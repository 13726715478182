import { Box, Card, CardBody, CardHeader, Container, Flex, Grid, Image, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosClient from '../../axios-client'
import { useTranslation } from 'react-i18next'
import imageIntrouvable from '../../assets/images/imageIntrouvable.png'
function Entreprise() {
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation();

    const [partenaire, setPartenaire] = useState([])
    const [pays, setPays] = useState([])
    const [villes, setVilles] = useState([])
    const [categories, setCategories] = useState([])


    useEffect(() => {
        axiosClient.get(`/partenaires/${location.state.idPartenaire}/`)
            .then(({ data }) => {
                setPartenaire(data)
            }).catch((error) => {
                // console.error(error);
            });

        axiosClient.get('pays/')
            .then((res) => {
                setPays(res.data);
            })
            .catch((error) => {
                // console.error(error);
            });

        axiosClient.get('villes/')
            .then((res) => {
                setVilles(res.data);
            })
            .catch((error) => {
                // console.error(error);
            });

        axiosClient.get('categories/')
            .then((res) => {
                setCategories(res.data);
            })
            .catch((error) => {
                // console.error(error);
            });
    }, []);

    return (
        <Box>
            {/* <Box
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      height="auto"
      p={0}
      position="relative"
      pt="50"
      pb="50"
      color={"black"}
    >
      <Box
        zIndex="-1"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="rgba(0, 0, 0, 0.5)" // Adjust the alpha value for transparency
      ></Box>
      <Container zIndex="2">
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          h="100%"
        >
          <Text fontSize="xl" color="white">
            Tu n'en as plus besoin ? Mets-le en vente !
          </Text>
          <Text fontSize="4xl" color="white">
            Bienvenue à Elbal
          </Text>
          <Text fontSize="lg" color="white" mt="4">
            Elbal te guide vers un avenir plus durable en donnant une nouvelle vie à tes vêtements et en dévoilant des trésors cachés. Chaque pièce a son histoire, chaque achat est une aventure unique.
          </Text>
        </Flex>
      </Container>
    </Box> */}
            <Box w={"100%"} mt={3} mb={2}>
                <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span onClick={() => navigate('/partenaires')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Partenaires")}</span> / <span> {t("Entreprise")}</span></Text>
            </Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 10 }}>

                <Grid px={{ base: 0, md: 5 }} templateColumns={{ sm: "1fr", md: "1fr", lg: "2fr 2fr " }} justify={"center"} gap='22px'>

                    <Card
                        // backgroundImage={partenaire.image}
                        display={'flex'}
                        // p='16px'
                        align={"center"}
                        justify={'center'}
                    // backgroundRepeat="no-repeat"

                    >   
                        {partenaire.image  
                        ?  <Image src={partenaire.image} w='100%' h='100%' objectFit="conatain" />
                        :<Image src={imageIntrouvable} w='100%' h='100%' objectFit="conatain" />
                        }
                       

                    </Card>
                    <Card

                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px'>
                            <Text fontFamily='Plus Jakarta Display' fontSize='25px' color="#49b1cc" fontWeight='bold'>
                                {t("Detaille entreprise")}
                            </Text>
                        </CardHeader>
                        <CardBody px='5px' >
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                <Flex mb='14px' flexDirection={{ base: "column",lg:"row" }}>
                                    <Text fontWeight={'bold'} letterSpacing={1} fontSize={{ sm: 'sm', md: 'md' }} me='10px'>
                                        {t("Entreprise (raison sociale)")} {' '} :
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }} fontWeight='400'>
                                        {partenaire?.company_name} 
                                    </Text>
                                </Flex>
                                <Flex mb='14px' w={'100%'} flexDirection={{ base: "column" }}>
                                    <Text fontWeight={'bold'} letterSpacing={1} fontSize={{ sm: 'sm', md: 'md' }} me='10px'>
                                        Description  {' '} :
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }} fontWeight='400'>
                                        {partenaire?.company_description}
                                        {/* description entreprise description entreprise description entreprise description entreprise description entreprise */}
                                    </Text>
                                </Flex>
                                <Flex mb='14px' flexDirection={{ base: "column", sm: "row" }}>
                                    <Text fontWeight={'bold'} letterSpacing={1} fontSize={{ sm: 'sm', md: 'md' }} me='10px'>
                                        {t("Pays")} {' '}  : &nbsp;
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }} fontWeight='400'>
                                        {pays.find(e => e.id === parseInt(partenaire?.pays_partenaire))?.nom_pays ?
                                        pays.find(e => e.id === parseInt(partenaire?.pays_partenaire))?.nom_pays
                                        : t("Non spécifié")}

                                    </Text>
                                </Flex>
                                <Flex mb='14px' flexDirection={{ base: "column", sm: "row" }}>
                                    <Text fontWeight={'bold'} letterSpacing={1} fontSize={{ sm: 'sm', md: 'md' }} me='10px'>
                                        {t("Ville")} {' '} : &nbsp;
                                    </Text>

                                    <ul>
                                        {villes.filter(e => partenaire?.ville_partenaire?.includes(e.id)).length > 0 ?
                                            (villes.filter(e => partenaire?.ville_partenaire?.includes(e.id)).map((row) => (
                                                <li><Text mr={2} fontSize={{ sm: 'sm', md: 'md' }} fontWeight='400'> {row?.nom_ville} </Text></li>
                                            )))
                                            : t("Non spécifié")
                                        }


                                    </ul>

                                </Flex>

                                <Flex fontWeight={'bold'} mb='14px' flexDirection={{ base: "column", sm: "row" }}>
                                    <Text letterSpacing={1} fontSize={{ sm: 'sm', md: 'md' }} me='10px'>
                                        {t("Activité")} {' '} : &nbsp;
                                    </Text>
                                    <Flex ml={5}>
                                        <ul >

                                            {categories.filter(e => partenaire?.category?.includes(e.id)).map((row) => (
                                                <li><Text fontSize={{ sm: 'sm', md: 'md' }} fontWeight='400'> {row?.name} </Text></li>
                                            ))}

                                        </ul>
                                    </Flex>


                                </Flex>


                                {/* <Flex  mb='15px' flexDirection={{base:"column", md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "100%", sm: "40%", lg: "40%" }} fontSize={{ sm: 'sm', md: 'md' }}  me='10px'>
                                        Adresse {'  '}
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  fontWeight='400'>
                                        : &nbsp;  {partenaire.entreprise?.adresse}        
                                    </Text>
                                </Flex>
                                <Flex  mb='15px' flexDirection={{base:"column", md:"row"}}>
                                    <Text letterSpacing={1} w={{ base: "100%", sm: "40%", lg: "40%" }} fontSize={{ sm: 'sm', md: 'md' }}  me='10px'>
                                        Téléphone {'  '}
                                    </Text>
                                    <Text fontSize={{ sm: 'sm', md: 'md' }}  fontWeight='400'>
                                        : &nbsp;  {partenaire.entreprise?.telephone} 
                                    </Text>
                                </Flex> */}


                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
        </Box>
    )
}

export default Entreprise