import { Alert, AlertIcon, Avatar, AvatarBadge, Box, Button, Card, CardBody, CardHeader, DarkMode, Divider, Flex, Grid, Icon, Input, InputGroup, InputRightElement, Select, Stack, Text, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdSettings } from 'react-icons/md'
import { FaCube, FaPenFancy, FaPencilAlt } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'
import { PiGenderFemaleBold } from 'react-icons/pi'
import { IoMdMale, IoMdFemale } from 'react-icons/io'
import { useStateContext } from '../../context/ContextProvider'
import axiosClient from '../../axios-client';
import { Navigate, useNavigate } from 'react-router-dom'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import countryData from '../../assets/dataJson/countries.json';
import axios from 'axios'
import { useTranslation } from 'react-i18next'

function Security() {
    const { user } = useStateContext();
    const { t } = useTranslation();

    const navigate = useNavigate()
    const [selectedImage, setSelectedImage] = useState()
    const [image, setImage] = useState()

    //toast variable
    const toast = useToast()
    //password variables
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageP, setMessageP] = useState('');

    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);

    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const [listAdmins, setListAdmins] = useState([])
    const [messageE, setMessageE] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;




    useEffect(() => {
        axiosClient
            .get("/admins/")
            .then((res) => setListAdmins(res.data));

    }, [selectedImage]);




    const isEmpty = () => {
        return !lastName || !firstName;
    };
    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////


    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(newPassword)) {
            missingRequirements.push(t("caractère spécial"));
        } else if (!lowercaseRegex.test(newPassword)) {
            missingRequirements.push(t("minuscule"));
        } else if (!uppercaseRegex.test(newPassword)) {
            missingRequirements.push(t("majuscule"));
        } else if (!digitRegex.test(newPassword)) {
            missingRequirements.push(t("chiffres"));
        } else if (!minLengthRegex.test(newPassword)) {
            missingRequirements.push(t("longueur minimale de 8 caractères"));
        }

        if (missingRequirements.length > 0) {
            const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength(t('Le mot de passe est correct!'));
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);


    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
        }

        if (!digitRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
        }

        if (!lowercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
        }

        if (!uppercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
        }
        if (!specialchar.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
        }
        if (password.length > 20) {
            errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isPasswordValid(newPassword)) {
            return;
        }
        try {
            const response = await axiosClient.put(`/change_password/${user.id}/`, {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
                useradd: user.first_name + ' ' + user.last_name
            });
            if (response.data.success) {
                toast({
                    description: t("le mot de passe est modifié avec succès"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                });
                setConfirmPassword('')
                setNewPassword('')
                setOldPassword('')

            }
            setMessage(response.data.error);
        } catch (err) {
            setError(err.response.data.error);
        }
    };



    const EditEmail = async () => {


        const formData = new FormData();
        if (email) { formData.append("email", email); }

        // const newList = listAdmins.filter((e) => e.id !== user.id);
        // if (newList.length > 0) {
        //     const check = newList.find((obj) => {
        //         return obj.email === email.trim();
        //     });
        //     if (check) {
        //         window.scrollTo(0, 0);
        //         return setMessageE(t("Ce mail existe déjà !"));
        //     }
        // }
        if (email && !emailRegex.test(email)) {
            return setMessageE(t("Veuillez entrer un email valide."));

        }
        if( !email) {
            return
        }

        try {
            const response = await axiosClient.put(`/update_user_email/${user.id}/`, formData)
            if (response.data.message) {
                toast({
                    description: t("L'email est modifié avec succès"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'

                });
                setMessageE('')
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }else if(response.data.error) {
                window.scrollTo(0, 0);
                setMessageE(t("Ce mail existe déjà !"))
            }
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
        }
    }
    return (
        <Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
                <Flex
                    mb={{ base: "20px", sm: '24px', md: '50px', xl: '10px' }}
                    borderRadius='15px'
                    px='0px'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    {/* Header */}
                    <Flex

                        direction={{ base: "column", sm: 'column', md: 'row' }}
                        mx='auto'
                        maxH='330px'
                        w={{ sm: '90%', xl: '100%' }}
                        justifyContent={{ sm: 'center', md: 'space-between' }}
                        align='center'
                        p='24px'
                        borderRadius='20px'
                        mt='10px'
                    >
                        <Flex
                            align='center'
                            mb={{ sm: '10px', md: '10px' }}
                            direction={{ sm: 'column', md: 'row' }}
                            w={{ sm: '100%' }}
                            textAlign={{ sm: 'center', md: 'start' }}>
                            <Avatar me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={user.image} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>

                            </Avatar>
                            <Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
                                <Flex direction='row' align={'center'}>
                                    <Text
                                        fontSize={{ sm: 'lg', lg: 'xl' }}
                                        color={useColorModeValue("gray.700", "white")}
                                        fontWeight='bold'
                                        ms={{ sm: '8px', md: '0px' }}
                                        mr={2}
                                    >
                                        {user.first_name} {user.last_name}
                                    </Text>
                                    {user.gender === "Femme" ? <IoMdFemale size={"22px"} color='#ff8096' /> : user.gender === "Homme" ? <IoMdMale size={"22px"} color='#5da4ff' /> : null}
                                </Flex>

                                <Text fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue('gray.400', 'gray.300')}>
                                    {user.email}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex mt={{ base: 4, md: 0 }} direction={{ base: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }}>
                            <Button
                                onClick={() => navigate('/profil')}
                                borderRadius='12px'
                                bg='transparent'
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={ImProfile} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t('PROFIL')}
                                </Text>
                            </Button>
                            <Button
                                borderRadius='12px'
                                bg='transparent'
                                onClick={() => navigate('/modifier-profil')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={MdSettings} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t("PARAMÈTRE")}
                                </Text>
                            </Button>
                            <Button
                                borderRadius='12px'
                                bg={useColorModeValue('gray.200', 'gray.700')}
                                onClick={() => navigate('/securite-de-compte')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={MdSettings} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t("SÉCURITÉ DE COMPTE")}
                                </Text>
                            </Button>

                        </Flex>
                    </Flex>
                </Flex>
                <Flex justify={'center'} w={"100%"} px={5}>
                    <Card
                        w={{base:"100%" , lg:"65%"}}
                        bg={useColorModeValue("gray.700", "gray.700")}
                        display={'flex'}
                        justify={'center'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' >
                            <Text fontSize='lg' color="white" fontWeight='bold'>
                                {t("Sécurité de compte")}
                            </Text>
                        </CardHeader>
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                {/* <Text fontSize='lg' color="white" fontWeight='500' mb={3}>
                                       Gestion Email
                                    </Text> */}
                                {messageE &&
                                    <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                        <AlertIcon />
                                        {messageE}
                                    </Alert>}
                                <Flex align='center' justifyContent={'space-between'} mb='10px'>

                                    <Input onChange={(e) => setEmail(e.target.value)} size={'md'} w={{ base: "100%", sm: "60%", lg: "50%" }} placeholder={t('Adresse email')} defaultValue={user.email} type="email" name='email' id="email"
                                        color='gray.100'
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    {/* <Text fontSize='md' color="white" fontWeight='400'>
                                        {admin.email}
                                    </Text> */}
                                    <Button onClick={EditEmail} size={"sm"} colorScheme='whatsapp' variant='outline'>
                                        {t("Modifier")}
                                    </Button>
                                </Flex>
                                {/* <Divider pt={2} pb={2} /> */}

                                <Text fontSize='md' color="white" fontWeight='bold' mt={3} pb={6}>{t("Modification de mot de passe")} :</Text>
                                {message &&
                                    <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                        <AlertIcon />
                                        {message}
                                    </Alert>
                                }
                                <Flex align='center' mb='14px' flexDirection={{ base: "column", md: "row" }}>
                                    <Text letterSpacing={1} mb={{ base: 2, md: 0 }} w={{ base: "100%", sm: "100%", md: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.300'} me='10px'>
                                        {t("Ancien mot de passe")} {' '}
                                    </Text>
                                    <InputGroup w={{ base: "100%", sm: "100%", md: "60%" }}>
                                        <Input

                                            type={showPasswords ? 'text' : 'password'}
                                            id="old_password"
                                            
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            color='gray.100'
                                            style={{
                                                borderTop: "none",
                                                borderRight: "none",
                                                borderLeft: "none",
                                                borderRadius: "0",
                                                borderBottom: "1px solid gray",
                                                outline: "none",
                                                borderRadius: 5
                                            }}
                                        />
                                        <InputRightElement h={'full'}>
                                            <Button
                                                color={"white"}
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPasswords((showPasswords) => !showPasswords)
                                                }>
                                                {showPasswords ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    {/* <Input
                                        w={{ base: "100%", sm: "100%", md: "60%" }}
                                        type={'password'}
                                        id="old_password"
                                        color='gray.100'
                                        // onChange={(e) => setOldPassword(e.target.value)}
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }}
                                    /> */}
                                </Flex>
                                <Flex align='center' mb='14px' flexDirection={{ base: "column", md: "row" }}>
                                    <Text letterSpacing={1} mb={{ base: 2, md: 0 }} w={{ base: "100%", sm: "100%", md: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.300'} me='10px'>
                                        {t("Nouveau mot de passe")} {' '}
                                    </Text>
                                    <InputGroup w={{ base: "100%", sm: "100%", md: "60%" }}>


                                        <Input

                                            type={showPassword ? 'text' : 'password'}
                                            id="new_password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            onCopy={(e) => e.preventDefault()}
                                            color='gray.100'
                                            style={{
                                                borderTop: "none",
                                                borderRight: "none",
                                                borderLeft: "none",
                                                borderRadius: "0",
                                                borderBottom: "1px solid gray",
                                                outline: "none",
                                                borderRadius: 5
                                            }}
                                        />

                                        <InputRightElement h={'full'}>
                                            <Button
                                                color={"white"}
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>

                                    {/* <Input
                                         w={{ base: "100%", sm: "100%", md: "60%" }}
                                        type={'password'}
                                        id="new_password"
                                        // value={newPassword}
                                        // onChange={(e) => setNewPassword(e.target.value)}
                                        // onCopy={(e) => e.preventDefault()}
                                        color='gray.100'
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }}
                                    /> */}
                                </Flex>
                                {newPassword.length > 0 ?
                                    <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                    : ""}
                                <Flex align='center' mb='14px' flexDirection={{ base: "column", md: "row" }}>
                                    <Text letterSpacing={1} mb={{ base: 2, md: 0 }} w={{ base: "100%", sm: "100%", md: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.300'} me='10px'>
                                        {t("Confirmer mot de passe")} {'  '}
                                    </Text>

                                    <Input
                                        w={{ base: "100%", sm: "100%", md: "60%" }}
                                        type="password"
                                        id="confirm_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        color='gray.100'
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }}
                                    />
                                </Flex>
                                <Stack spacing={2} mt={3} direction={['column', 'row']} justifyContent="end" >
                                    <Button
                                        size={'sm'}
                                        colorScheme="red"
                                        onClick={() => navigate(-1)}
                                    >
                                        {t("Annuler")}
                                    </Button>
                                    <Button
                                        size={'sm'}
                                        type='submit'

                                        onClick={handleFormSubmit}
                                        colorScheme="gray">
                                        {t("Enregistrer")}
                                    </Button>

                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>

                </Flex>
            </Flex>
        </Box>

    )
}

export default Security