import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Card, CardBody, CardHeader, Flex, FormLabel, Input, InputGroup, InputLeftElement, Stack, Text, Textarea, useColorModeValue, useDisclosure, useToast, SimpleGrid, Heading, CardFooter, Menu, MenuButton, MenuList, MenuItem, useMediaQuery, Skeleton,
    Image,
    Checkbox,
    Select
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPaginantion from '../../components/MyPagination';
import { PiPlusBold } from 'react-icons/pi';
import upload from '../../assets/images/upload.png'
import Iframe from 'react-iframe';
import { BsThreeDotsVertical } from 'react-icons/bs';
import PdfViewer from './PdfViewer';
import { Document } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../components/MyPagination';
import emptyPromo from "../../assets/images/emptyPromo.png"
import notfound from '../../assets/images/notfound.png';

const Pdf = ({ pdfUrl }) => {
    const [pdfBlob, setPdfBlob] = useState(null);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await fetch(pdfUrl);
                const blob = await response.blob();
                setPdfBlob(blob);

            } catch (error) {
                // console.error('Error fetching PDF:', error);
            }
        };

        fetchPdf();
    }, [pdfUrl]);


    return (
        <Flex w={"100%"} h={"100%"}>
            {pdfBlob && (
                <>
                    <Text>{pdfBlob.name}</Text>
                    <Iframe
                        height='100%'
                        width='100%'

                        title="PDF Viewer"
                        src={URL.createObjectURL(pdfBlob)}

                    />
                </>

            )}
        </Flex>
    );
};


function GestPromotions() {
    const { t } = useTranslation();

    const navigate = useNavigate()
    const [promotionsData, setPromotionsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isOpenDetails, setIsOpenDetails] = useState(false);
    const [paginationData, setPaginationData] = useState()
    const [loadingData, setLoadingData] = useState(true)

    const [message, setMessage] = useState('');
    const [promoData, setPromoData] = useState();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState(null)
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [selectedEtat, setSelectedEtat] = useState(null);

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const firstField = React.useRef()
    const [isChecked, setIsChecked] = useState(null);

    const MemoizedPdfViewer = React.memo(PdfViewer);

    const onOpenDetails = () => {
        setIsOpenDetails(true);
    };
    const onCloseDetails = () => {
        setIsOpenDetails(false);
    };

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 12;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    //get the data
    useEffect(() => {
        // setLoadingData(true)
        axiosClient.get(`/promotions/getAllPromos/?page_size=12&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setPromotionsData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
    }, [pageSize, currentPage, searchTerm, selectedEtat])


    function update() {
        axiosClient.get(`/promotions/getAllPromos/?page_size=12&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setPromotionsData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
    }

    //search method
    // const filteredData = useCallback(() => {
    //     return promotionsData.filter((row) => {
    //         return row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.description.toString().toLowerCase().includes(searchTerm.toString().toLowerCase())
    //     });
    // }, [promotionsData, searchTerm]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 50 * 1024 * 1024; // 50 MB in bytes

        const extensions = ['.pdf']
        if (file) {
            if (file.size > maxSize) {
                toast({
                    description: t("Le fichier est trop volumineux (plus de 50 Mo)"),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    variant: "left-accent",
                    position: 'top-center'
                });
                event.target.value = null;
            }
            else if (file.name?.length > 70) {
                toast({
                    description: t("Le nom du pdf est trop long"),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    variant: "left-accent",
                    position: 'top-center'
                })
                event.target.value = null
            }
            else if (!extensions.includes(file.name?.substring(file.name?.length, file.name?.lastIndexOf(".")))) {
                toast({
                    description: t("Le type de fichier doit être un PDF"),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    variant: "left-accent",
                    position: 'top-center'
                })
                event.target.value = null
            }
            else {
                setFile(file)
                setSelectedFiles(URL.createObjectURL(file));
            }
        }



    };
    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/promotions/${id}/`)
            .then((response) => {
                setPromotionsData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("La promotion est supprimée avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                update()
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }

    const onSubmit = () => {
        if (!title || title.trim() === "") {
            setMessage(t("Veuillez remplir le titre"))
            return;
        }
        if (!description || description.trim() === "") {
            setMessage(t("Veuillez remplir la description"))
            return;
        }
        const formData = new FormData()
        formData.append("title", title)
        formData.append("file", file)
        formData.append("description", description)
        formData.append("fileName", file.name)
        if (isChecked) { formData.append("isActive", isChecked) }
        else { formData.append("isActive", true) }




        axiosClient.post('promotions/', formData)
            .then((response) => {
                setPromotionsData([response.data, ...promotionsData]);
                setTitle('')
                setDescription('')
                setSelectedFiles()
                setMessage("")
                setIsChecked(null)

                toast({
                    description: t(`La promotion est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                update()
                onClose()
            })
            .catch((err) => 
            {
                // console.log(err)
            }
            )




    }

    const onUpdate = (id) => {
        const formData = new FormData()
        if (title) {
            formData.append('title', title)
        }
        if (description) {
            formData.append('description', description)
        }
        if (file) {
            formData.append('file', file)
            formData.append('fileName', file.name)
        }
        formData.append("isActive", isChecked)

        axiosClient.patch(`promotions/${id}/`, formData)
            .then((response) => {

                setTitle('')
                setDescription('')
                setSelectedFiles()
                setMessage("")
                update()
                toast({
                    description: t(`La promotion est modifiée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                onClose()
            })
            .catch((err) => {
                // console.log(err)
            }
                
        )
    }

    // const UpdateNotif = (id) => {

    //     const formData = new FormData();
    //     if (title) formData.append("title", title)
    //     if (selectedCible) formData.append("cible", selectedCible)
    //     if (content) formData.append("content", content)

    //     axiosClient.patch(`/notificationsAdmin/${id}/`, formData).then(() => {

    //         update()

    //         toast({
    //             description: `La notification est modifiée avec succès`,
    //             status: 'success',
    //             duration: 2000,
    //             isClosable: true,
    //             variant: 'left-accent',
    //         })
    //         setTitle('')
    //         setContent('')
    //         setSelectedCible('')
    //         setMessage("")
    //     })

    //     onClose()
    // }
    const myColor = useColorModeValue("gray.50", "gray.700");
    const [isLargerThan602] = useMediaQuery('(max-width: 602px)')
    const [between768and842] = useMediaQuery('(min-width: 768px) and (max-width: 842px)')
    const isLargerThan602AndBetween768and842 = isLargerThan602 || between768and842;

    const handlePdfClick = async (pdf) => {
        if (pdf) {
            try {
                const response = await fetch(pdf);
                const blob = await response.blob();
                window.open(URL.createObjectURL(blob), '_blank')
            } catch (error) {
                // console.error('Error fetching PDF:', error);
            }
            // window.open(URL.createObjectURL(pdf), '_blank');
        }
    };


    return (
        <Box>
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span>APA Promotions</span> </Text>
                    <Button mb={{ base: 5, md: 0 }} onClick={() => {
                        onOpen()

                    }}
                        leftIcon={<PiPlusBold color='#49b1cc' />} variant='solid'>
                        {t("Ajouter une promotion")}
                    </Button>
                </Box>

            </Flex>
            {( !searchTerm && selectedEtat===null && paginationData?.length === 0 ) ? (
                <Flex mb={'10'} mt={20} gap={2} flexDirection={'column'} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"}>
                    <Text color={'gray.500'} fontWeight={"semibold"}>{t("Il n'y a pas de promotions à afficher")}</Text>
                    <Image opacity={0.7} w={{ base: "150px", md: "200px", lg: "200px" }} src={emptyPromo} />
                </Flex>
            )

                :
                <Flex direction='column' mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 7 }}>

                    <Flex
                        gap={2}
                        px={3}
                        // justifyContent="end"
                        alignItems="center"

                        justify={"space-between"}
                        direction={{ base: 'column', sm: 'row' }}
                    >
                        <Select mb={{base:0,sm:4}} size={"md"} w={{ base: "100%", sm: "30%" ,lg: "20%"  }} bg={myColor} borderRadius="5" fontSize={{ base: 13, lg: 16 }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                            <option value="">{t("Tout")}</option>
                            <option value="true">{t("Activé")}</option>
                            <option value="false">{t("Désactivé")}</option>
                        </Select>
                        <InputGroup size={"md"} w={{ base: '100%', sm: "50%",lg: "40%"  }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius={"5"}
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                bg={myColor}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        align={{ base: 'stretch', md: 'center' }}
                        justify={{ base: 'flex-start', md: 'space-between' }}
                        p={3}
                    >

                        <Flex
                            direction="column"
                            width={{ base: "100%", lg: "100%" }}
                            justify={{ base: 'flex-start', md: 'space-between' }}

                        >
                            <SimpleGrid spacing={7} templateColumns='repeat(auto-fill, minmax(250px, 1fr))'>
                                {loadingData &&

                                    <>
                                        <Skeleton rounded={'md'}>
                                            <Card shadow={"md"} h={'250px'} maxW='md'>
                                            </Card>
                                        </Skeleton>
                                        <Skeleton rounded={'md'}>
                                            <Card shadow={"md"} h={'250px'} maxW='md'>
                                            </Card>
                                        </Skeleton>
                                        <Skeleton rounded={'md'}>
                                            <Card shadow={"md"} h={'250px'} maxW='md'>
                                            </Card>
                                        </Skeleton>
                                        <Skeleton rounded={'md'}>
                                            <Card shadow={"md"} h={'250px'} maxW='md'>
                                            </Card>
                                        </Skeleton>
                                    </>


                                }

                                {(paginationData?.length !== 0) && promotionsData.map((row ,key) => (


                                    // <Stack
                                    //     bg={myColor}
                                    //     mb={2}
                                    //     rounded={'lg'}
                                    //     p={5}
                                    //     boxShadow={'md'}
                                    //     alignItems={'start'}
                                    //     justify="space-between"
                                    // // as={"Button"}

                                    // >
                                    //     <Flex direction="row"
                                    //         justify="space-between"
                                    //         ml={'auto'}
                                    //         width="full">
                                    //         <Stack direction={'row'} align={'center'}>
                                    //             <Text fontSize={'lg'} fontWeight="semibold">{row.title}</Text>

                                    //         </Stack>
                                    //         <Text mt={2} fontSize={'xs'} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString('fr-FR', { hour: '2-digit', minute: '2-digit' })}</Text>

                                    //     </Flex>

                                    //     <Flex
                                    //         direction="row"
                                    //         width="full"
                                    //         ml={'auto'}
                                    //         justify="space-between">
                                    //         <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={{ base: "80%", md: "80%", lg: "85%" }}>
                                    //             {row.content}

                                    //         </Text>

                                    //         <Flex align={'center'} mt={1} direction={'column'}>

                                    //             <Flex direction={"row"} >
                                    //                 {/* <EditIcon
                                    //             size="sm"
                                    //             cursor='pointer'
                                    //             onClick={() => {
                                    //                 setChangeModal(false)
                                    //                 setNotifData(row)
                                    //                 setNotifId(row.id)
                                    //                 onOpen()

                                    //             }}

                                    //             color='green'

                                    //         /> */}

                                    //                 {/* {(user.role === "SUPERADMIN" || user.role === "ADMIN") && <DeleteIcon
                                    //                     ml={5}
                                    //                     cursor='pointer'
                                    //                     size="sm"
                                    //                     onClick={() => {
                                    //                         setId(row.id);
                                    //                         onOpenAlert();
                                    //                     }}
                                    //                     color='#e53e3e'
                                    //                 // icon={<DeleteIcon />}>
                                    //                 />
                                    //                 } */}

                                    //             </Flex>


                                    //         </Flex>
                                    //     </Flex>
                                    // </Stack>

                                    <Card key={key} shadow={"md"} h={'fit-content'} bg={myColor} maxW='md'>

                                        <CardHeader px={2} py={5}>
                                            <Flex justifyContent={"space-between"} alignItems="center">
                                                <Flex alignItems='center' flexWrap='wrap'>

                                                    <Heading size='sm'>{row.title}</Heading>
                                                    {/* <Text mt={2} fontSize={"12px"} color={'gray'} >{new Date(row.date_creation).toLocaleDateString('fr-FR', { hour: '2-digit', minute: '2-digit' })}</Text> */}
                                                </Flex>
                                                <Menu >
                                                    <MenuButton >
                                                        <Flex cursor="pointer" alignItems={"end"} justifyContent={"end"}>
                                                            <div>
                                                                <BsThreeDotsVertical color='gray' size={18} />
                                                            </div>
                                                        </Flex>
                                                    </MenuButton>
                                                    <MenuList zIndex="2">
                                                        <MenuItem onClick={() => {
                                                            onOpenDetails()
                                                            setPromoData(row)
                                                        }}>{t("Détails")}</MenuItem>
                                                        <MenuItem onClick={() => {
                                                            onOpen()
                                                            setIsEdit(true)
                                                            setPromoData(row)
                                                            setIsChecked(row.isActive);
                                                        }}>{t("Modifier")}</MenuItem>
                                                        <MenuItem
                                                            onClick={() => {
                                                                onOpenAlert()
                                                                setId(row.id)
                                                            }
                                                            }
                                                        >{t("Supprimer")} </MenuItem>

                                                    </MenuList>
                                                </Menu>

                                            </Flex>
                                        </CardHeader>
                                        <CardBody px={2} py={0}>
                                            {/* <Text>
                                                {row.description}
                                            </Text> */}
                                            <Flex  >
                                                {row.file &&
                                                    <>
                                                        {/* <embed src={row.file} type='application/pdf' height={"500px"} width={"500px"}/> */}
                                                        {/* <PdfViewer key={row.id} pdfUrl={row.file} /> */}
                                                        {/* <object data={row.file} type="application/pdf" width="100%" height="500px">
                                                        </object> */}
                                                        {/* <Document file={row.file} /> */}
                                                        <Flex height={"500px"} rounded={"md"} w={"100%"} style={{ position: 'relative', overflow: 'hidden', height: '100%', justifyContent: "center", alignItems: "center" }}>
                                                            <>
                                                                <Iframe

                                                                    title="PDF Viewer"
                                                                    // src={"https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf"} 
                                                                    src={row.file}
                                                                    width={isLargerThan602AndBetween768and842 ? "100%" : ""}
                                                                    height="170px"

                                                                />
                                                                <div
                                                                    onClick={() => handlePdfClick(row.file)}
                                                                    style={{
                                                                        opacity: .1,

                                                                        background: 'black',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        zIndex: 1,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            </>

                                                        </Flex>

                                                        {/* <Iframe
                        
                        title="PDF Viewer"
                        src={"https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf"}
                        height="170px"
                       
                    /> */}
                                                    </>
                                                }</Flex>
                                        </CardBody>
                                        <CardFooter py={2} mb={1} w={"100%"}>
                                            <Text w={"100%"} align={'end'} mt={2} fontSize={"12px"} color={'gray'} >{new Date(row.date_creation).toLocaleDateString(t('fr-FR'), { hour: '2-digit', minute: '2-digit' })}</Text>

                                        </CardFooter>

                                    </Card>

                                ))}
                            </SimpleGrid>

                            {(((searchTerm || selectedEtat!==null) && paginationData?.length === 0) || (checkLengthData)) && (
                               
                                <Flex flexDirection={"column"} p={{ base: 5, md: 10, lg: 20 }} w={"100%"} justify={"center"} alignItems={'center'}>
                                <Text color={'gray.500'} fontWeight={"semibold"}>{t("Aucune promotion correspondante n'a été trouvée.")}</Text>
                                <Image opacity={0.5} src={notfound}></Image>
                            </Flex>
                            )}
                            <Flex justify="space-between" align="center" w="100%">
                                <Box flex="1">
                                    <MyPagination
                                        paginationData={paginationData}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        PAGE_SIZE={pageSize}
                                    />
                                </Box>


                            </Flex>
                        </Flex>


                    </Flex>

                </Flex>}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer promotion")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {isEdit ?
                <Drawer
                    closeOnOverlayClick={false}
                    size={"sm"}
                    isOpen={isOpen}
                    placement='right'
                    initialFocusRef={firstField}
                    onClose={() => {
                        onClose()
                        setIsEdit(false)
                        setTitle('')
                        setDescription('')
                        setSelectedFiles(null)
                        setFile()
                    }
                    }
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader borderBottomWidth='1px'>
                            {t("Modifier la promotion")}
                        </DrawerHeader>

                        <DrawerBody>
                            <Stack spacing='24px'>
                                <Box>
                                    <FormLabel >{t("Titre")}</FormLabel>
                                    <Input
                                        maxLength={50}
                                        defaultValue={promoData?.title} onChange={(e) => setTitle(e.target.value)}
                                        id='titre'
                                        placeholder={t("Entrez le titre")}
                                    />
                                </Box>


                                <Box>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea maxLength={150} defaultValue={promoData?.description} onChange={(e) => setDescription(e.target.value)} id='desc' placeholder={t('Ecrivez la description de promo')} />
                                </Box>
                                <Checkbox
                                    sx={{
                                        '& .chakra-checkbox__control': {
                                            bg: isChecked ? 'blue.500' : 'rgb(189,190,191,0.1)', // Change the background color based on the checked state
                                            borderColor: isChecked ? 'blue.500' : 'gray.300',
                                        },
                                        '& .chakra-checkbox__control[data-checked]': {
                                            bg: 'blue.500', // Background color when checked
                                            borderColor: 'blue.500',
                                        }
                                    }}
                                    isChecked={isChecked}
                                    defaultChecked
                                    onChange={e => {
                                        setIsChecked(e.target.checked);

                                    }}
                                >
                                    {t("Activé")}</Checkbox>

                                <Flex position={'relative'} _hover={{ background: "#fafafa" }} cursor={"pointer"} align={'start'} justify={"start"} flexDirection={'column'} w={"100%"} minH={"50px"} border={"1px dashed lightgray"} borderRadius={"lg"} >

                                    <Input style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        zIndex: 10,
                                        opacity: 0,
                                        height: '100%'
                                    }} onChange={handleFileChange} type='file' accept=".pdf"></Input>

                                    {(!selectedFiles && promoData.file === "") ?

                                        <>
                                            <Flex
                                                flexDirection={"column"}
                                                align={"center"}
                                                justify={"center"}
                                                position="relative"
                                                w='100%'
                                                h='100px'
                                            >
                                                <img src={upload} style={{ width: 70, height: 70, color: "lightgray", objectFit: "contain" }} />

                                            </Flex>

                                        </>

                                        :
                                        (selectedFiles) ?
                                            <>
                                                <Flex
                                                    flexDirection={"column"}
                                                    align={"center"}
                                                    justify={"center"}
                                                    position="relative"
                                                    pt={2}
                                                    w={"100%"}
                                                >
                                                    <Text>{file.name}</Text>
                                                </Flex>

                                            </>
                                            :
                                            <>
                                                <Flex
                                                    flexDirection={"column"}
                                                    align={"center"}
                                                    justify={"center"}
                                                    position="relative"
                                                    pt={2}
                                                    w={"100%"}
                                                >
                                                    <Text>{promoData?.fileName}</Text>
                                                </Flex>

                                            </>
                                    }

                                </Flex>




                            </Stack>

                            <Flex mt={3} h={"100%"}>
                                <Iframe url={selectedFiles ? selectedFiles : promoData?.file} width='100%' height='100%' display='block' position='relative' />

                            </Flex>

                        </DrawerBody>

                        <DrawerFooter borderTopWidth='1px'>
                            <Button variant='outline' mr={3} onClick={() => {
                                onClose()
                                setIsEdit(false)
                                setTitle('')
                                setDescription('')
                                setSelectedFiles(null)
                                setFile()
                            }
                            }>
                                {t("Annuler")}
                            </Button>
                            <Button colorScheme='whatsapp' onClick={() => onUpdate(promoData?.id)}>{t("Modifier")}</Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
                :
                <Drawer
                    closeOnOverlayClick={false}
                    size={"sm"}
                    isOpen={isOpen}
                    placement='right'
                    initialFocusRef={firstField}
                    onClose={() => {
                        onClose()
                        setIsEdit(false)
                        setTitle('')
                        setDescription('')
                        setSelectedFiles(null)
                        setFile()
                    }
                    }
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader borderBottomWidth='1px'>
                            {t("Ajouter une promotion")}
                        </DrawerHeader>

                        <DrawerBody>
                            <Stack spacing='24px'>
                                <Box>
                                    <FormLabel >{t('Titre')}</FormLabel>
                                    <Input
                                        maxLength={50}
                                        value={title} onChange={(e) => setTitle(e.target.value)}
                                        id='titre'
                                        placeholder={t("Entrez le titre")}
                                    />
                                </Box>


                                <Box>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea maxLength={150} value={description} onChange={(e) => setDescription(e.target.value)} id='desc' placeholder={t('Ecrivez la description de promo')} />
                                </Box>
                                <Checkbox
                                    sx={{
                                        '& .chakra-checkbox__control': {
                                            bg: isChecked ? 'blue.500' : 'rgb(189,190,191,0.1)', // Change the background color based on the checked state
                                            borderColor: isChecked ? 'blue.500' : 'gray.300',
                                        },
                                        '& .chakra-checkbox__control[data-checked]': {
                                            bg: 'blue.500', // Background color when checked
                                            borderColor: 'blue.500',
                                        }
                                    }}
                                    value={isChecked}
                                    defaultChecked
                                    onChange={e => {
                                        setIsChecked(e.target.checked);

                                    }}
                                >
                                    {t("Activé")}</Checkbox>

                                <Flex position={'relative'} _hover={{ background: "#fafafa" }} cursor={"pointer"} align={'start'} justify={"start"} flexDirection={'column'} w={"100%"} minH={"50px"} border={"1px dashed lightgray"} borderRadius={"lg"} >

                                    <Input style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        zIndex: 10,
                                        opacity: 0,
                                        height: '100%'
                                    }} onChange={handleFileChange} type='file' accept=".pdf"></Input>

                                    {!selectedFiles ?

                                        <>

                                            {/* <Flex w='100%' h='20%'>
                        <Box style={{ paddingLeft: 5, paddingTop: 5, color: '#8d8686' }}>
                          <Text >(* The name of the zip is too long and the type of the file should be a zip or rar)</Text>
                        </Box>
                      </Flex> */}


                                            <Flex
                                                flexDirection={"column"}
                                                align={"center"}
                                                justify={"center"}
                                                position="relative"
                                                w='100%'
                                                h='100px'
                                            >
                                                <img src={upload} style={{ width: 70, height: 70, color: "lightgray", objectFit: "contain" }} />

                                            </Flex>

                                        </>

                                        :
                                        <>
                                            <Flex
                                                flexDirection={"column"}
                                                align={"center"}
                                                justify={"center"}
                                                position="relative"
                                                pt={2}
                                                w={"100%"}
                                            >
                                                <Text>{file.name}</Text>
                                            </Flex>

                                        </>
                                    }

                                </Flex>




                            </Stack>
                            {selectedFiles &&

                                <Flex mt={3} h={"100%"}>
                                    <Iframe url={selectedFiles} width='100%' height='100%' display='block' position='relative' />

                                </Flex>}
                        </DrawerBody>

                        <DrawerFooter borderTopWidth='1px'>
                            <Button variant='outline' mr={3} onClick={() => {
                                onClose()
                                setIsEdit(false)
                                setTitle('')
                                setDescription('')
                                setSelectedFiles(null)
                                setFile()
                            }
                            }>
                                {t("Annuler")}
                            </Button>
                            <Button colorScheme='blue' onClick={onSubmit}>{t("Ajouter")}</Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>}


            <Drawer
                closeOnOverlayClick={false}
                size={"lg"}
                isOpen={isOpenDetails}
                placement='left'
                initialFocusRef={firstField}
                onClose={() => {
                    onCloseDetails()

                }
                }
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        {t("Details de promotion")}
                    </DrawerHeader>

                    <DrawerBody>
                        <Stack spacing='24px'>
                            <Flex gap="2" alignItems={"center"}>
                                <Text fontFamily='Plus Jakarta Display' fontSize={"23px"}>{t("Titre")} : </Text>
                                <Text fontSize={"md"}>
                                    {promoData?.title}
                                </Text>
                            </Flex>


                            <Box gap="2" alignItems={"center"}>
                                <Text fontFamily='Plus Jakarta Display' fontSize={"23px"}>Description : </Text>
                                <Text fontSize={"md"}>
                                    {promoData?.description}
                                </Text>
                            </Box>


                            <Flex
                                flexDirection={"column"}
                                align={"center"}
                                justify={"center"}
                                position="relative"
                                pt={2}
                                w={"100%"}
                            >
                                <Text fontFamily='Plus Jakarta Display'>{promoData?.fileName}</Text>
                            </Flex>





                        </Stack>

                        <Flex mt={3} h={"100%"} w={"100%"}>
                            {/* <Pdf pdfUrl={promoData?.file} /> */}
                            <Iframe

                                title="PDF Viewer"
                                // src={"https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf"} 
                                src={promoData?.file}
                                width='100%'

                            />
                        </Flex>


                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={() => {
                            onCloseDetails()

                        }
                        }>
                            {t("Annuler")}
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
            {/* {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setSelectedCible('')
                    setTitle('')
                    setContent('')
                    setSelectedCible('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Ajout d'une notification</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Titre</FormLabel>
                                        <Input maxLength={50} name="type" onChange={e => setTitle(e.target.value)} placeholder='Titre de notification...' />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Message</FormLabel>
                                        <Textarea height={"100px"} resize={"none"} type='text' maxLength={250} name="message" onChange={e => setContent(e.target.value)} placeholder='Message de notification...' />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>Cible</FormLabel>
                                        <Select onChange={handleCibleChange} placeholder='Select option'>
                                            <option value='Client'>Client</option>
                                            <option value='Partenaire'>Partenaire</option>
                                            <option value='Both'>Client / Partenaire</option>
                                        </Select>
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!title || !content || !selectedCible)
                                ?
                                <Button colorScheme='blue' title="Entrer le nom de catégorie" isDisabled
                                >
                                    Ajouter
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutNotification}
                                >
                                    Ajouter
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setSelectedCible('')
                    setTitle('')
                    setContent('')
                    setSelectedCible('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>Modification de notification</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Titre</FormLabel>
                                        <Input maxLength={50} defaultValue={notifData.title} name="title" onChange={e => setTitle(e.target.value)} placeholder='Titre de notification...' />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>Message</FormLabel>
                                        <Textarea height={"100px"} defaultValue={notifData.content} resize={"none"} type='text' maxLength={250} name="message" onChange={e => setContent(e.target.value)} placeholder='Message de notification...' />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>Cible</FormLabel>
                                        <Select onChange={handleCibleChange} defaultValue={notifData.cible} placeholder='Select option'>
                                            <option value='Client'>Client</option>
                                            <option value='Partenaire'>Partenaire</option>
                                            <option value='Both'>Client / Partenaire</option>
                                        </Select>
                                    </FormControl>


                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateNotif(notifId)}
                            >
                                Modifier
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            } */}

        </Box>
    )
}

export default GestPromotions