import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Avatar, Badge, Box, Button, Card, CardBody, CardHeader, Checkbox, Container, Flex, FormControl, FormLabel, Grid, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Text, Textarea, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import { DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPaginantion from '../../components/MyPagination';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { PiPlusBold } from 'react-icons/pi';
import empty from '../../assets/images/emptyupdate.png'
import animationData from '../../assets/lotties/update.json'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MyPagination from '../../components/MyPagination';
import apple from '../../assets/images/apple.png'
import android from '../../assets/images/android.png'
import androidIos from '../../assets/images/update.png'
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

function Updates() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState()

    const { user, setUser } = useStateContext();
    const [notificationsData, setNotificationsData] = useState([]);
    const [updateData, setUpdateData] = useState();
    const [notify, setNotify] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [updates, setUpdates] = useState([]);
    const [updateId, setUpdateId] = useState('');

    const [version, setVersion] = useState('');
    const [link, setLink] = useState('');

    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [selectedC, setSelectedC] = useState('');
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [customMessage, setCustomMessage] = useState('');

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)
    const [etat, setEtat] = useState(false);

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const handleChange = (ev) => {
        setSelectedPlatform(ev.target.value)

    }
    const [paginationData, setPaginationData] = useState()

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////


    //get the data
    useEffect(() => {
        setLoadingData(true)

        axiosClient.get(`/updates/getAllUpdates/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&platform=${selectedC}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setUpdates(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });

    }, [pageSize, currentPage, searchTerm, selectedC])

    function update() {
        setLoadingData(true)

        axiosClient.get(`/updates/getAllUpdates/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&platform=${selectedC}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setUpdates(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
    }



    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/updates/${id}/`)
            .then((response) => {
                setUpdates((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("la mise a à jour est supprimée avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                // update()
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }
    useEffect(() => {
        if (updateData) {
            setEtat(updateData.etat || false);
        }
    }, [updateData]);
    const AjoutUpdate = () => {
        if (!version || version.trim() === "") {
            setMessage(t("Veuillez remplir la version"))
            return;
        }
        if (!link || link.trim() === "") {
            setMessage(t("Veuillez remplir le lien"))
            return;
        }
        const formData = new FormData()
        formData.append("platform", selectedPlatform)
        formData.append("latestVersion", version)
        formData.append("update_url", link)
        formData.append("message", customMessage)
        formData.append("etat", etat);
        axiosClient.post('/updates/', formData)
            .then((response) => {
                const newUpdate = response.data;
                setUpdates([newUpdate, ...updates]);
                setVersion('')
                setLink('')
                setCustomMessage('')
                setSelectedPlatform('')
                setMessage("")
                setEtat(false)
                update()

                if (notify) {
                    // Call backend function to notify users after the update is created
                    axiosClient.post('/notify-users/', { update_id: newUpdate.id })
                        .then(() => {
                            toast({
                                description: t(`Notification envoyée avec succès !`),
                                status: 'success',
                                duration: 2000,
                                isClosable: true,
                                variant: 'left-accent',
                                position: 'bottom-right'
                            });
                            setNotify(false)
                        })
                        .catch((error) => {
                            console.error("Error notifying users:", error);
                            toast({
                                description: t(`Échec de l'envoi de la notification.`),
                                status: 'error',
                                duration: 2000,
                                isClosable: true,
                                variant: 'left-accent',
                                position: 'bottom-right'
                            });
                            setNotify(false)
                        });
                }

                toast({
                    description: t(`La mise à jour est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                });
            })
            .catch((error) => {
                console.error("Error creating update:", error);
                setMessage(t("Échec de la création de la mise à jour"));
            });

        onClose();
    };


    const Update = (id) => {

        const formData = new FormData();
        // if (selectedPlatform) formData.append("platform", selectedPlatform)
        if (version) formData.append("latestVersion", version)
        if (link) formData.append("update_url", link)
        if (customMessage) formData.append("message", customMessage)
        formData.append("etat", etat);
        axiosClient.patch(`/updates/${id}/`, formData).then(() => {

            update()
            if (notify) {
                // Call backend function to notify users after the update is created
                axiosClient.post('/notify-users/', { update_id: id })
                    .then(() => {
                        toast({
                            description: t(`Notification envoyée avec succès !`),
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                            variant: 'left-accent',
                            position: 'bottom-right'
                        });
                        setNotify(false)
                        setCustomMessage('')
                        setEtat(false)

                    })
                    .catch((error) => {
                        console.error("Error notifying users:", error);
                        toast({
                            description: t(`Échec de l'envoi de la notification.`),
                            status: 'error',
                            duration: 2000,
                            isClosable: true,
                            variant: 'left-accent',
                            position: 'bottom-right'
                        });
                        setNotify(false)
                        setCustomMessage('')
                        setEtat(false)
                    });
            }
            toast({
                description: t(`La mise à jour est modifiée avec succès`),
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })

            setMessage("")

        })

        onClose()
    }
    const myColor = useColorModeValue("gray.50", "gray.700");

    return (
        <Box>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span>{t("Mises à jour")}</span> </Text>
                    <Button onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                        leftIcon={<PiPlusBold color='#49b1cc' />} variant='solid'
                        isDisabled={updates.some(update => update.platform === "android") && updates.some(update => update.platform === "ios")}
                    >
                        {t("Ajouter Une Mise à jour")}
                    </Button>
                </Box>

            </Flex>
            {(paginationData?.length === 0 && !searchTerm && !selectedC) ? (
                <Flex mb={'10'} mt={20} gap={2} flexDirection={'column'} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"}>
                    <Text color={'gray.500'} fontWeight={"semibold"}>{t("Il n'y a pas de mises à jour à afficher")}</Text>
                    <Image opacity={0.7} w={{ base: "150px", md: "200px", lg: "200px" }} src={empty} />
                </Flex>
            ) :
                <Flex direction='column' p={{ base: 0, lg: 7 }}>

                    <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr 2fr " }} justify={"center"} gap='22px'>
                        <Flex w={'100%'} h={'100%'} display={{ base: "none", lg: "flex" }} justify={"center"} align={'center'}>
                            {/* <Lottie speed={0.5} options={defaultOptions} /> */}
                            <Image src={androidIos} />
                        </Flex>
                        <Card

                            bg={"none"}
                            border={'none'}
                            shadow={'none'}
                            display={'flex'}

                        >

                            <CardBody px='2px' >

                                {/* <Flex

                                    justifyContent="end"
                                    alignItems="center"
                                    direction={{ base: 'column', md: 'row' }}
                                >
                                    <Select borderRadius={"5"} bg={myColor} fontSize={{ base: 13, lg: 16 }} size={"md"} w={{ base: '100%', md: "25%" }} mr={{ base: 0, md: 5 }} mb={4} value={selectedC} onChange={(e) => setSelectedC(e.target.value)}>
                                        <option value="">{t("Tout")}</option>
                                        <option value="Android">Android</option>
                                        <option value="iOS">iOS</option>
                                    </Select>
                                    <InputGroup size={"md"} w={{ base: '100%', md: "40%" }}>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<SearchIcon color='gray.300' />}
                                        />
                                        <Input
                                            borderRadius={"5"}
                                            placeholder={t("Recherche...")}
                                            value={searchTerm}
                                            bg={myColor}
                                            onChange={(e) => {
                                                setCurrentPage(0)
                                                setSearchTerm(e.target.value)
                                            }}
                                            mb={4}
                                            sx={{
                                                marginLeft: 'auto',
                                            }}
                                        />  </InputGroup>

                                </Flex> */}


                                <Flex
                                    direction="column"
                                    width={{ base: "100%", lg: "100%" }}
                                >

                                    {(paginationData?.length !== 0) &&
                                        updates?.map((row, key) => (

                                            <Stack
                                                key={key}
                                                bg={myColor}
                                                mb={5}
                                                rounded={'lg'}
                                                p={8}
                                                boxShadow={'md'}
                                                direction={'row'}
                                            // as={"Button"}

                                            >
                                                <Flex pr={5} justify={"center"} align={"center"} w={"10%"}>
                                                    <Avatar size={"lg"} borderRadius={"5px"} src={row.platform === "ios" ? apple :
                                                        row.platform === "android" ? android : ""} />
                                                </Flex>
                                                <Box w={"90%"} alignItems={'start'}
                                                    justify="space-between">
                                                    <Flex direction="row"
                                                        justify="space-between"
                                                        ml={'auto'}
                                                        mb={3}
                                                        width="full">
                                                        <Stack display={'flex'} flexDirection={{ base: "column", md: "row" }} align={'center'}>
                                                            <Text fontSize={'lg'} fontWeight="semibold">{row.latestVersion}</Text>
                                                            {/* <Stack mt={1}>
                                                                    <Badge
                                                                        p={1}
                                                                        rounded={'lg'}
                                                                        colorScheme={row.platform === "iOS" ? "green" :
                                                                            row.platform === "Android" ? "blue" :
                                                                                ""}
                                                                    >
                                                                        {row.platform}
                                                                    </Badge>
                                                                </Stack> */}
                                                        </Stack>
                                                        <Text mt={2} fontSize={'sm'} color={'gray.500'}>{new Date(row.date_creation).toLocaleDateString(t('fr-FR'), { hour: '2-digit', minute: '2-digit' })}</Text>

                                                    </Flex>

                                                    <Flex
                                                        direction="row"
                                                        width="full"

                                                        ml={'auto'}
                                                        justify="space-between">
                                                        <Text fontSize={{ base: 'sm' }} textAlign={'left'} w={{ base: "80%", md: "80%", lg: "85%" }}>
                                                            {row.update_url}

                                                        </Text>

                                                        <Flex align={'center'} mt={1} direction={'column'}>

                                                            <Flex direction={"row"} >
                                                                <EditIcon
                                                                    size="md"
                                                                    cursor='pointer'
                                                                    onClick={() => {
                                                                        setChangeModal(false)
                                                                        setUpdateData(row)
                                                                        setUpdateId(row.id)
                                                                        onOpen()

                                                                    }}

                                                                    color='green'

                                                                />

                                                                {(user.role === "SUPERADMIN" || user.role === "ADMIN") && <DeleteIcon
                                                                    ml={5}
                                                                    cursor='pointer'
                                                                    size="md"
                                                                    onClick={() => {
                                                                        setId(row.id);
                                                                        onOpenAlert();
                                                                    }}
                                                                    color='#e53e3e'
                                                                // icon={<DeleteIcon />}>
                                                                />
                                                                }

                                                            </Flex>


                                                        </Flex>
                                                    </Flex>
                                                </Box>

                                            </Stack>
                                        ))}
                                    {/* {((searchTerm && paginationData?.length === 0) || (selectedC && paginationData?.length === 0) || (checkLengthData)) && (

                                        <Flex flexDirection={"column"} p={{ base: 5, md: 10, lg: 20 }} w={"100%"} justify={"center"} alignItems={'center'}>
                                            <Text color={'gray.500'} fontWeight={"semibold"}>{t("Aucune mise à jour correspondante n'a été trouvée.")}</Text>
                                        </Flex>
                                    )} */}
                                    {/* <Flex justify="space-between" align="center" w="100%">
                                        <Box flex="1">
                                            <MyPagination
                                                paginationData={paginationData}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                PAGE_SIZE={pageSize}
                                            />
                                        </Box>


                                    </Flex> */}
                                </Flex>


                            </CardBody>
                        </Card>

                    </Grid>
                </Flex>}

            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {changeModal ?
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setVersion('')
                    setSelectedPlatform("")
                    setLink('')
                    setCustomMessage('')
                    setNotify(false)
                    setEtat(false)


                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'une mise à jour")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Version")}</FormLabel>
                                        <Input maxLength={50} name="type" onChange={e => setVersion(e.target.value)} placeholder={t('Version de mise a jour...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Lien de mise à jour")}</FormLabel>
                                        <Input type='text' maxLength={250} name="message" onChange={e => setLink(e.target.value)} placeholder={t('Lien de mise à jour...')} />
                                    </FormControl>
                                    <FormControl isRequired >
                                        <FormLabel>{t("Plateforme")}</FormLabel>
                                        <Select onChange={handleChange} placeholder={t('Sélectionner la plateforme')}>
                                            {!updates.some(update => update.platform === "android") && (
                                                <option value="android">Android</option>
                                            )}
                                            {!updates.some(update => update.platform === "ios") && (
                                                <option value="ios">iOS</option>
                                            )}
                                            {/* <option value="Android">Android</option>
                                            <option value="iOS">iOS</option> */}
                                        </Select>
                                    </FormControl>
                                    <FormControl mt={3}>
                                        <FormLabel>{t("Message")}</FormLabel>
                                        <Textarea
                                            value={customMessage}
                                            onChange={e => setCustomMessage(e.target.value)}
                                            placeholder={t("Ajoutez un message")}
                                        />
                                    </FormControl>
                                    <FormControl mt={3}>
                                        <Checkbox
                                            isChecked={notify}
                                            onChange={(e) => setNotify(e.target.checked)}
                                        >
                                            {t("Notifier les utilisateurs")}
                                        </Checkbox>
                                    </FormControl>
                                    <FormControl mt={3}>
                                        <Checkbox
                                            isChecked={etat}
                                            onChange={(e) => setEtat(e.target.checked)}
                                        >
                                            {t("Affichage de mise à jour")}
                                        </Checkbox>
                                    </FormControl>



                                </Flex>
                            </Flex>
                        </ModalBody>

                        <ModalFooter>

                            {(!link || !version || !selectedPlatform || !customMessage)
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutUpdate}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }

                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setVersion('')
                    setSelectedPlatform("")
                    setLink('')
                    setCustomMessage('')
                    setNotify(false)
                    setEtat(false)
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Modification de mise à jour")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Version")}</FormLabel>
                                        <Input maxLength={50} name="type" defaultValue={updateData.latestVersion} onChange={e => setVersion(e.target.value)} placeholder={t('Titre de notification...')} />
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Lien de mise à jour")}</FormLabel>
                                        <Input type='text' maxLength={250} defaultValue={updateData.update_url} name="message" onChange={e => setLink(e.target.value)} placeholder={t('Lien de mise à jour...')} />
                                    </FormControl>
                                    <FormControl mt={3}>
                                        <FormLabel>{t("Message")}</FormLabel>
                                        <Textarea
                                            defaultValue={updateData.message}
                                            onChange={e => setCustomMessage(e.target.value)}
                                            placeholder={t("Ajoutez un message")}
                                        />
                                    </FormControl>
                                    {/* <FormControl isRequired >
                                        <FormLabel>{t("Cible")}</FormLabel>
                                        <Select onChange={handleChange} defaultValue={updateData.platform} placeholder={t('Sélectionner la plateforme')}>
                                        <option value="Android">Android</option>
                                        <option value="iOS">iOS</option>
                                        </Select>
                                    </FormControl> */}
                                    <FormControl mt={3}>
                                        <Checkbox
                                            isChecked={notify}
                                            onChange={(e) => setNotify(e.target.checked)}
                                        >
                                            {t("Notifier les utilisateurs")}
                                        </Checkbox>
                                    </FormControl>
                                    <FormControl mt={3}>
                                        <Checkbox
                                            isChecked={etat}
                                            onChange={(e) => setEtat(e.target.checked)}
                                            // defaultChecked={updateData.etat}
                                        >
                                            {t("Affichage de mise à jour")}
                                        </Checkbox>
                                    </FormControl>
                                </Flex>
                            </Flex>
                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => Update(updateId)}
                            >
                                {t("Modifier")}
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>
    )
}

export default Updates