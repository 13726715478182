import { createContext, useContext, useState, useEffect } from "react";
import axiosClient from "../axios-client";
import { Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const StateContext = createContext({
  currentUser: null,
  token: null,
  notification: null,
  setUser: () => { },
  setToken: () => { },
  setNotification: () => { },
  setIsLoading: () => { },
  countTickets: () => { },

});

export const ContextProvider = ({ children }) => {
  const secretKeyEnv = process.env.REACT_APP_SECRET_KEY;
  const getEncryptionKey = () => {
    const deviceInfo = navigator.userAgent;
    // console.log("deviceInfo",deviceInfo);

    const secretKey = secretKeyEnv;
    // console.log("secretKey",secretKeyEnv);
    return secretKey + deviceInfo;
  };
  const encryptToken = (token) => {
    const key = getEncryptionKey();
    return CryptoJS.AES.encrypt(token, key).toString();
  };

  const decryptToken = (encryptedToken) => {
    try {
      const key = getEncryptionKey();
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      // console.error("Error decrypting token:", error);
      return null;
    }
  };

  const [user, setUser] = useState({});
  const [token, _setToken] = useState(() => {
    const encryptedToken = localStorage.getItem("U_T");
    return encryptedToken ? decryptToken(encryptedToken) : null; // Decrypt the token when loading
  });
  const [countTickets, setCountTickets] = useState();



  //const [notification, _setNotification] = useState('');
  const [isLoading, _setIsLoading] = useState(false);


  useEffect(() => {
    if (token) {
      axiosClient
        .get("auth/user/")
        .then(({ data }) => {
          setUser(data);

        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }, [token]);

  const setToken = (token) => {
    if (token) {
      const encryptedToken = encryptToken(token); // Encrypt the token
      localStorage.setItem("U_T", encryptedToken);
    } else {
      localStorage.removeItem("U_T");
    }
    _setToken(token);
  };


  const setIsLoading = (v) => {
    _setIsLoading(v);
  };





  return (
    <StateContext.Provider
      value={{

        user,
        setUser,
        token,
        setToken,
        setIsLoading,
        isLoading,
        countTickets,
        setCountTickets,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
export const useStateContext = () => useContext(StateContext);
