import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, VStack, HStack, Text, Avatar, Input, Button, Divider, useColorModeValue, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useDisclosure, CircularProgress, Spinner, Card, CardBody, Stack, Heading, Badge } from '@chakra-ui/react';
import axiosClient, { socketUrl } from '../../axios-client';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import chatbg from '../../assets/images/chatbg.png'
import { useStateContext } from '../../context/ContextProvider';
function ChatRoom({ sender, recepteur, room, image, name }) {
    const { user } = useStateContext()
    const { t } = useTranslation()
    const chatBg = useColorModeValue('gray.100', 'gray.900');

    const chatBorderBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');

    const chat1 = useColorModeValue('gray.200', 'gray.500');
    const chat2 = useColorModeValue('blue.100', 'blue.500');
    const [partenaire, setPartenaire] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState('');

    const [webSocket, setWebSocket] = useState(null);
    const [demandsWebSocket, setDemandsWebSocket] = useState(null);
    const [dataDemandes, setDataDemandes] = useState([])

    const [messages, setMessages] = useState([]);
    const [client, setClient] = useState();

    const chatContainerRef = useRef(null);
    const bottomRef = useRef(null);
    const [loading, setLoading] = useState(true)

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        onOpen();
    };
    useEffect(() => {
        axiosClient.get(`/partenaires/${sender}/`)
            .then(({ data }) => {
                setPartenaire(data)

            })
    }, [])

    const fetchData = async (url) => {
        try {
            axiosClient.get(url).then(res => {

                if (res.data.length === 0) {
                    setLoading(false)
                } else {
                    setDataDemandes(prevData => {
                        setLoading(false)
                        return [...prevData, ...res.data.results]
                    })
                    console.log("hmm", res.data.results);

                }
            })
        } catch (error) {
            console.log('ERROR : ', error);
            setLoading(false)
        }
    }
    useEffect(() => {
        axiosClient.get(`/clients/${recepteur}/`)
            .then(({ data }) => {
                setClient(data)
            })
        const socket = new WebSocket(`${socketUrl}/ws/chat/${room}/${sender}/${user.id}/`);
        const demandsSocket = new WebSocket(`${socketUrl}/ws/demande/${sender}/`);

        socket.onopen = () => {
            setWebSocket(socket);
        };
        demandsSocket.onopen = () => {
            console.log('WebSocket connected demandsSocket :', socket);
            setDemandsWebSocket(socket);
        };

        fetchData(`/demandes/getDemandeByPartner_Client/?page=1&page_size=1000&partenaire=${sender}&client=${recepteur}`)

        demandsSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('demand_notification : ', data);

            if (data.type === 'demand_notification') {
                // Mettre à jour l'état de la demande dans les messages
                // alert('hh : ',data.serialized_demand)
                setDataDemandes(prevData => {
                    return [...prevData, {
                        offre: data?.demand?.offer_id,
                        id: data?.demand?.id
                    }]
                })
                console.log('demand_notification : ', data.demand);



            }
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'fetch_messages') {
                setMessages(data.messages);
                setLoading(false)
            } else if (data.type === 'chat_message') {
                setMessages(prevMessages => [...prevMessages, {
                    sender: data.sender,
                    message: data.message,
                    date_creation: data.date_creation,
                    type_file: data.type_file,
                    file_url: data.file_url,
                    vu: false
                }]);
                setLoading(false)
            }
        };
        demandsSocket.onclose = () => {
            console.log('WebSocket closed demandsSocket');
        };
        socket.onclose = () => {
            // console.log('WebSocket closed');
        };

        return () => {
            demandsSocket.close();
            socket.close();
        };
    }, [room, recepteur, sender, user.id]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);
    const dateOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const blend = useColorModeValue("", "overlay")

    return (
        <>
            <Box height={"100%"} flex="2" p="4" bg={chatBorderBg} w={{ base: '100%', md: 'auto' }}>
                <VStack align="start" h="100%">
                    <HStack
                    
                        spacing="3"
                        p="2"
                        // py={2}
                        borderRadius="md"
                        w="100%"
                    >
                        <Avatar src={image} name={name} size="md" />
                        <Text fontSize="md">{name} </Text>
                    </HStack>

                    <Box
                        ref={chatContainerRef}
                        flex="1"
                        w="100%"
                        overflowY="auto"
                        p="4"
                        border="1px"
                        borderColor={borderColor}
                        borderRadius="md"
                        bg={chatBg}
                        bgImage={chatbg}
                        bgBlendMode={blend}

                    >
                        {loading ?
                            <Flex w={"100%"} h={"100%"} justify={"center"} align={"center"}>
                                <Spinner size='xl' speed='0.7s' thickness='5px' color='gray.500' />
                            </Flex>

                            :
                            <VStack pb={4} spacing="4" align="start">
                                {messages.map((msg, index) => {
                                    const a = msg.sender_id ? msg.sender_id : msg.sender;
                                    const isToday = new Date(msg.date_creation).toDateString() === new Date().toDateString();
                                    const showTodayDivider = isToday && (index === 0 || new Date(messages[index - 1].date_creation).toDateString() !== new Date().toDateString());

                                    return (
                                        <React.Fragment key={index}>
                                            {showTodayDivider && (
                                                <Text color="gray.500" alignSelf="center">
                                                    {t("Aujourd'hui")}
                                                </Text>
                                            )}
                                            {msg.type_file && (
                                                <HStack spacing="3" alignSelf={parseInt(a) === parseInt(sender) ? 'flex-end' : 'flex-start'} w={{base:"70%",md:"60%" , lg:"55%"}}>
                                                    {parseInt(a) !== parseInt(sender) && <Avatar display={{base:"none",xl:'flex'}}  bg={'gray.300'} src={image} name={name} size="sm" />}
                                                    <Flex w={"100%"} flexDirection={"column"}>
                                                        <Box w={"100%"} borderRadius="md">
                                                            <Image onClick={() => handleImageClick(msg.type_file ? msg.type_file : msg.type_file)} cursor={"pointer"} objectFit={'cover'} borderRadius="xl" w={"100%"} maxH={"280px"} src={msg.type_file ? msg.type_file : msg.type_file} />
                                                        </Box>
                                                        <Text textAlign={"end"} color={"gray.500"} fontSize={"12px"}>{isToday ? new Date(msg.date_creation).toLocaleTimeString(t('fr-FR')) : new Date(msg.date_creation).toLocaleString(t('fr-FR'), dateOptions)}</Text>
                                                    </Flex>
                                                    {parseInt(a) === parseInt(sender) && <Avatar display={{base:"none",xl:'flex'}} bg={'blue.200'} src={partenaire?.image} name={partenaire?.last_name} size="sm" />}
                                                </HStack>
                                            )}
                                            {msg.message && (
                                                <HStack spacing="3" alignSelf={parseInt(a) === parseInt(sender) ? 'flex-end' : 'flex-start'} maxW={{base:"70%",md:"60%" , lg:"55%"}}>
                                                    {parseInt(a) !== parseInt(sender) && <Avatar display={{base:"none",xl:'flex'}} mt={2}  alignSelf={"start"} bg={'gray.300'} src={image} name={name} size="sm" />}
                                                    <Flex w={"100%"} flexDirection={"column"}>
                                                        <Box w={"100%"} bg={parseInt(a) === parseInt(sender) ? chat2 : chat1} p="2.5" borderRadius="md">
                                                            <Text w={"100%"}>{msg.message}</Text>
                                                        </Box>
                                                        <Text textAlign={"end"} color={"gray.500"} fontSize={"12px"}>{isToday ? new Date(msg.date_creation).toLocaleTimeString(t('fr-FR')) : new Date(msg.date_creation).toLocaleString(t('fr-FR'), dateOptions)}</Text>
                                                    </Flex>
                                                    {parseInt(a) === parseInt(sender) && <Avatar display={{base:"none",xl:'flex'}} mt={2}  alignSelf={"start"} bg={'blue.200'} src={partenaire?.image} name={partenaire?.last_name} size="sm" />}
                                                </HStack>
                                            )}

                                            {msg.offres.length !== 0 && msg.offres.map((offre, offreIndex) => (

                                                <HStack spacing="3" alignSelf={parseInt(a) === parseInt(sender) ? 'flex-end' : 'flex-start'} w={{base:"70%",md:"60%" , lg:"55%"}}>
                                                    {parseInt(a) !== parseInt(sender) && <Avatar  display={{base:"none",xl:'flex'}} bg={'gray.300'} src={image} name={name} size="sm" />}
                                                    <Flex w={"100%"} flexDirection={"column"}>

                                                        <Card w={"100%"} borderRadius="md">
                                                            <CardBody>
                                                                {offre.image && (
                                                                    <>
                                                                        <Image onClick={() => handleImageClick(offre.image ? offre.image : offre.image)} cursor={"pointer"} objectFit={'cover'} borderRadius="xl" w={"100%"} maxH={"220px"} src={offre.image ? offre.image : offre.image} />
                                                                    </>
                                                                )}
                                                                <Stack mt='4' spacing='1'>
                                                                    <Heading size='md'>{offre.labelle}</Heading>
                                                                    <Text>
                                                                        {offre.description}
                                                                    </Text>
                                                                    {/* <Text color='blue.600' fontSize='2xl'>
                                                                    {offre.price} {offre.currency.toUpperCase()}
                                                                    </Text> */}
                                                                    <Flex justify={"space-between"} align={"center"} gap={2}>
                                                                        <Text fontWeight={"semibold"} fontSize='lg'>
                                                                            {offre.price} {offre.currency.toUpperCase()}
                                                                        </Text>
                                                                        {offre.reduction > 0 && (
                                                                            <Box >

                                                                                <Badge p={1} variant='solid' borderRadius={"md"} colorScheme='red'>-{offre.reduction}%</Badge>
                                                                                {/* <Text >
                                                                                    -{offre.reduction}%
                                                                                </Text> */}
                                                                            </Box>
                                                                        )}
                                                                    </Flex>
                                                                    {offre.adresse && (
                                                                        <Text >
                                                                            📍 {offre.adresse} {offre.id}
                                                                        </Text>
                                                                    )}
                                                                </Stack>
                                                                {dataDemandes.some(e => e.offre === offre.id) &&
                                                                <Button isDisabled mt={2} w={"100%"} variant='solid' colorScheme={dataDemandes.some(e => parseInt(e.offre) === parseInt(offre.id) && e.is_used) ? 'green':'blue' } >
                                                                    {dataDemandes.some(e => parseInt(e.offre) === parseInt(offre.id) && e.is_used)
                                                                        ? <Text >{t('Déjà validée')}</Text>
                                                                        : <Text >{t('Nouvelle demande')}</Text>
                                                                    }
                                                                </Button>}

                                                            </CardBody>

                                                        </Card>
                                                        <Text textAlign={"end"} color={"gray.500"} fontSize={"12px"}>{isToday ? new Date(msg.date_creation).toLocaleTimeString(t('fr-FR')) : new Date(msg.date_creation).toLocaleString(t('fr-FR'), dateOptions)}</Text>
                                                    </Flex>
                                                    {parseInt(a) === parseInt(sender) && <Avatar display={{base:"none",xl:'flex'}} bg={'blue.200'} src={partenaire?.image} name={partenaire?.last_name} size="sm" />}
                                                </HStack>
                                            ))}


                                        </React.Fragment>
                                    );
                                })}
                                <div ref={bottomRef}></div>

                            </VStack>
                        }
                    </Box>


                </VStack>
            </Box>
            <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay
                    bg={"blackAlpha.700"}

                />
                <ModalContent>
                    <ModalBody p={0}>
                        <Image rounded={"md"} src={selectedImage} h={"100%"} w="100%" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChatRoom