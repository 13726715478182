import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
// import { LiaUserEditSolid } from 'react-icons/lia';
import { TbHomeCog } from 'react-icons/tb';
import { MdGroupRemove } from 'react-icons/md';
import { FiEdit3 } from 'react-icons/fi';
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    useToast,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tooltip,
    Text,
    SkeletonCircle,
    Skeleton,
} from '@chakra-ui/react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

export const GestPartenaires = () => {
    const { t } = useTranslation();
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [loadingData, setLoadingData] = useState(true)

    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState();
    const [currentPage, setCurrentPage] = useState(0);

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };

    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user } = useStateContext();


    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Partenaires data
    const [data, setData] = useState([]);
    const [pays, setPays] = useState([])
    const [paginationData, setPaginationData] = useState()

    //get the partenaire data
    useEffect(() => {
        // setLoadingData(true)

        axiosClient.get(`/partenaires/getAllPartenaires/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                // if (selectedEtat !== null) {
                //     filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                // }
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });

        axiosClient.get('pays/')
            .then((res) => {
                setPays(res.data);
            })
            .catch((error) => {
                // console.error(error);
            });


    }, [selectedEtat, pageSize, currentPage, searchTerm]);


    const [message, setMessage] = useState(false)

    // delete partenaire
    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/partenaires/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("l'utilisateur est supprimé avec succès"),
                    sstatus: 'success',
                    variant:'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }

    //search method
    // const filteredData = useCallback(() => {
    //     return data.filter((row) => {
    //         // Exclude data of current user
    //         if (row.id === user.id || row.role === "SUPERADMIN") {
    //             return false;
    //         }
    //         if (row.role === "ADMIN" && (user.role !== "SUPERADMIN")) {
    //             return false;
    //         }
    //         return (
    //             row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.pays.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.ville.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.rue.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.code_postal.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.email.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //     });
    // }, [data, searchTerm, user.id]);


    //edit l'etat de partenaire ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_partenaire_etat/${id}/`, formData)
            .then((response) => {
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => {
                // console.error(error)
            });
        if (!etat) {
            toast({
                description: t("le compte est activé"),
                variant: 'left-accent',
                status: 'success',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: t("le compte est désactivé"),
                status: 'warning',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const [year, month, day] = dateString.split('-');
            return `${day}-${month}-${year}`;
        }

    };


    return (
        <Box mt="5px" >
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span >{t("Partenaires")}</span></Text>
                    <Button onClick={() => navigate('/ajouter-partenaire')} leftIcon={<AiOutlineUserAdd color='#49b1cc' />} colorScheme='gray' variant='solid'>
                        {t("Ajouter partenaire")}
                    </Button>
                </Box>
            </Flex>

            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={2}
                    // direction={{ base: 'column', lg: 'row' }}
                    >
                        <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '30%', lg: "15%" }} mb={4} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                            <option value="">{t("Tout")}</option>
                            <option value="true">{t("Activé")}</option>
                            <option value="false">{t("Désactivé")}</option>
                        </Select>


                        <InputGroup size="sm" w={{ base: '70%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table size="sm" variant='striped' colorScheme="gray">
                            <Thead size="sm">
                                <Tr fontSize="12">
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Code</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t('Nom entreprise')}</Th>

                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Nom")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Prénom")}</Th> */}
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12"> Email</Th>
                                    {/* <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Adresse")}</Th> */}
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Pays")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("État")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Entreprise")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Date Création")}</Th>
                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                        <Th fontSize="12">Options</Th>
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>

                                    </Tr>}
                                {(paginationData?.length !== 0) &&
                                    data.map((row) => (
                                        <Tr key={row.id}>
                                            <Td > <Avatar size='md' src={row.image} /></Td>
                                            <Td >{row.code}</Td>
                                            <Td >{row.company_name}</Td>
                                            {/* <Td>{row.last_name}</Td>
                                        <Td>{row.first_name}</Td> */}
                                            <Td >{row.email}</Td>
                                            {/* <Td>
                                            <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                            </Tooltip>
                                        </Td> */}
                                            <Td>{pays.find(e => e.id === parseInt(row.pays_partenaire))?.nom_pays}</Td>
                                            <Td>
                                                <Flex>
                                                    <Stack direction='row' mr="3px">
                                                        {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                            <Switch
                                                                colorScheme='green'
                                                                isChecked={row.etat}
                                                                onChange={() => handleEtatClick(row.id, row.etat)}
                                                            />
                                                        }
                                                    </Stack>
                                                    <Badge
                                                        size="sm"
                                                        rounded={'lg'}
                                                        colorScheme={!row.etat ? 'red' : 'green'}
                                                    >
                                                        {row.etat ? t("Activé") : t("Désactivé")}
                                                    </Badge>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                    <IconButton
                                                        colorScheme="linkedin"
                                                        size="sm"
                                                        onClick={() => navigate(`/entreprise`, {
                                                            state: {
                                                                idPartenaire: row.id
                                                            }
                                                        })}

                                                        mr={{ base: 0, md: 2, lg: 2 }}
                                                        icon={<TbHomeCog fontSize={20} />}

                                                    ></IconButton>
                                                </div>
                                            </Td>
                                            <Td>{new Date(row.date_creation).toLocaleDateString(t("fr-FR"))}</Td>

                                            <Td>

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => navigate(`/modifier-partenaire`, {
                                                        state: {
                                                            idPartenaire: row.id
                                                        }
                                                    })}
                                                    mr="5px" variant='outline'
                                                    colorScheme="whatsapp"
                                                    icon={<FiEdit3 />}>
                                                </IconButton>

                                                {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                    <>
                                                        <IconButton
                                                            size="sm"
                                                            onClick={() => {
                                                                setDeleteId(row.id);
                                                                onOpenAlert();

                                                            }}
                                                            mr="5px"

                                                            colorScheme="red"
                                                            icon={<MdGroupRemove />}>
                                                        </IconButton>
                                                        <IconButton
                                                            size="sm"
                                                            onClick={() => {
                                                              navigate("/chat", {
                                                                state: {
                                                                    idPartenaire: row.id
                                                                }
                                                            })

                                                            }}
                                                            mr="5px"

                                                            colorScheme="blue"
                                                            icon={<IoChatbubbleEllipsesSharp />}>
                                                        </IconButton>
                                                    </>

                                                }
                                            </Td>

                                        </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat !== null) && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>


                            <Text>
                                {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                            </Text>

                        </AlertDialogBody>



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>


        </Box>

    )
}

export default GestPartenaires