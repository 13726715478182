import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex, VStack, HStack, Text, Avatar, Input, Button, Divider, useColorModeValue, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useDisclosure, Badge, Skeleton } from '@chakra-ui/react';
import axiosClient, { socketUrl } from '../../axios-client';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emptychat from '../../assets/images/emptychat.png'
import ChatRoom from './ChatRoom';
function Rooms() {
    
    const location = useLocation()
    const { t } = useTranslation()
    const userBg = useColorModeValue('white', 'gray.800');
    const userHoverBg = useColorModeValue('gray.200', 'gray.600');
    const chatBorderBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'gray.600');


    const emptybg = useColorModeValue('gray.100', 'gray.700');
    const [partenaire, setPartenaire] = useState([])
    const [clients, setClients] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState('');

    const [rooms, setRooms] = useState([]);
    const [webSocket, setWebSocket] = useState(null);
    const [selectedReceptor, setSelectedReceptor] = useState()
    const [selectedRoom, setSelectedRoom] = useState()
    const [selectedImg, setSelectedImg] = useState()
    const [selectedName, setSelectedName] = useState()
    const [loading, setLoading] = useState(true)

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        onOpen();
    };
    
    const bottomRef = useRef(null); // Create a ref to the bottom element

    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        axiosClient.get(`/partenaires/${location.state.idPartenaire}/`)
            .then(({ data }) => {
                setPartenaire(data)

            })
        axiosClient.get(`/clients/`)
            .then(({ data }) => {
                setClients(data)
            })
    }, [location.state.idPartenaire])

    useEffect(() => {
        const socket = new WebSocket(`${socketUrl}/ws/user/${location.state.idPartenaire}/`);

        socket.onopen = () => {
            // console.log('WebSocket connected:', socket);
            setWebSocket(socket);
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            // console.log('DATAAAAA MESSAGES : ', data);
            if (data.type === 'fetch_rooms') {
                // console.log('Updating rooms:', data.rooms);
                setRooms(data.rooms);
                setLoading(false)

            } else if (data.type === 'chat_message') {
                // console.log('Received chat message:', data);
                // Update the relevant room with the new message
                setRooms(prevRooms => {
                    const updatedRooms = prevRooms.map(room => {
                        if (room.room_name === data.room_name) {
                            return {
                                ...room,
                                last_message: data.message,
                                latest_message_date: data.date_creation
                            };
                        }
                        return room;
                    });
                    // Move the updated room to the top of the list
                    const updatedRoom = updatedRooms.find(room => room.room_name === data.room_name);
                    const filteredRooms = updatedRooms.filter(room => room.room_name !== data.room_name);
                    return [updatedRoom, ...filteredRooms];
                });
            }
        };

        socket.onclose = () => {
            // console.log('WebSocket closed');
        };

        scrollToBottom()

        return () => {
            socket.close();
        };
    }, [location.state.idPartenaire]);


   

    return (
        <Flex
            height={{ base: 'auto', md: '90vh' }}
            direction={{ base: 'column', lg: 'row' }}
            bg={useColorModeValue('gray.50', 'gray.900')}
        >
            <Box
                flex={{ base: 'none', md: '1' }}
                p="4"
                borderRight={"1px"}
                borderBottom={{ base: '1px', md: 'none' }}
                borderColor={borderColor}
                bg={userBg}
                h="100%"
                w={{ base: '100%', md: 'auto' }}
            >
                <Flex borderBottom="2px" borderColor={borderColor} pb="4" flexDirection="column">
                    <Text fontSize="xl" fontWeight="bold" mb="2">{t("Partenaire")}</Text>

                    <HStack spacing="3" p="2" borderRadius="md" w="100%">
                        <Avatar src={partenaire?.image} name="Segun Adebayo" size="lg" />
                        <Flex flexDirection="column">
                            <Text fontSize="md" fontWeight={"semibold"}>{partenaire?.company_name}</Text>
                            <Text fontSize="sm">{partenaire?.email}</Text>
                        </Flex>
                    </HStack>
                </Flex>

                <Flex h="calc(100% - 150px)" flexDirection="column" overflowY="auto" mt="4">
                    {/* <Flex justifyContent={"space-between"} mb="5" gap={2} alignItems={"center"} >
                        <Text fontSize="xl" fontWeight="bold" >Clients</Text>
                        <Input  rounded={"xl"} type='text' placeholder='Entrez le nom de client' />
                    </Flex> */}
                    <Text fontSize="xl" fontWeight="bold" mb={5} >Clients</Text>
                    {loading ? (
                        <Skeleton height='50px' />
                    ) : (
                        rooms.length > 0 ? (
                            <VStack spacing="2" align="start">
                                {rooms.map((room, key) => {
                                    const lastMessage = room.last_message;
                                    const unreadMessage = room.unread_messages;
                                    return (
                                        <HStack
                                            key={key}
                                            spacing="3"
                                            p="2"
                                            bg={unreadMessage > 0 ? userHoverBg : undefined}
                                            _hover={{ bg: userHoverBg, cursor: 'pointer' }}
                                            borderRadius="md"
                                            w="100%"
                                            onClick={() => {
                                                scrollToBottom();
                                                setSelectedReceptor(room.user1_id === partenaire.id ? room.user2_id : room.user1_id);
                                                setSelectedRoom(room.room_name);
                                                setSelectedImg(room.user1_id === partenaire.id ? `${room.user2_details.image}` : `${room.user1_details.image}`);
                                                setSelectedName(room.user1_id === partenaire.id ? `${room.user2_details.first_name} ${room.user2_details.last_name}` : `${room.user1_details.first_name} ${room.user1_details.last_name}`);
                                                
                                            }}
                                            justifyContent="space-between"
                                        >
                                            <Flex gap={3} alignItems="center">
                                                <Avatar
                                                    src={room.user1_id === partenaire.id ? `${room.user2_details.image}` : `${room.user1_details.image}`}
                                                    name={room.user1_id === partenaire.id ? `${room.user2_details.first_name} ${room.user2_details.last_name}` : `${room.user1_details.first_name} ${room.user1_details.last_name}`}
                                                    size="md"
                                                />
                                                <Flex flexDirection="column">
                                                    <Text fontSize="md" fontWeight="semibold">{room.user1_id === partenaire.id ? `${room.user2_details.first_name} ${room.user2_details.last_name}` : `${room.user1_details.first_name} ${room.user1_details.last_name}`}</Text>
                                                    {lastMessage
                                                        ? <Text fontSize="sm" color="gray.500">
                                                            {lastMessage.length > 40 ? `${lastMessage.substring(0, 40)}...` : lastMessage}
                                                        </Text>
                                                        : <Text fontSize="sm" color="gray.500">Image</Text>
                                                    }                                                </Flex>
                                            </Flex>
                                            {unreadMessage > 0 && (
                                                <Badge display="flex" alignItems="center" justifyContent="center" minW="25px" minH="25px" fontSize="0.8em" rounded="50%" colorScheme="red">
                                                    <Text>{unreadMessage > 99 ? "+99" : unreadMessage}</Text>
                                                </Badge>
                                            )}
                                        </HStack>
                                    );
                                })}
                            </VStack>
                        ) : (
                            <Text>{t("Ce partenaire n'a pas encore de discussions.")}</Text>
                        )
                    )}

                </Flex>
            </Box>
            {selectedRoom ?
                <ChatRoom sender={partenaire?.id} recepteur={selectedReceptor} room={selectedRoom} image={selectedImg} name={selectedName} />
                :
                <Box flex="2" p="4" bg={chatBorderBg} w={{ base: '100%', md: 'auto' }}>
                    <VStack align="start" h="100%">

                        <Box
                            flex="1"
                            w="100%"
                            overflowY="auto"
                            p="4"
                            bg={emptybg}
                        //   bgImage={"https://camo.githubusercontent.com/cba518ead87b032dc6f1cbfc7fade27604449201ac1baf34d889f77f093f01ac/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67"}
                        >
                            <Flex gap={2} flexDirection={'column'} h={"100%"} w={"100%"} alignItems={"center"} justifyContent={"center"}>
                                <Text fontWeight={"semibold"}>{t("Bienvenu")},</Text>
                                <Text fontWeight={"semibold"}>{t("Cliquez sur une discussion pour voir les messages.")}</Text>
                                <Image w={"300px"} src={emptychat} />
                            </Flex>

                        </Box>

                    </VStack>
                </Box>
            }
     <div ref={bottomRef}></div>
        </Flex>
    )
}

export default Rooms