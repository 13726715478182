import { Alert, AlertIcon, Avatar, AvatarBadge, Box, Button, Card, CardBody, CardHeader, DarkMode, Divider, Flex, FormControl, FormLabel, Grid, HStack, Icon, Input, InputGroup, InputRightElement, Select, Stack, Text, useColorMode, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdSettings } from 'react-icons/md'
import { FaCube, FaPenFancy, FaPencilAlt } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'
import { PiGenderFemaleBold } from 'react-icons/pi'
import { IoMdMale, IoMdFemale } from 'react-icons/io'
import { useStateContext } from '../../context/ContextProvider'
import axiosClient from '../../axios-client';
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AddIcon, CloseIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import countryData from '../../assets/dataJson/countries.json';
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import MySelect from "react-select";
import makeAnimated from 'react-select/animated';
import Resizer from "react-image-file-resizer";


function EditPartenaire() {
    const { user } = useStateContext();
    const navigate = useNavigate()
    const [selectedImage, setSelectedImage] = useState()
    const [image, setImage] = useState()
    const { Cuser, setCUser } = useStateContext();
    const [listPartenaires, setListPartenaires] = useState([])
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const { t } = useTranslation();
    const animatedComponents = makeAnimated();

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    //toast variable
    const toast = useToast()
    //password variables
    const [messageP, setMessageP] = useState('');
    const [partenaire, setPartenaire] = useState([])

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyDesc, setCompanyDesc] = useState('');
    const [companyName, setCompanyName] = useState('');

    const [email, setEmail] = useState('');
    // const [rue, setRue] = useState('');
    const [pays, setPays] = useState([]);
    const [tel, setTel] = useState('');
    const [birthday, setBirthday] = useState('');

    const location = useLocation()

    const [paysOptions, setPaysOptions] = useState([
        { value: '', label: '' },
    ]);
    const [cityOptions, setCityOptions] = useState([
        { value: '', label: '' },
    ]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedCityOptions, setSelectedCityOptions] = useState(null);

    const [selectedImg, setSelectedImg] = useState()

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    const [listSelects, setListSelects] = useState([{ cats: null, subCats: null }]);

    const handlePaysSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };
    const handleCitySelectChange = (selectedOptions) => {
        setSelectedCityOptions(selectedOptions);
    };

    const handleChangeImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedImage(imageFile);
        } catch (err) {
            // console.log(err);
        }
    }


    useEffect(() => {
        if (selectedImg) {
            setImage(URL.createObjectURL(selectedImg));
        } else {
            setSelectedImg();
        }

        const fetchData = async () => {
            try {
                // Fetch partenaire details
                const partenaireResponse = await axiosClient.get(`/partenaires/${location.state.idPartenaire}/`);
                const partenaireData = partenaireResponse.data;
                setPartenaire(partenaireData); // Set partenaire data

                // Fetch pays options
                const paysResponse = await axiosClient.get('/pays/');
                const paysOptions = paysResponse.data.map(pay => ({
                    value: pay.id,
                    label: pay.nom_pays,
                }));
                setPaysOptions(paysOptions);

                // Determine default pays
                const defaultOption = paysOptions.find(opt => opt.value === partenaireData.pays_partenaire) || null;
                setSelectedOptions(defaultOption);



                // Fetch villes options based on default pays
                if (defaultOption) {
                    const villesResponse = await axiosClient.get('/villes/');
                    const cityOptions = villesResponse.data.filter(e => e.pays === defaultOption.value).map(city => ({
                        value: city.id,
                        label: city.nom_ville,
                    }));
                    setCityOptions(cityOptions);

                    // Set default villes based on partenaire data
                    const defaultCities = partenaireData.ville_partenaire?.map(villeId => ({
                        value: villeId,
                        label: cityOptions.find(c => c.value === villeId)?.label || '',
                    })) || [];
                    setSelectedCityOptions(defaultCities);

                }

                const categoriesResponse = await axiosClient.get('/categories/');
                const subCategoriesResponse = await axiosClient.get('/subCategories/');

                const categoriesOptions = categoriesResponse.data.map(category => ({
                    value: category.id,
                    label: category.name,
                }));
                // console.log("catOptions", categoriesOptions);

                const subCategoriesOptions = subCategoriesResponse.data.map(subcategory => ({
                    value: subcategory.id,
                    label: subcategory.nom_subCategory,
                    category: subcategory.category, // Assuming each subcategory has a category field
                }));
                // console.log("subCatsOptions", subCategoriesOptions);


                setCategoriesOptions(categoriesOptions);
                setSubCategoriesOptions(subCategoriesOptions);

                const defaultCategories = categoriesOptions.filter(option => partenaireData.category.includes(option.value));
                const defaultSubCategories = subCategoriesOptions.filter(option => partenaireData.sub_cat.includes(option.value));

                setSelectedCategories(defaultCategories);
                setSelectedSubCategories(defaultSubCategories);

                // Initialize listSelects with default values
                const initialSelects = defaultCategories.map(cat => ({
                    cats: cat,
                    subCats: defaultSubCategories.filter(sub => sub.category === cat.value),
                }));
                setListSelects(initialSelects);
            } catch (error) {
                // console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedImg, location.state.idPartenaire]);

    const handleSelectChange = (index, selectedOption) => {
        const newSelects = [...listSelects];
        newSelects[index].cats = selectedOption;

        // Reset subcategories when the category changes
        newSelects[index].subCats = null;
        setListSelects(newSelects);
    };

    const handleSCatSelectChange = (index, selectedOption) => {
        const newSelects = [...listSelects];
        newSelects[index].subCats = selectedOption;
        setListSelects(newSelects);
    };

    const removeSelects = (index) => {
        const newSelects = listSelects.filter((_, i) => i !== index);
        setListSelects(newSelects);
    };

    const AddSelects = () => {
        setListSelects([...listSelects, { cats: null, subCats: null }]);
    };

    const getCategories = () => {
        return (
            <>
                {listSelects.map((val, index) => (
                    <Flex mt={2} justify={"space-between"} pb={3} key={index} gap={3} align={'center'} w={"100%"}>
                        <Box w={"50%"}>
                            <MySelect
                                name='cats'
                                value={val.cats}
                                onChange={(e) => handleSelectChange(index, e)}
                                options={categoriesOptions}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: 'transparent',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        backgroundColor: 'white',
                                        cursor: "pointer",
                                    }),
                                }}
                            />
                        </Box>
                        <Box w={"50%"}>
                            <MySelect
                                name='subCats'
                                value={val.subCats}
                                onChange={(e) => handleSCatSelectChange(index, e)}
                                options={subCategoriesOptions.filter(e => e.category === val.cats?.value)}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isMulti
                                isClearable
                                styles={{
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: 'transparent',
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        textAlign: 'left',
                                        backgroundColor: 'white',
                                        cursor: "pointer",
                                    }),
                                }}
                            />
                        </Box>
                        {(listSelects.length > 1) && (
                            <CloseIcon color={'red'} className='btnclose' fontSize="xs" cursor="pointer" onClick={() => removeSelects(index)} />
                        )}
                        <AddIcon fontSize="sm" cursor="pointer" onClick={AddSelects} />
                    </Flex>
                ))}
            </>
        );
    };

    // Update city options when selected pays changes
    useEffect(() => {
        if (selectedOptions && selectedOptions.value) {
            const fetchCityOptions = async () => {
                try {
                    const villesResponse = await axiosClient.get('/villes/');
                    const cityOptions = villesResponse.data.filter(e => e.pays === selectedOptions.value).map(city => ({
                        value: city.id,
                        label: city.nom_ville,
                    }));
                    setCityOptions(cityOptions);

                    // Update selected cities based on new city options
                    const updatedSelectedCities = selectedCityOptions.filter(city => cityOptions.some(option => option.value === city.value));
                    setSelectedCityOptions(updatedSelectedCities);
                } catch (error) {
                    // console.error('Error fetching cities data:', error);
                }
            };

            fetchCityOptions();
        }
    }, [selectedOptions]);



    const handleSubmit = async (event) => {
        event.preventDefault();

        // if (user.code_postal && !codepRegex.test(user.code_postal)) {
        //     setMessage("Veuillez entrer un code postal valide.");
        //     return;
        // }
        const selectedCitiesIds = selectedCityOptions ? selectedCityOptions.map(option => option.value) : [];
        const formData = new FormData();
        if (firstName) { formData.append("first_name", firstName); }
        if (lastName) { formData.append("last_name", lastName); }
        if (birthday) { formData.append("birthday", birthday); }
        if (tel) { formData.append("tel", tel); }
        if (selectedImage) formData.append("image", selectedImage);
        if (companyName) formData.append("company_name", companyName);
        if (email) formData.append("email", email);
        if (companyDesc) formData.append("company_description", companyDesc);
        formData.append("pays_partenaire", selectedOptions.value);
        // Append selected categories and subcategories
        if (selectedCitiesIds.length > 0) {
            for (let index = 0; index < selectedCitiesIds.length; index++) {
                formData.append("ville_partenaire", selectedCitiesIds[index]);
            }
        }
        // Append selected categories and subcategories
        listSelects.forEach(select => {
            if (select.cats) {
                formData.append("categories", select.cats.value);
            }
            if (select.subCats) {
                select.subCats.forEach(subCat => {
                    formData.append("subcategories", subCat.value);
                });
            }
        });
        try {
            const response = await axiosClient.put(`/update_partenaire/${partenaire.id}/`, formData)
            if (response.data.message) {
                toast({
                    description: t("les informations sont modifiées avec succès"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'

                });
                setMessageP('')
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
            else if (response.data.error) {
                window.scrollTo(0, 0);
                setMessageP(t("Ce mail existe déjà"))
            }
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
        }

    };
    const colorbg = useColorModeValue('white', '#2d3748')
    const multiLabel = useColorModeValue('#e6e6e6', '#4e536c')

    const multitext = useColorModeValue('black', 'white')


    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
                <Flex
                    mb={{ base: "20px", sm: '24px', md: '50px', xl: '10px' }}
                    borderRadius='15px'
                    px='0px'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    {/* Header */}
                    <Flex

                        direction={{ base: "column", sm: 'column', md: 'row' }}
                        mx='auto'
                        maxH='330px'
                        w={{ sm: '90%', xl: '100%' }}
                        justifyContent={{ sm: 'center', md: 'space-between' }}
                        align='center'
                        p='24px'
                        borderRadius='20px'
                        mt='10px'
                    >
                        <Flex
                            align='center'
                            mb={{ sm: '10px', md: '10px' }}
                            direction={{ sm: 'column', md: 'row' }}
                            w={{ sm: '100%' }}
                            textAlign={{ sm: 'center', md: 'start' }}>
                            <Avatar me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={image ? image : partenaire.image ? partenaire.image : partenaire.image} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>
                                <label htmlFor="imageInput">
                                    <input
                                        id="imageInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleChangeImage}
                                    />
                                    <AvatarBadge
                                        cursor='pointer'
                                        borderRadius='8px'
                                        border='transparent'
                                        bg={useColorModeValue('linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 100%)', 'linear-gradient(138.78deg, rgba(100,107,143,0.94) 17.44%, rgba(35,41,73,0.49) 93.55%, rgba(41,48,88,0.69) 100%)')}
                                        boxSize='26px'
                                        backdropFilter='blur(120px)'>
                                        <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                    </AvatarBadge>
                                </label>
                            </Avatar>
                            <Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
                                <Flex direction='row' align={'center'}>
                                    <Text
                                        fontSize={{ sm: 'lg', lg: 'xl' }}
                                        color={useColorModeValue("gray.700", "white")}
                                        fontWeight='bold'
                                        ms={{ sm: '8px', md: '0px' }}
                                        mr={2}
                                    >
                                        {partenaire.company_name}
                                    </Text>
                                    {partenaire.gender === "Femme" ? <IoMdFemale size={"22px"} color='#ff8096' /> : <IoMdMale size={"22px"} color='#5da4ff' />}
                                </Flex>

                                <Text fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue('gray.400', 'gray.300')}>
                                    {partenaire.email}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex mt={{ base: 4, md: 0 }} direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }}>

                            <Button
                                borderRadius='12px'
                                bg={useColorModeValue('gray.200', 'gray.700')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={MdSettings} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                    {t("PARAMÈTRE")}
                                </Text>
                            </Button>

                        </Flex>
                    </Flex>
                </Flex>
                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: " 1fr " }}   >

                    <Card
                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                        p='16px'
                        mx={{ base: 50, lg: 100, xl: 200 }}
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t('Informations personnelles')}
                            </Text>
                        </CardHeader>
                        {messageP &&
                            <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                <AlertIcon />
                                {messageP}
                            </Alert>
                        }
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                <Flex align='left' mb='14px' direction={{ base: 'column', md: "row" }}  >
                                    <Flex align='center' w={{ base: '100%', md: "50%" }} mr={10}>
                                        <Text letterSpacing={1} w={{ base: "30%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Nom')}  {' '}
                                        </Text>
                                        <Input backgroundColor={useColorModeValue('#fdfdfd', 'none')} onChange={(e) => setLastName(e.target.value)} size={'md'} w={{ base: "70%", sm: "60%", lg: "70%" }} placeholder={t('Nom')} defaultValue={partenaire.last_name} type="text" name='last_name' id="last_name" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>
                                    <Flex align='center' w={{ base: '100%', md: "50%" }}>
                                        <Text letterSpacing={1} w={{ base: "30%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Prénom')}  {' '}
                                        </Text>
                                        <Input backgroundColor={useColorModeValue('#fdfdfd', 'none')} onChange={(e) => setFirstName(e.target.value)} size={'md'} w={{ base: "70%", sm: "60%", lg: "70%" }} placeholder={t('Prénom')} defaultValue={partenaire.first_name} type="text" name='first_name' id="first_name" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>

                                </Flex>
                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        Email {' '}
                                    </Text>
                                    <Input backgroundColor={useColorModeValue('#fdfdfd', 'none')} onChange={(e) => setEmail(e.target.value)} size={'md'} w={{ base: "70%", sm: "60%", lg: "70%" }} placeholder='email' defaultValue={partenaire.email} type="text" name='email' id="email"
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderRadius: "0",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                </Flex>

                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        {t('Telephone')} {' '}
                                    </Text>
                                    <Input backgroundColor={useColorModeValue('#fdfdfd', 'none')} onChange={(e) => setTel(e.target.value)} size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} placeholder={t('Numéro de telephone')} defaultValue={partenaire.tel} type="tel" name='tel' id="tel" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>

                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "50%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        {t('Date de naissance')} {' '}
                                    </Text>
                                    <Input backgroundColor={useColorModeValue('#fdfdfd', 'none')} onChange={(e) => setBirthday(e.target.value)} size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} defaultValue={partenaire.birthday} type="date" name='birthday' id="birthday" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>


                                <CardHeader p='5px 5px' mb='2px'>
                                    <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                        {t('Entreprise')}
                                    </Text>
                                </CardHeader>
                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        {t("Nom entreprise")} {' '}
                                    </Text>
                                    <Input defaultValue={partenaire.company_name} backgroundColor={useColorModeValue('#fdfdfd', 'none')} size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} onChange={(e) => setCompanyName(e.target.value)} name="name" type="text" placeholder={t("Tapez le nom d'entreprise")} maxLength={50} className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>
                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        Description {' '}
                                    </Text>
                                    <Input defaultValue={partenaire.company_description} backgroundColor={useColorModeValue('#fdfdfd', 'none')} size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} className="form-control-sm h-50" onChange={(e) => setCompanyDesc(e.target.value)} name="descirption" type="text" placeholder="Tapez la description" maxLength={250} style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>

                                <HStack spacing={2} mb='15px'>

                                    <Box w="50%">
                                        <FormControl id="pays" >
                                            <Text mb={2} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} ml={0.5}>{t("Pays")}</Text>

                                            <MySelect
                                                value={selectedOptions}
                                                onChange={handlePaysSelectChange}
                                                options={paysOptions}
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        backgroundColor: 'transparent',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        cursor: "pointer"
                                                    }),

                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: txtcolor,
                                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: colorbghover,
                                                        },
                                                        cursor: "pointer"
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: txtcolor
                                                    }),
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box w="50%">
                                        <FormControl id="ville">
                                            <Text mb={2} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} ml={0.5}>{t("Ville")}</Text>
                                            {/* <Input value={ville} onChange={(e) => setVille(e.target.value)} name="ville" type="text" placeholder={t('Tapez la ville')} maxLength={30} /> */}
                                            <MySelect
                                                value={selectedCityOptions}
                                                onChange={handleCitySelectChange}
                                                options={cityOptions}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti

                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        backgroundColor: 'transparent',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        cursor: "pointer"
                                                    }),
                                                    multiValueLabel: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        overflow: 'visible',
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                        cursor: "pointer"
                                                    }),
                                                    multiValue: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                        cursor: "pointer"
                                                    }),

                                                    multiValueRemove: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: txtcolor,
                                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: colorbghover,
                                                        },
                                                        cursor: "pointer"
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: txtcolor
                                                    }),
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                </HStack>
                                <Flex flexDirection={'column'} w={"100%"}>
                                <CardHeader p='5px 5px' mb='2px'>
                                    <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                        {t('Activités')}
                                    </Text>
                                </CardHeader>
                                    {getCategories()}


                                </Flex>

                                <Stack spacing={2} direction={['column', 'row']} justifyContent="end" >
                                    <Button
                                        size={'sm'}
                                        colorScheme="red"
                                        onClick={() => navigate(-1)}
                                    >
                                        {t("Annuler")}
                                    </Button>
                                    <Button
                                        size={'sm'}
                                        type='submit'

                                        onClick={handleSubmit}
                                        colorScheme="gray">
                                        {t("Enregistrer")}
                                    </Button>

                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
        </Box>

    )
}

export default EditPartenaire