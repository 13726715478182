import { Alert, AlertIcon, Avatar, AvatarBadge, Box, Button, Card, CardBody, CardHeader, Flex, Grid, Icon, Input, InputGroup, InputRightElement, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdSettings } from 'react-icons/md'
import { FaPencilAlt } from 'react-icons/fa'
import { IoMdMale, IoMdFemale } from 'react-icons/io'
import { useStateContext } from '../../context/ContextProvider'
import axiosClient from '../../axios-client';
import { useLocation, useNavigate } from 'react-router-dom'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
// import countryData from '../../assets/dataJson/countries.json';
import { useTranslation } from 'react-i18next'
import Resizer from "react-image-file-resizer";

function EditAdmin() {
    const { user } = useStateContext();
    const navigate = useNavigate()
    const [selectedImage, setSelectedImage] = useState()
    const [selectedImg, setSelectedImg] = useState()

    const [image, setImage] = useState()
    const [listAdmins, setListAdmins] = useState([])
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;
    const codepRegex = /^(\d{3})$|(\d{6})$|([A-Z]\d{4}[A-Z]{3})$|(\d{4})$|(\d{4})$|(?:FI)*(\d{5})$|(?:AZ)*(\d{4})$|(\d{5})$|(?:BB)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{3}\d?)$|([A-Z]{2}\d{2})$|([A-Z]{2}\d{4})$|(\d{8})$|(\d{6})$|([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$|(\d{4})$|(\d{7})$|(\d{6})$|(\d{4})$|(?:CP)*(\d{5})$|(\d{4})$|(\d{4})$|(\d{4})$|(\d{5})$|(\d{5})$|(?:FI)*(\d{5})$|(\d{5})$|(\d{4})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|(\d{10})$|(\d{3})$|(\d{5})$|(\d{5})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{7})$|([A-Z]\d{2}[A-Z]{2})|([A-Z]\d{3}[A-Z]{2})|([A-Z]{2}\d{2}[A-Z]{2})|([A-Z]{2}\d{3}[A-Z]{2})|([A-Z]\d[A-Z]\d[A-Z]{2})|([A-Z]{2}\d[A-Z]\d[A-Z]{2})|(GIR0AA)$|(\d{5})$|(\d{4}(\d{4})?)$|(\d{4})$|(\d{5})$|(\d{6})$|(\d{5})$|(\d{6})$|(?:SEOUL)*(\d{6})$|(\d{5})$|(\d{5})$|(\d{5})$|(\d{6})$|(\d{4})$|(\d{7})$|(97500)$|(\d{9})$|(\d{7})$|(96940)$|(\d{4})$|((97|98)(4|7|8)\d{2})$|(\d{6})$|(\d{6})$|(\d{6})$|(\d{5})$|(\d{5})$|(?:SE)*(\d{5})$|(\d{6})$|(STHL1ZZ)$|(?:SI)*(\d{4})$|(\d{5})$|4789\d$|(\d{5})$|(?:CP)*(\d{4})$|([A-Z]\d{3})$|(TKCA 1ZZ)$|(\d{5})$|(\d{6})$|(\d{6})$|(\d{4})$|(\d{5})$|(\d{5})$|(986\d{2})$|(\d{5})$|(\d{4})$|(\d{5})$|(\d{5})$|([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2})$/i;
    const { t } = useTranslation();

    //toast variable
    const toast = useToast()
    //password variables
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [messageE, setMessageE] = useState('');
    const [messageP, setMessageP] = useState('');

    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswords, setShowPasswords] = useState(false);

    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordColor, setPasswordColor] = useState('');
    const [admin, setAdmin] = useState([])
    const [selectedCountry, setSelectedCountry] = useState({});
    const [countries, setCountries] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [ville, setVille] = useState('');
    const [codeP, setCodeP] = useState('');
    const [email, setEmail] = useState('');
    const [rue, setRue] = useState('');
    const [pays, setPays] = useState('');
    const [tel, setTel] = useState('');
    const [birthday, setBirthday] = useState('');

    const location = useLocation()

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    const handleChangeImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedImage(imageFile);
        } catch (err) {
            // console.log(err);
        }
    }
    useEffect(() => {
        if (selectedImg) {
            setImage(URL.createObjectURL(selectedImg))
        }
        else {
            setSelectedImg()
        }
        axiosClient.get(`/admins/${location.state.idAdmin}/`)
            .then(({ data }) => {
                setAdmin(data)
            })

        axiosClient
            .get("/admins/")
            .then((res) => setListAdmins(res.data));


    }, [selectedImage]);

    // useEffect(() => {
    //     const Mycountries = [];
    //     const data = countryData;
    //     for (let index = 0; index < data.countries.length; index++) {
    //         Mycountries.push({
    //             value: data.countries[index].value,
    //             label: data.countries[index].label.substring(5, 50)
    //         });
    //     }
    //     setCountries(Mycountries);
    // }, []);

    // function pays(p) {
    //     let t
    //     t = {
    //         value: 1,
    //         label: p
    //     }

    //     return t
    // }
    const isEmpty = () => {
        return !lastName.trim() || !firstName.trim();
    };
    ////////////////////////// VALIDATION DE MOT DE PASSE /////////////////////////////////////////


    const checkPasswordStrength = () => {
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const startLength = /^.{2,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let missingRequirements = [];

        if (!specialchar.test(newPassword)) {
            missingRequirements.push(t("caractère spécial"));
        } else if (!lowercaseRegex.test(newPassword)) {
            missingRequirements.push(t("minuscule"));
        } else if (!uppercaseRegex.test(newPassword)) {
            missingRequirements.push(t("majuscule"));
        } else if (!digitRegex.test(newPassword)) {
            missingRequirements.push(t("chiffres"));
        } else if (!minLengthRegex.test(newPassword)) {
            missingRequirements.push(t("longueur minimale de 8 caractères"));
        }

        if (missingRequirements.length > 0) {
            const missingText = `${t("Vous avez besoin de")} ${missingRequirements.join(", ")} ${t("dans votre mot de passe.")}`;
            setPasswordStrength(missingText);
            setPasswordColor('red.500');
        } else {
            setPasswordStrength(t('Le mot de passe est correct!'));
            setPasswordColor('green');
        }
    }

    useEffect(() => {
        checkPasswordStrength();
    }, [newPassword]);


    // check the password complexity
    const isPasswordValid = (password) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!*_|èàç()/."';:,?ù])[0-9a-zA-Z@#$%^&+=!*_|èàç()/."';:,?ù]{8,}$/;
        const specialchar = /[@#$%^&+=!*_|èàç()/."';:,?ù]/;
        const minLengthRegex = /^.{8,}$/;
        const digitRegex = /\d/;
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;

        let errors = [];

        if (!minLengthRegex.test(password)) {
            errors.push(t('Le mot de passe doit comporter au moins 8 caractères.'));
        }

        if (!digitRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un chiffre.'));
        }

        if (!lowercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre minuscule.'));
        }

        if (!uppercaseRegex.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins une lettre majuscule.'));
        }
        if (!specialchar.test(password)) {
            errors.push(t('Le mot de passe doit contenir au moins un caractère spécial (@#$%^&+=).'));
        }
        if (password.length > 20) {
            errors.push(t('Le mot de passe ne doit pas dépasser 20 caractères.'));
        }

        if (errors.length > 0) {
            setMessage(errors[0]);
            return false;
        }

        return passwordRegex.test(password);
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!isPasswordValid(newPassword)) {
            return;
        }
        try {
            const response = await axiosClient.put(`/change_password/${admin.id}/`, {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
                useradd: user.first_name + ' ' + user.last_name
            });
            if (response.data.success) {
                toast({
                    description: t("le mot de passe est modifié avec succès"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant:'left-accent',
                    position: 'bottom-right'
                });
                setConfirmPassword('')
                setNewPassword('')
                setOldPassword('')

            }
            setMessage(response.data.error);
        } catch (err) {
            setError(err.response.data.error);
        }
    };

    const EditEmail = () => {
        // event.preventDefault();

       
        const formData = new FormData();
        if (email) { formData.append("email", email); }

        const newList = listAdmins.filter((e) => e.id !== location.state.idAdmin);

        if (newList.length > 0) {
            const check = newList.find((obj) => {
                return obj.email === email.trim();
            });
            if (check) {
                window.scrollTo(0, 0);
                return setMessageE(t("Ce mail existe déjà !"));
            }
        }
        if (!emailRegex.test(email)) {
            return setMessageE(t("Veuillez entrer un email valide."));

        }
        try {
            axiosClient.put(`/update_user_email/${location.state.idAdmin}/`, formData).then(()=>{
                toast({
                    description: t("L'email d'utilisateur est modifiée avec succèss"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'

                });
                setMessageE('')
                navigate('/admins')
            })
            .catch((err)=>{
                
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
            })
            
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
        }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        // if (user.code_postal && !codepRegex.test(user.code_postal)) {
        //     setMessage("Veuillez entrer un code postal valide.");
        //     return;
        // }
        const formData = new FormData();
        if (firstName) { formData.append("first_name", firstName); }
        if (lastName) { formData.append("last_name", lastName); }
        if (pays) { formData.append("pays", pays); }
        if (ville) { formData.append("ville", ville); }
        if (rue) { formData.append("rue", rue); }
        if (birthday) { formData.append("birthday", birthday); }
        if (tel) { formData.append("tel", tel); }
        if (codeP) { formData.append("code_postal", codeP); }
        if (selectedImage) formData.append("image", selectedImage);


        // if (firstName.trim() === "" || lastName.trim() === "" || (ville && ville.trim() === "") || (rue && rue.trim() === "") || (codeP && codeP.trim() === "")) {
        //     setMessage("Veuillez remplir les champs correctement");
        //     return;
        // }



        try {
            const response = await axiosClient.put(`/update_user/${admin.id}/`, formData)
            if (response.data.message) {
                toast({
                    description: t("les informations sont modifiées avec succès"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'

                });
                setMessageP('')
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
        }

    };

    return (
        <Box>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }}>
                <Flex
                    mb={{ base: "20px", sm: '24px', md: '50px', xl: '10px' }}
                    borderRadius='15px'
                    px='0px'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    {/* Header */}
                    <Flex

                        direction={{ base: "column", sm: 'column', md: 'row' }}
                        mx='auto'
                        maxH='330px'
                        w={{ sm: '90%', xl: '100%' }}
                        justifyContent={{ sm: 'center', md: 'space-between' }}
                        align='center'
                        p='24px'
                        borderRadius='20px'
                        mt='10px'
                    >
                        <Flex
                            align='center'
                            mb={{ sm: '10px', md: '10px' }}
                            direction={{ sm: 'column', md: 'row' }}
                            w={{ sm: '100%' }}
                            textAlign={{ sm: 'center', md: 'start' }}>
                            <Avatar me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={image ? image : admin.image ? admin.image : admin.image} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>
                                <label htmlFor="imageInput">
                                    <input
                                        id="imageInput"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleChangeImage}
                                    />
                                    <AvatarBadge
                                        cursor='pointer'
                                        borderRadius='8px'
                                        border='transparent'
                                        bg={useColorModeValue('linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 100%)' , 'linear-gradient(138.78deg, rgba(100,107,143,0.94) 17.44%, rgba(35,41,73,0.49) 93.55%, rgba(41,48,88,0.69) 100%)')}
                                        boxSize='26px'
                                        backdropFilter='blur(120px)'>
                                        <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                    </AvatarBadge>
                                </label>
                            </Avatar>
                            <Flex direction='column' maxWidth='100%' my={{ sm: '14px' }}>
                                <Flex direction='row' align={'center'}>
                                    <Text
                                        fontSize={{ sm: 'lg', lg: 'xl' }}
                                        color={useColorModeValue("gray.700", "white")}
                                        fontWeight='bold'
                                        ms={{ sm: '8px', md: '0px' }}
                                        mr={2}
                                    >
                                        {admin.first_name} {admin.last_name}
                                    </Text>
                                    {admin.gender === "Femme" ? <IoMdFemale size={"22px"} color='#ff8096' /> : <IoMdMale size={"22px"} color='#5da4ff' />}
                                </Flex>

                                <Text fontSize={{ sm: 'sm', md: 'md' }} color={useColorModeValue('gray.400', 'gray.300')}>
                                    {admin.email}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex mt={{ base: 4, md: 0 }} direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }}>

                            <Button
                                borderRadius='12px'
                                bg={useColorModeValue('gray.200', 'gray.700')}
                                me={{ base: 'none', lg: '20px' }}
                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={MdSettings} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                    {t('PARAMÈTRE')}
                                </Text>
                            </Button>
                            {/* <Button
                                borderRadius='12px'
                                bg='transparent'

                                leftIcon={<Icon color={useColorModeValue("gray.700", "white")} as={FaCube} me='6px' />}>
                                <Text fontSize='sm' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                    ELSE
                                </Text>
                            </Button> */}
                        </Flex>
                    </Flex>
                </Flex>
                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "2fr 2fr " }} px={5} gap='40px' >

                    <Card
                        bg={useColorModeValue("gray.200", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t('Informations personnelles')}
                            </Text>
                        </CardHeader>
                        {messageP &&
                            <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                <AlertIcon />
                                {messageP}
                            </Alert>
                        }
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                <Flex align='left' mb='14px' direction={{ base: 'column', md: "row" }}  >
                                    <Flex align='center' w={{ base: '100%', md: "50%" }} mr={10}>
                                        <Text letterSpacing={1} w={{ base: "30%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Nom')}  {' '}
                                        </Text>
                                        <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')} onChange={(e) => setLastName(e.target.value)} size={'md'} w={{ base: "70%", sm: "60%", lg: "70%" }} placeholder={t('Nom')} defaultValue={admin.last_name} type="text" name='last_name' id="last_name" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>
                                    <Flex align='center' w={{ base: '100%', md: "50%" }}>
                                        <Text letterSpacing={1} w={{ base: "30%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Prénom')}  {' '}
                                        </Text>
                                        <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setFirstName(e.target.value)} size={'md'} w={{ base: "70%", sm: "60%", lg: "70%" }} placeholder={t('Prénom')} defaultValue={admin.first_name} type="text" name='first_name' id="first_name" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>

                                </Flex>
                                {/* <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        Nom complet {' '}
                                    </Text>
                                    <Input size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} placeholder='Numéro de telephone' defaultValue={user.tel} type="tel" name='tel' id="tel" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderRadius: "0",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex> */}

                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "40%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        {t('Telephone')} {' '}
                                    </Text>
                                    <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setTel(e.target.value)} size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} placeholder={t('Numéro de telephone')} defaultValue={admin.tel} type="tel" name='tel' id="tel" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>

                                <Flex align='center' mb='14px'>
                                    <Text letterSpacing={1} w={{ base: "50%", sm: "40%", lg: "30%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                        {t('Date de naissance')} {' '}
                                    </Text>
                                    <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setBirthday(e.target.value)} size={'md'} w={{ base: "60%", sm: "60%", lg: "70%" }} defaultValue={admin.birthday} type="date" name='birthday' id="birthday" className="form-control-sm h-50" style={{
                                        borderTop: "none",
                                        borderRight: "none",
                                        borderLeft: "none",
                                        borderBottom: "1px solid lightgray",
                                        outline: "none",
                                        borderRadius: 5
                                    }} />
                                </Flex>
                                <CardHeader p='5px 5px' mb='2px'>
                                    <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                        {t('Adresse personnelle')}
                                    </Text>
                                </CardHeader>
                                <Flex align='left' mb='14px' direction={{ base: 'column', md: "row" }}  >
                                    <Flex align='center' w={{ base: '100%', md: "50%" }} mr={10}>
                                        <Text letterSpacing={1} w={{ base: "100%", sm: "40%", lg: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Ville')}  {' '}
                                        </Text>
                                        <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setVille(e.target.value)} size={'md'} w={{ base: "70%", sm: "60%", lg: "60%" }} placeholder={t('ville')} defaultValue={admin.ville} type="text" name='ville' id="ville" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>
                                    <Flex align='center' w={{ base: '100%', md: "50%" }}>
                                        <Text letterSpacing={1} w={{ base: "100%", sm: "40%", lg: "50%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Rue')}  {' '}
                                        </Text>
                                        <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setRue(e.target.value)} size={'md'} w={{ base: "100%", sm: "60%", lg: "50%" }} placeholder={t('rue')} defaultValue={admin.rue} type="text" name='rue' id="rue" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>

                                </Flex>
                                <Flex align='left' mb='14px' direction={{ base: 'column', md: "row" }}  >
                                    <Flex align='center' w={{ base: '100%', md: "50%" }} mr={10}>
                                        <Text letterSpacing={1} w={{ base: "100%", sm: "40%", lg: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Pays')}  {' '}
                                        </Text>
                                        <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setPays(e.target.value)} size={'md'} w={{ base: "100%", sm: "60%", lg: "60%" }} placeholder={t('pays')} defaultValue={admin.pays} type="text" name='pays' id="pays" className="form-control-sm h-50" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                        {/* <Select
                                         size={'md'} w={{ base: "100%", sm: "60%", lg: "60%" }}
                                                id="pays"
                                                name="pays"
                                                options={countries}
                                                value={selectedCountry.label ? selectedCountry : pays(user.pays)}
                                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                                                isSearchable
                                               
                                                style={{
                                                    borderTop: 
                                                    "none",
                                                    borderRight: "none",
                                                    borderLeft: "none",
                                                    borderRadius: "0",
                                                    borderBottom: "1px solid lightgray",
                                                    outline: "none",
                                                    borderRadius: 5
                                                }} 
                                            /> */}
                                    </Flex>
                                    <Flex align='center' w={{ base: '100%', md: "50%" }}>
                                        <Text letterSpacing={1} w={{ base: "100%", sm: "40%", lg: "50%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.500'} me='10px'>
                                            {t('Code postal')}  {' '}
                                        </Text>
                                        <Input  backgroundColor={useColorModeValue('#dae0e98c', 'none')}  onChange={(e) => setCodeP(e.target.value)} size={'md'} w={{ base: "100%", sm: "60%", lg: "50%" }} placeholder={t('code postal')} defaultValue={admin.code_postal} type="text" name='codeP' id="codeP" style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid lightgray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    </Flex>

                                </Flex>
                                <Stack spacing={2} direction={['column', 'row']} justifyContent="end" >
                                    <Button
                                        size={'sm'}
                                        colorScheme="red"
                                        onClick={() => navigate(-1)}
                                    >
                                        {t('Annuler')}
                                    </Button>
                                    <Button
                                        size={'sm'}
                                        type='submit'

                                        onClick={handleSubmit}
                                        colorScheme="gray">
                                        {t('Enregistrer')}
                                    </Button>

                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>
                    <Card

                        bg={useColorModeValue("gray.700", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='5px 5px' mb='2px'>
                            <Text fontSize='lg' color="white" fontWeight='bold'>
                                {t('Sécurité de compte')}
                            </Text>
                        </CardHeader>
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {/* <Separator mb='30px' /> */}
                                {/* <Text fontSize='lg' color="white" fontWeight='500' mb={3}>
                                       Gestion Email
                                    </Text> */}
                                {messageE &&
                                    <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                        <AlertIcon />
                                        {messageE}
                                    </Alert>
                                }
                                <Flex align='center' justifyContent={'space-between'} mb='14px'>

                                    <Input onChange={(e) => setEmail(e.target.value)} size={'md'} w={{ base: "100%", sm: "60%", lg: "50%" }} placeholder='email' defaultValue={admin.email} type="text" name='codeP' id="codeP"
                                        color='gray.100'
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }} />
                                    {/* <Text fontSize='md' color="white" fontWeight='400'>
                                        {admin.email}
                                    </Text> */}
                                    <Button onClick={EditEmail} size={"sm"} colorScheme='whatsapp' variant='outline'>
                                        {t('Modifier')}
                                    </Button>
                                </Flex>
                                {/* <Divider pt={2} pb={2} /> */}
                                <Text fontSize='md' color="white" fontWeight='bold' mt={3} pb={6}>{t('Modification de mot de passe :')}</Text>
                                {message &&
                                    <Alert mb={2} fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                        <AlertIcon />
                                        {message}
                                    </Alert>
                                }
                                <Flex align='center' mb='14px' flexDirection={{ base: "column", md: "row" }}>
                                    <Text letterSpacing={1} mb={{ base: 2, md: 0 }} w={{ base: "100%", sm: "100%", md: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.300'} me='10px'>
                                        {t('Ancien mot de passe')} {' '}
                                    </Text>
                                    <InputGroup w={{ base: "100%", sm: "100%", md: "60%" }}>
                                        <Input

                                            type={showPasswords ? 'text' : 'password'}
                                            id="old_password"

                                            onChange={(e) => setOldPassword(e.target.value)}
                                            color='gray.100'
                                            style={{
                                                borderTop: "none",
                                                borderRight: "none",
                                                borderLeft: "none",
                                                borderBottom: "1px solid gray",
                                                outline: "none",
                                                borderRadius: 5
                                            }}
                                        />
                                        <InputRightElement h={'full'}>
                                            <Button
                                                color={"white"}
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPasswords((showPasswords) => !showPasswords)
                                                }>
                                                {showPasswords ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    {/* <Input
                                        w={{ base: "100%", sm: "100%", md: "60%" }}
                                        type={'password'}
                                        id="old_password"
                                        color='gray.100'
                                        // onChange={(e) => setOldPassword(e.target.value)}
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }}
                                    /> */}
                                </Flex>
                                <Flex align='center' mb='14px' flexDirection={{ base: "column", md: "row" }}>
                                    <Text letterSpacing={1} mb={{ base: 2, md: 0 }} w={{ base: "100%", sm: "100%", md: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.300'} me='10px'>
                                       {t('Nouveau mot de passe')} {' '}
                                    </Text>
                                    <InputGroup w={{ base: "100%", sm: "100%", md: "60%" }}>


                                        <Input

                                            type={showPassword ? 'text' : 'password'}
                                            id="new_password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            onCopy={(e) => e.preventDefault()}
                                            color='gray.100'
                                            style={{
                                                borderTop: "none",
                                                borderRight: "none",
                                                borderLeft: "none",
                                                borderBottom: "1px solid gray",
                                                outline: "none",
                                                borderRadius: 5
                                            }}
                                        />

                                        <InputRightElement h={'full'}>
                                            <Button
                                                color={"white"}
                                                variant={'ghost'}
                                                onClick={() =>
                                                    setShowPassword((showPassword) => !showPassword)
                                                }>
                                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>

                                    {/* <Input
                                         w={{ base: "100%", sm: "100%", md: "60%" }}
                                        type={'password'}
                                        id="new_password"
                                        // value={newPassword}
                                        // onChange={(e) => setNewPassword(e.target.value)}
                                        // onCopy={(e) => e.preventDefault()}
                                        color='gray.100'
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }}
                                    /> */}
                                </Flex>
                                {newPassword.length > 0 ?
                                    <Text align="left" justify="left" fontSize="sm" mt={2} color={passwordColor}>{`${passwordStrength}`}</Text>

                                    : ""}
                                <Flex align='center' mb='14px' flexDirection={{ base: "column", md: "row" }}>
                                    <Text letterSpacing={1} mb={{ base: 2, md: 0 }} w={{ base: "100%", sm: "100%", md: "40%" }} fontSize={{ sm: 'sm', md: 'md' }} color={'gray.300'} me='10px'>
                                        {t('Confirmer mot de passe')} {'  '}
                                    </Text>

                                    <Input
                                        w={{ base: "100%", sm: "100%", md: "60%" }}
                                        type="password"
                                        id="confirm_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        color='gray.100'
                                        style={{
                                            borderTop: "none",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderBottom: "1px solid gray",
                                            outline: "none",
                                            borderRadius: 5
                                        }}
                                    />
                                </Flex>
                                <Stack spacing={2} direction={['column', 'row']} justifyContent="end" >
                                    <Button
                                        size={'sm'}
                                        colorScheme="red"
                                        onClick={() => navigate(-1)}
                                    >
                                        {t('Annuler')}
                                    </Button>
                                    <Button
                                        size={'sm'}
                                        type='submit'

                                        onClick={handleFormSubmit}
                                        colorScheme="gray">
                                        {t('Enregistrer')}
                                    </Button>

                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
        </Box>

    )
}

export default EditAdmin