import logo from "../assets/images/logo.png";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useStateContext } from "../context/ContextProvider";
import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../axios-client";
import { FaChevronCircleLeft, FaUser, FaUserFriends, FaUserTie } from "react-icons/fa";
import { BsCake2, BsFillInfoCircleFill, BsHousesFill, BsPersonVcardFill } from "react-icons/bs";
import { FaUserEdit } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";
import LanguageChanger from "../components/language/LanguageChanger";
import { useTranslation } from "react-i18next";


import { BiCategory, BiLogOut, BiMessageSquareDetail, BiSolidUser } from "react-icons/bi";

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  Image,
  HStack,
  Center,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
  Badge,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
  AlertDialogBody,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import {
  FiHome,
  FiMenu,
  FiMoon,
  FiSun,
  FiChevronDown,

} from "react-icons/fi";
import { MdEmail, MdEventNote, MdNotificationsActive, MdOutlineMarkEmailRead, MdOutlineZoomInMap } from "react-icons/md";
import { SettingsIcon } from "@chakra-ui/icons";
import { GoAlertFill } from "react-icons/go";
import { IoIosArrowBack, IoMdChatboxes } from "react-icons/io";

export default function DefaultLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSiderBar, setIsSideBar] = useState("block");
  const [changedWidth, setChangedWidth] = useState("60");
  const { user } = useStateContext();

  const handleCloseSideBar = () => {
    if (isSiderBar === "block") {
      setIsSideBar("none");
      setChangedWidth("full");
    } else {
      setIsSideBar("block");
      setChangedWidth("60");
    }
  };
  const location = useLocation();

  // Define a list of paths where padding should be removed.
  const pathsWithNoPadding = ['/ajouter-evenement', '/modifier-evenement', '/partenaires-signales', '/birthdays'];
  // const pathsWithNoPadding2 = ['/modifier-evenement'];
  // const pathsWithNoPadding3 = ['/partenaires-signalés'];


  const paddingValue = pathsWithNoPadding.includes(location.pathname) ? '0' : '4';
  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      {(user.role === "RESPOEVENTS" || user.role === "AGENTSUPPORT") ?
        null : <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: isSiderBar }}
        />}

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}

      {(user.role === "RESPOEVENTS" || user.role === "AGENTSUPPORT") ? <MobileNavEvents /> :
        <MobileNav
          onOpen={onOpen}
          handleCloseSideBar={handleCloseSideBar}
          isSiderBar={isSiderBar}
        />
      }
      {(user.role === "RESPOEVENTS" || user.role === "AGENTSUPPORT")
        ?
        <Box ml={{ base: 0, md: '0' }} p={paddingValue}>

          <Outlet />
        </Box>
        :
        <Box ml={{ base: 0, md: changedWidth }} p={paddingValue}>

          <Outlet />
        </Box>
      }

    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { user } = useStateContext();
  const userRole = user.role;
  const { t } = useTranslation();

  // current user data


  const LinkItems = {
    SUPERADMIN: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Admins"), icon: FaUserTie, path: "/admins" },
      { name: t("Gestion Clients"), icon: FaUser, path: "/clients" },
      { name: t("Gestion Partenaires"), icon: FaUserFriends, path: "/partenaires" },
      { name: t("Réseau de Partenaires"), icon: FaMapLocationDot, path: "/localisations" },
      { name: t("Discussions"), icon: IoMdChatboxes, path: "/rooms" },

      { name: t("Partenaires Signalés"), icon: GoAlertFill, path: "/partenaires-signales" },
      { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: t("Gestion Catégories"), icon: BiCategory, path: "/categories" },
      { name: "Apa agency services", icon: BsHousesFill, path: "/apa-agency-services" },
      { name: "Apa promotions", icon: SettingsIcon, path: "/apa-promotions" },
      { name: t("Types d'abonnements"), icon: HiCursorClick, path: "/types_abonnements" },
      { name: t("Espace Événements"), icon: MdEventNote, path: "/evenements" },
      { name: "Notifications", icon: MdNotificationsActive, path: "/notifications" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      { name: t("A propos"), icon: BsFillInfoCircleFill, path: "/aPropos" },
      { name: t("Paramètres"), icon: SettingsIcon, path: "/contacts" },



    ],
    ADMIN: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Admins"), icon: FaUserTie, path: "/admins" },
      { name: t("Gestion Clients"), icon: FaUser, path: "/clients" },
      { name: t("Gestion Partenaires"), icon: FaUserFriends, path: "/partenaires" },
      { name: t("Réseau de Partenaires"), icon: FaMapLocationDot, path: "/localisations" },
      { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: t("Gestion Catégories"), icon: BiCategory, path: "/categories" },
      { name: "Apa agency services", icon: BsHousesFill, path: "/apa-agency-services" },
      { name: "Apa promotions", icon: SettingsIcon, path: "/apa-promotions" },
      { name: t("Types d'abonnements"), icon: HiCursorClick, path: "/types_abonnements" },
      { name: t("Espace Événements"), icon: MdEventNote, path: "/evenements" },
      { name: "Notifications", icon: MdNotificationsActive, path: "/notifications" },
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
      { name: t("A propos"), icon: BsFillInfoCircleFill, path: "/aPropos" },
      { name: t("Paramètres"), icon: SettingsIcon, path: "/contacts" },

    ],
    RESPO: [
      { name: t("Tableau de bord"), icon: FiHome, path: "/" },
      { name: t("Gestion Clients"), icon: FaUser, path: "/clients" },
      { name: t("Gestion Partenaires"), icon: FaUserFriends, path: "/partenaires" },
      { name: t("Réseau de Partenaires"), icon: FaMapLocationDot, path: "/localisations" },
      { name: t("Demandes d'inscription"), icon: BsPersonVcardFill, path: "/demandes-inscription" },
      { name: t("Gestion Catégories"), icon: BiCategory, path: "/categories" },
      { name: t("Types d'abonnements"), icon: HiCursorClick, path: "/types_abonnements" },
      { name: t("A propos"), icon: BsFillInfoCircleFill, path: "/aPropos" },

    ],
    RESPOEVENTS: [
      { name: t("Espace Événements"), icon: MdEventNote, path: "/evenements" },
    ],
    AGENTSUPPORT: [
      { name: "Tickets", icon: BiMessageSquareDetail, path: "/tickets" },
    ],

  };
  const items = LinkItems[userRole] || [];

  return (
    <Box
      transition="3s ease"
      color="white"
      bgGradient={useColorModeValue("linear(to-b, black, gray.700)", "linear(to-t, black, black)")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      // overflowY={"scroll"}
      h="full"
      pb="20px"
      // css={{
      //   /* Hide scrollbar */
      //   "&::-webkit-scrollbar": {
      //     display: "none",
      //   },
      //   /* Optional: Firefox scrollbar */
      //   scrollbarWidth: "none",
      //   msOverflowStyle: "none",
      // }}

      {...rest}

    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="center">
        <Image src={logo} width="100px" mt="10px"></Image>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} position={"absolute"} top={3} right={3} />

      </Flex>
      <Box overflowY={"scroll"} h={'90%'}
        css={{
          /* Hide scrollbar */
          "&::-webkit-scrollbar": {
            display: "none",
          },
          /* Optional: Firefox scrollbar */
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {items.map((link) => (
          <NavItem
            items={items}
            key={link.name}
            icon={link.icon}
            onClose={onClose}
          >
            {link.name}
          </NavItem>
        ))}
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, items, onClose, ...rest }) => {
  const navigate = useNavigate();
  const { setUser } = useStateContext();

  const location = useLocation();
  const pathLink = items.find((e) => e.name === children);
  const { countTickets } = useStateContext()
  // current user data
  // useEffect(() => {
  //   axiosClient.get("auth/user/").then(({ data }) => {
  //     setUser(data);
  //   });
  //   axiosClient.get('/tickets/getTotalTicketsCount/') // Assuming this endpoint retrieves user data
  //     .then((res) => {
  //       setCount(res.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [count,setCount]);


  // useEffect(() => {
  //   const socket = new WebSocket('ws://localhost:8000/ws/tickets/');

  //   socket.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     setCount(data.count);
  //   };

  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  const handleClick = () => {
    navigate(pathLink.path);
    onClose();
  };


  return (

    <Box
      onClick={handleClick}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        m="2"
        p="4"
        mx="4"
        bg={pathLink.path === location.pathname ? "gray.500" : ""}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#49b1cc",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="18"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children === "Tickets" ? (
          <>
            <Box flex={"1"}>
              <Text fontSize="md">{children}</Text>
            </Box>
            {countTickets > 0 && (
              <Badge
                rounded="full"
                bg="#df2e38"
                style={{ padding: "2px 7px 2px 6px" }}
                color="white"
                ml="2"
              >
                {countTickets}
              </Badge>
            )}
          </>
        ) : (
          <Text fontSize="sm">{children}</Text>
        )}

      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, handleCloseSideBar, isSiderBar, ...rest }) => {
  const { user, setToken, setUser, setRefresh, token } = useStateContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const { t } = useTranslation()
  const cancelRef = useRef()

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onOpenAlert = () => {
    setIsAlertOpen(true);
  };
  const onCloseAlert = () => {
    setIsAlertOpen(false);
  };
  // Logout Function
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [isLoadingAll, setIsLoadingAll] = useState();

  const [birthdayCount,setBirthdayCount]=useState()
  const onLogout = (ev) => {
    setIsLoading(true)
    ev.preventDefault();
    // if(user?.tokens.length === 0){
    //   setUser({});
    //   setToken(null);
    //   localStorage.removeItem("tokenExpirationTime");

    //   localStorage.removeItem("refreshTimeAdmin");
    //   localStorage.removeItem("role");
    //   setIsLoading(false)
    // }else{
    axiosClient
      .put(`delete_token/${user.id}/`, { token: token })
      .then(({ data }) => {
        setUser({});
        setToken(null);
        localStorage.removeItem("tokenExpirationTime");

        localStorage.removeItem("refreshTimeAdmin");
        localStorage.removeItem("role");
        localStorage.removeItem("loggedin");
        setIsLoading(false)
        navigate("/login")
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false)
      });
    // }
    // setUser({});
    //     setToken(null);
    //     localStorage.removeItem("tokenExpirationTime");

    //     localStorage.removeItem("refreshTimeAdmin");
    //     localStorage.removeItem("role");


    // axiosClient.post('/logout/', null, {
    //   headers: {
    //     'Authorization': `Bearer ${localStorage.getItem('U_T')}`,
    //     'Content-Type': 'application/json'
    //   }
    // })
    //   .then(response => {
    //     if (response.status === 200) {
    //       console.log('okk');
    //       setUser({});
    //       setToken(null);
    //       localStorage.removeItem("tokenExpirationTime");

    //       localStorage.removeItem("refreshTimeAdmin");
    //       localStorage.removeItem("role");

    //     } else {
    //       console.error('Failed to logout');
    //     }
    //   })
    //   .catch(error => {
    //     console.error('Error:', error);
    //   });

  };
  const onAllLogout = (ev) => {
    setIsLoadingAll(true)
    ev.preventDefault();
    // setUser({});
    // setToken(null);
    // localStorage.removeItem("tokenExpirationTime");

    // localStorage.removeItem("refreshTimeAdmin");
    // localStorage.removeItem("role");
    axiosClient
      .put(`delete_all_tokens/${user.id}/`)
      .then(({ data }) => {
        setUser({});
        setToken(null);
        localStorage.removeItem("tokenExpirationTime");

        localStorage.removeItem("refreshTimeAdmin");
        localStorage.removeItem("role");
        localStorage.removeItem("loggedin");
        setIsLoadingAll(false)
        navigate("/login")
      })
      .catch((err) => {
        // console.log(err);
        setIsLoadingAll(false)
      });

  };
  useEffect(() => {
    axiosClient.get(`/clients/get_birthdays/?type=today&page_size=1&page=1`)
            .then((response) => {
               setBirthdayCount(response.data.count)
            })
            .catch((error) => {
                // console.error(error);
            });
}, [birthdayCount]);

  const flexContent = (
    <>
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        style={{
          color: "white",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
        icon={<FiMenu />}
      />
      {isSiderBar === "block" ? (
        <>
          <Text
            mt={2}
            ml={2}
            display={{ base: 'none', md: "block" }}
            fontWeight={"bold"}
            color={"white"}
            fontSize={{ base: "md", sm: "md", md: "xl", lg: "xl", xl: '2xl' }}
          >
            {t("APA Premium Administrative")}
          </Text>
        </>
      ) : (
        <>
          <Text
            display={{ base: 'none', md: "block" }}
            color={"white"}
            ml={10}
            fontWeight={"bold"}
            fontSize={{ base: "md", sm: "md", md: "xl", lg: "xl", xl: '2xl' }}
          >
            {t("APA Premium Administrative")}
          </Text>
        </>
      )}
      <HStack spacing={{ base: "0", md: "3" }}>
        {/* <IconButton
          onClick={()=>navigate("/birthdays")}
          size="sm"
          variant=""
          _hover={{
            bg: "#49b1cc",
            color: "white",
          }}
          icon={<BsCake2 size={"18px"}/>}
        /> */}
          <IconButton
            onClick={() => navigate("/emails")}
            size="sm"
            variant=""
            _hover={{
              bg: "#49b1cc",
              color: "white",
            }}
            icon={<MdOutlineMarkEmailRead size={"18px"} />}
          />
          
        <Box position="relative" display="inline-block">
          <IconButton
            onClick={() => navigate("/birthdays")}
            size="sm"
            variant=""
            _hover={{
              bg: "#49b1cc",
              color: "white",
            }}
            icon={<BsCake2 size={"18px"} />}
          />
          {birthdayCount > 0 && (
            <Badge
              position="absolute"
              top="-1.5"
              right="-1.5"
              bg="red.500"
              color="white"
              borderRadius="full"
              fontSize="0.7em"
              p="1"
              style={{ padding: "2px 7px 2px 6px" }}
            >
              {birthdayCount}
            </Badge>
          )}
        </Box>


        <LanguageChanger />
        <IconButton
          onClick={toggleColorMode}
          size="sm"
          variant=""
          _hover={{
            bg: "#49b1cc",
            color: "white",
          }}
          icon={colorMode === "light" ? <FiMoon size={"18px"} /> : <FiSun size={"18px"} />}
        />
        <Flex ml={{ base: "3", md: "5" }} alignItems={"center"} color="black">
          <Menu >
            <MenuButton
              py={4}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={user.image} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" color="white">
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text fontSize="xs" color="#49b1cc">
                    {user.role === "ADMIN"
                      ? t("administrateur")
                      : user.role === "SUPERADMIN"
                        ? t("super-administrateur")
                        : user.role === "RESPO"
                          ? t("responsable")
                          : ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }} color="white">
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              // minW='200px'
              zIndex={9999}
              borderColor="gray.600"
              alignItems={"center"}
              bg={useColorModeValue("gray.800", "black")}
              color="white"
              boxShadow={"3px 3px 50px #1a202c"}
            >
              <Center mt={3} mb={3}>
                <Avatar size={"xl"} src={user.image} />
              </Center>

              <MenuItem
                fontSize={'sm'}
                icon={<BiSolidUser size={15} />}
                _hover={{ bg: "blue.900", color: "white" }}
                bg={useColorModeValue("gray.800", "black")}
                onClick={() => navigate("/profil")}
              >
                {" "}
                {t("Profil")}
              </MenuItem>
              <MenuItem
                fontSize={'sm'}
                icon={<FaUserEdit size={15} />}
                _hover={{ bg: "blue.900", color: "white" }}
                bg={useColorModeValue("gray.800", "black")}
                onClick={() => navigate("/modifier-profil")}
              >
                {" "}
                {t("Mes paramètres")}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<BiLogOut size={20} />}
                _hover={{ bg: "blue.900", color: "white" }}
                bg={useColorModeValue("gray.800", "black")}
                onClick={() => {
                  // onLogout()
                  onOpenAlert()
                }}
              >
                {t("Déconnexion")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <AlertDialog
          isOpen={isAlertOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCloseAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              </AlertDialogHeader>

              <AlertDialogBody
              >
                <Flex gap={5} w={"100%"} align={'center'} justify={"space-between"}>
                  <Text textAlign={'justify'} w={"70%"}>
                    {t("Se déconnecter de")} <strong> {user.first_name} {user.last_name}</strong>
                  </Text>

                  <Button isLoading={isLoading} w={"30%"} size={"sm"} ref={cancelRef} onClick={onLogout} border={"1px solid"} borderColor={"gray.300"}>
                    <Text> {isLoading ? <Spinner size="sm" /> : t("Déconnexion")}</Text>
                  </Button>
                </Flex>

                <Divider borderColor={'gray'} my={5} />
                <Flex w={'100%'}>
                  <Button isLoading={isLoadingAll} mb={3} w={'100%'} onClick={onAllLogout} color={"red"} colorScheme='gray' border={"1px solid"} borderColor={"gray.300"}>
                    {isLoadingAll ? <Spinner size="sm" /> : t("Se déconnecter de tous les comptes")}
                  </Button>

                </Flex>

              </AlertDialogBody>


            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </HStack>
    </>
  );
  const b = useColorModeValue("linear(to-r, black, gray.700)", "linear(to-l, black, black)");
  const border = useColorModeValue("black", "gray.700");
  return (
    <Box>
      {isSiderBar === "block" ? (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={{ base: 0, md: 60, lg: 60 }}
          bgGradient={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      ) : (
        <Flex
          ml={{ base: 0, md: "full" }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          color="white"
          marginLeft={0}
          bgGradient={b}
          borderBottomWidth="1px"
          borderBottomColor={border}
          justifyContent={{ base: "space-between", md: "space-between" }}
          {...rest}
        >
          {flexContent}
        </Flex>
      )}
      {isSiderBar === "block" ? (
        <IconButton

          icon={<IoIosArrowBack />}
          onClick={handleCloseSideBar}
          display={{ base: "none", md: "block" }}
          variant=""
          style={{
            position: "absolute",
            left: "210px",
            top: "25px",
            color: "#49b1cc",
            fontSize: "25px",
            fontWeight: "bolder",
          }}
        />
      ) : (
        <IconButton
          onClick={handleCloseSideBar}
          icon={<FiMenu />}
          variant="outline"
          aria-label="open menu"
          style={{
            position: "absolute",
            left: "10px",
            top: "20px",
            color: "white",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        />
      )}
    </Box>
  );
};



const MobileNavEvents = ({ ...rest }) => {
  const { user, setToken, setUser } = useStateContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const { t } = useTranslation();

  // Logout Function
  const navigate = useNavigate();

  const onLogout = (ev) => {
    ev.preventDefault();

    setUser({});
    setToken(null);
    localStorage.removeItem("tokenExpirationTime");

    localStorage.removeItem("refreshTimeAdmin");
    localStorage.removeItem("role");
    // axiosClient.post('/logout/', null, {
    //   headers: {
    //     'Authorization': `Bearer ${localStorage.getItem('U_T')}`,
    //     'Content-Type': 'application/json'
    //   }
    // })
    //   .then(response => {
    //     if (response.status === 200) {
    //       console.log('okk');
    //       setUser({});
    //       setToken(null);
    //       localStorage.removeItem("tokenExpirationTime");

    //       localStorage.removeItem("refreshTimeAdmin");
    //       localStorage.removeItem("role");

    //     } else {
    //       console.error('Failed to logout');
    //     }
    //   })
    //   .catch(error => {
    //     console.error('Error:', error);
    //   });

  };

  const flexContent = (
    <>

      <Text
        color={"white"}
        ml={{ base: 0, md: 5 }}
        fontWeight={"bold"}
        fontSize={{ base: "md", sm: "md", md: "2xl", lg: "2xl" }}
      >
        {t("APA Premium Administrative")}
      </Text>
      <HStack spacing={{ base: "0", md: "6" }}>
        <LanguageChanger />

        <IconButton
          onClick={toggleColorMode}
          size="lg"
          variant=""
          _hover={{
            bg: "#49b1cc",
            color: "white",
          }}
          icon={colorMode === "light" ? <FiMoon /> : <FiSun />}
        />
        <Flex alignItems={"center"} color="black">
          <Menu >
            <MenuButton
              py={4}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={user.image} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm" color="white">
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text fontSize="xs" color="#49b1cc">
                    {user.role === "AGENTSUPPORT"
                      ? t("Agent de support client")
                      : user.role === "RESPOEVENTS"
                        ? t("Responsable des événements")
                        : ""}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }} color="white">
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              // minW='200px'
              zIndex={9999}
              borderColor="gray.600"
              alignItems={"center"}
              bg={useColorModeValue("gray.800", "black")}
              color="white"
              boxShadow={"3px 3px 50px #1a202c"}
            >
              <Center mt={3} mb={3}>
                <Avatar size={"xl"} src={user.image} />
              </Center>

              <MenuItem
                fontSize={'sm'}
                icon={<BiSolidUser size={15} />}
                _hover={{ bg: "blue.900", color: "white" }}
                bg={useColorModeValue("gray.800", "black")}
                onClick={() => navigate("/profil")}
              >
                {" "}
                {t("Profil")}
              </MenuItem>
              <MenuItem
                fontSize={'sm'}
                icon={<FaUserEdit size={15} />}
                _hover={{ bg: "blue.900", color: "white" }}
                bg={useColorModeValue("gray.800", "black")}
                onClick={() => navigate("/modifier-profil")}
              >
                {" "}
                {t("Mes paramètres")}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<BiLogOut size={20} />}
                _hover={{ bg: "blue.900", color: "white" }}
                bg={useColorModeValue("gray.800", "black")}
                onClick={onLogout}
              >
                {t("Déconnexion")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </>
  );
  const b = useColorModeValue("linear(to-r, black, gray.700)", "linear(to-l, black, black)");
  const border = useColorModeValue("black", "gray.700");
  return (
    <Box>

      <Flex
        ml={{ base: 0, md: "full" }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        color="white"
        marginLeft={0}
        bgGradient={b}
        borderBottomWidth="1px"
        borderBottomColor={border}
        justifyContent={{ base: "space-between", md: "space-between" }}
        {...rest}
      >
        {flexContent}
      </Flex>
    </Box>
  );
};