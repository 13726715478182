import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import { BiLeftArrowAlt } from 'react-icons/bi';
import MySelect from 'react-select';

import makeAnimated from 'react-select/animated';
import Resizer from "react-image-file-resizer";

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Avatar,
    Td,
    Input,
    FormControl,
    FormLabel,
    useToast,
    TableContainer,
    useColorModeValue,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
    Alert,
    AlertIcon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    Text,
    Tag,
    PopoverFooter,
    TagCloseButton,
    AvatarBadge,
    Icon,
    Textarea,
    TagLabel,
    SkeletonCircle,
    Skeleton

} from '@chakra-ui/react';
import { FaPencilAlt } from 'react-icons/fa';
import { PiPlus, PiPlusBold } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

export const GestCategory = () => {
    const animatedComponents = makeAnimated();
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [paginationData, setPaginationData] = useState()
    const [currentPage, setCurrentPage] = useState(0);

    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure();

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isCatOpen, setIsCatOpen] = useState(false);
    //Modal
    const [changeModal, setChangeModal] = useState(true)


    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };


    const onOpenCat = () => {
        setIsCatOpen(true);
    };

    const onCloseCat = () => {
        setIsCatOpen(false);
        setMessage('')
    };

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };

    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();


    const [catId, setCatId] = useState(null);
    const cancelRef = React.useRef()

    //Category data
    const [data, setData] = useState([]);
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [sCatDescription, setSCatDescription] = useState();


    const [message, setMessage] = useState('');
    const [scatName, setScatName] = useState('');

    const [selectedCatImage, setSelectedCatImage] = useState()
    const [selectedCatImg, setSelectedCatImg] = useState()

    const [selectedScatImage, setSelectedScatImage] = useState()
    const [selectedScatImg, setSelectedScatImg] = useState()
    const [selectedScatImageEdit, setSelectedScatImageEdit] = useState()
    const [selectedScatImgEdit, setSelectedScatImgEdit] = useState()


    const [imageCat, setImageCat] = useState()
    const [imageScat, setImageScat] = useState()
    const [imageScatEdit, setImageScatEdit] = useState()

    const [scatNameEdit, setScatNameEdit] = useState('');
    const [sCatDescriptionEdit, setSCatDescriptionEdit] = useState();

    const [sCatData, setSCatData] = useState([]);
    const [catData, setCatData] = useState([]);

    const [pays, setPays] = useState([]);

    const [villesOptions, setVillesOptions] = useState([]);
    const [villesData, setVillesData] = useState([]);
    const [selectedVilles, setSelectedVilles] = useState([]);

    const [options, setOptions] = useState([
        { value: '', label: '' },
    ]);

    const [selectedOptions, setSelectedOptions] = useState(null);

    const [loading, setLoading] = useState(true)

    // current user data

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                await axiosClient.get('auth/user/')
                    .then(({ data }) => {
                        setUser(data)
                    })
                // Fetch pays data
                const { data: paysData } = await axiosClient.get('pays/');
                setPays(paysData);

                // Initialize options for Pays select
                const paysOptions = paysData.map(pay => ({
                    value: pay.id,
                    label: pay.nom_pays,
                }));
                setOptions(paysOptions);

                // Initialize selected pays based on catData.pays
                let initialSelectedPays
                if (catData.pays && catData.pays.length > 0) {
                    initialSelectedPays = paysOptions.filter(option => catData.pays.includes(option.value));
                    setSelectedOptions(initialSelectedPays);
                }

                // Fetch villes data
                const { data: villesData } = await axiosClient.get('villes/');
                setVillesData(villesData);

                // Initialize villesOptions based on selected pays (catData.pays)
                updateVillesOptions(initialSelectedPays, villesData);

                // Initialize selected villes based on catData.villes
                if (catData.villes && catData.villes.length > 0) {
                    const initialSelectedVilles = villesData.filter(ville => catData.villes.includes(ville.id))
                        .map(ville => ({
                            value: ville.id,
                            label: ville.nom_ville,
                        }));
                    setSelectedVilles(initialSelectedVilles);
                } else {
                    setSelectedVilles([]); // Clear selectedVilles if no initial selection
                }

            } catch (error) {
                // console.error('Error fetching data:', error);
            }

            setLoading(false);
        };

        fetchData();
    }, [catData]);

    // Function to update villesOptions based on selected pays
    const updateVillesOptions = (selectedPays, villesData) => {
        const selectedPaysIds = selectedPays.map(option => option.value);
        const filteredVilles = villesData?.filter(ville => selectedPaysIds.includes(ville.pays));

        const groupedVilles = filteredVilles.reduce((acc, ville) => {
            const paysName = options.find(option => option.value === ville.pays).label;
            if (!acc[paysName]) {
                acc[paysName] = [];
            }
            acc[paysName].push({
                value: ville.id,
                label: ville.nom_ville,
            });
            return acc;
        }, {});

        const groupedVillesOptions = Object.keys(groupedVilles).map(paysName => ({
            label: paysName,
            options: groupedVilles[paysName],
        }));

        setVillesOptions(groupedVillesOptions);
        // console.log("selected villes",selectedVilles.filter(ville => filteredVilles.some(v => v.id === ville.value)))
        setSelectedVilles(selectedVilles?.filter(ville => filteredVilles.some(v => v.id === ville.value)))
    };

    // Handle changes in selected Pays
    const handleSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        updateVillesOptions(selectedOptions, villesData);
    };

    const handleSelectPaysAddChange = async (selectedOptions) => {

        setSelectedOptions(selectedOptions);
        const selectedPaysIds = selectedOptions.map(option => option.value);
        let filteredVilles = []
        await axiosClient.get('villes/').then(res => {
            filteredVilles = res.data?.filter(ville => selectedPaysIds.includes(ville.pays))
        })
        // const filteredVilles = villesData?.filter(ville => selectedPaysIds.includes(ville.pays));

        const groupedVilles = filteredVilles.reduce((acc, ville) => {
            const paysName = options.find(option => option.value === ville.pays).label;
            if (!acc[paysName]) {
                acc[paysName] = [];
            }
            acc[paysName].push({
                value: ville.id,
                label: ville.nom_ville,
            });
            return acc;
        }, {});

        const groupedVillesOptions = Object.keys(groupedVilles).map(paysName => ({
            label: paysName,
            options: groupedVilles[paysName],
        }));
        setVillesOptions(groupedVillesOptions);
    };

    // Handle changes in selected Villes
    const handleVillesChange = (selectedVilles) => {
        setSelectedVilles(selectedVilles);
    };

    const handleChangeCatImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedCatImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedCatImage(imageFile);
        } catch (err) {
            // console.log(err);
        }
        // const image = e.target.files[0]
        // setSelectedCatImage(image)
    }

    const handleChangeScatImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedScatImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedScatImage(imageFile);
        } catch (err) {
            // console.log(err);
        }
        // const image = e.target.files[0]
        // setSelectedScatImage(image)
    }

    const handleChangeScatImageEdit = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedScatImgEdit(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedScatImageEdit(imageFile);
        } catch (err) {
            // console.log(err);
        }
        // const image = e.target.files[0]
        // setSelectedScatImageEdit(image)
    }


    //get the data
    useEffect(() => {
        // setLoadingData(true)

        axiosClient.get(`/categories/getAllCategories/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)
            });

        axiosClient.get('subCategories/')
            .then((res) => {
                const sortedData = res.data.sort((a, b) => a.order - b.order);
                setSCatData(sortedData);
                // setSCatData(res.data.sort().reverse())

            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)
            });

        if (selectedCatImage) {
            setImageCat(URL.createObjectURL(selectedCatImage))
        }
        else {
            setSelectedCatImage()
        }

        if (selectedScatImage) {
            setImageScat(URL.createObjectURL(selectedScatImage))
        }
        else {
            setSelectedScatImage()
        }

        if (selectedScatImageEdit) {
            setImageScatEdit(URL.createObjectURL(selectedScatImageEdit))
        }
        else {
            setSelectedScatImageEdit()
        }
    }, [selectedCatImage, selectedScatImage, selectedScatImageEdit, pageSize, currentPage, searchTerm])

    const AjoutCategory = () => {
        if (!name || name.trim() === "") {
            setMessage(t("Veuillez remplir le nom"))
            return;
        }
        const selectedPaysIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        const selectedVilleIds = selectedVilles ? selectedVilles.map(option => option.value) : [];

        const formData = new FormData()
        formData.append("name", name)
        formData.append("description", description)
        for (let index = 0; index < selectedPaysIds.length; index++) {
            formData.append("pays", selectedPaysIds[index]);
        }
        for (let index = 0; index < selectedVilleIds.length; index++) {
            formData.append("villes", selectedVilleIds[index]);
        }


        if (selectedCatImage) formData.append("image", selectedCatImage);

        formData.append("added_by", user.first_name + ' ' + user.last_name)

        if (data.length > 0) {
            const check = data.find(obj => {
                return obj.name.toLowerCase() === name.toLowerCase().trim();
            })
            if (check) {
                return setMessage(t('Cette catégorie existe déja !'))
            }
        }
        axiosClient.post('/categories/', formData)
            .then((response) => {
                setData([response.data, ...data]);
                setName('')
                setDescription('')
                setMessage("")
                setSelectedCatImage()
                setSelectedOptions()
                setSelectedVilles()
                setVillesOptions([])
                setImageCat(null)
                toast({
                    description: `${t("La catégorie")} ${name} ${t("est ajoutée avec succès")} `,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        onClose()
    }
    function update() {
        axiosClient.get(`/categories/getAllCategories/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)
            });

        axiosClient.get('subCategories/')
            .then((res) => {
                setSCatData(res.data.sort().reverse())

            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)
            });

    }
    function reset() {
        setSelectedScatImage(null)
        setSelectedScatImageEdit(null)
        setImageCat(null)
        setImageScat(null)
        setIsAddFormOpen(false)
        setIsEditFormOpen(false)
        setImageScatEdit(null)
        setMessage('')
        setScatNameEdit('')
    }

    const AjoutSubCategory = () => {

        if (!scatName || scatName.trim() === "") {
            setMessage(t("Veuillez remplir le nom"))
            return;
        }
        const formData = new FormData()
        formData.append("nom_subCategory", scatName)
        formData.append("category", catId)
        formData.append("description", sCatDescription)
        if (selectedScatImage) formData.append("image", selectedScatImage);


        if (sCatData.length > 0) {
            const check = sCatData.find(obj => {
                return obj.nom_subCategory.toLowerCase() === scatName.toLowerCase().trim()
            })
            if (check) {
                return setMessage(t('Cette sous catégorie existe déja !'))
            }
        }
        axiosClient.post('/subCategories/', formData)
            .then((response) => {
                setMessage("")
                toast({
                    description: `${t("La sous catégorie")} ${scatName}  ${t("est ajoutée avec succès")}`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                setScatName('')

                update()
                reset()

            })
    }

    const date = new Date();
    date.setDate(date.getDate());

    const [edited_at, setedited_at] = useState(date)

    const updateCategory = (id) => {
        if (!catData.name || catData.name.trim() === "") {
            setMessage(t("Veuillez remplir le nom"))
            return;
        }

        const formData = new FormData();
        if (name) formData.append("name", name);
        if (description) formData.append("description", description);
        if (selectedCatImage) formData.append("image", selectedCatImage);
        const selectedPaysIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        const selectedVillesIds = selectedVilles ? selectedVilles.map(option => option.value) : [];
        for (let index = 0; index < selectedPaysIds.length; index++) {
            formData.append("pays", selectedPaysIds[index]);
        }
        let tabPays = []
        if (selectedPaysIds.length > 0) {
            for (let index = 0; index < selectedPaysIds.length; index++) {
                tabPays.push(selectedPaysIds[index]);
            }
        }
        let tab = []
        if (selectedVillesIds.length > 0) {
            for (let index = 0; index < selectedVillesIds.length; index++) {
                tab.push(selectedVillesIds[index]);
            }
        }
        for (let index = 0; index < selectedVillesIds.length; index++) {
            formData.append("villes", selectedVillesIds[index]);
        }
        formData.append("edited_by", user.first_name + ' ' + user.last_name)

        const newList = data.filter((e) => e.id !== id)
        if (name) {
            if (newList.length > 0) {
                const check = newList.find(obj => {
                    return obj.name.toLowerCase() === name.toLowerCase()
                })
                if (check) {
                    return setMessage(t('Cette catégorie existe déja !'))
                }
            }
        }
        const fd = new FormData()
        if (selectedCatImage) fd.append("image", selectedCatImage);
        axiosClient.patch(`/categories/${id}/`, {
            ...(name && { name: formData.get("name") }),
            ...(description && { description: formData.get("description") }),
            ...(user && { edited_by: formData.get("edited_by") }),
            ...(user && { edited_by: `${user.first_name} ${user.last_name}` }),
            pays: tabPays, // Ensure tabPays is correctly formatted
            villes: tab,   // Ensure tab is correctly formatted

        }).then((res) => {

            update()
            axiosClient.patch(`/categories/${id}/`, fd).then(() => {
                update()

                toast({
                    description: `${t("La catégorie")} ${t("est modifiée avec succès")}`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                setName('')
                setDescription('')
                setMessage("")
                setSelectedCatImage()
                setVillesOptions([])
                setSelectedOptions()
                setSelectedVilles()
                setImageCat(null)
            })

            onClose()
        })

    }
    const updateSousCategory = (id) => {
        if (!sousCat.nom_subCategory || sousCat.nom_subCategory.trim() === "") {
            setMessage(t("Veuillez remplir le nom"))
            setScatName('')
            return;
        }
        const formData = new FormData();
        if (scatNameEdit) formData.append("nom_subCategory", scatNameEdit);
        if (sCatDescriptionEdit) formData.append("description", sCatDescriptionEdit)
        if (selectedScatImageEdit) formData.append("image", selectedScatImageEdit);


        const newList = sCatData.filter((e) => e.id !== id)
        if (scatNameEdit) {
            if (newList.length > 0) {
                const check = newList.find(obj => {

                    return obj.nom_subCategory.toLowerCase() === scatNameEdit.toLowerCase()
                })
                if (check) {
                    return setMessage(t('Cette sous catégorie existe déja !'))
                }


            }
        }


        axiosClient.patch(`/subCategories/${id}/`, formData).then(() => {


            toast({
                // title: 'Modification',
                description: t(`La sous catégorie est modifiée avec succès`),
                status: 'success',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
            reset()
            update()
        })
        setScatNameEdit('')



    }
    const handleDelete = (id = catId) => {
        if (!id) return;
        axiosClient.delete(`/categories/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("La catégorie est supprimée avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }

    const handleSCatDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/subCategories/${id}/`)
            .then((response) => {
                setSCatData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("La sous catégorie est supprimée avec succès"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right',
                    variant: 'left-accent',
                })
            })
            .catch((error) => {
                // console.error(error);
            });
        update()
        // onCloseAlert()
    }



    const initRef = React.useRef()

    const [sousCat, setSousCat] = useState([])

    const selectSousCat = (id) => {
        axiosClient.get(`/subCategories/${id}/`).then(res => {
            setSousCat(res.data)
        })
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        // Reorder the items
        const reorderedItems = Array.from(data);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);

        setData(reorderedItems);

        // Update the database with the new order
        updateDatabase(reorderedItems);
    };

    // Fonction pour simuler la mise à jour dans la base de données
    const updateDatabase = (newOrder) => {
        const updatedOrder = newOrder.map((item, index) => ({
            id: item.id,
            order: index,
        }));

        axiosClient.post('/categories/update_order/', updatedOrder)
            .then(response => {
                // console.log("Order updated successfully:", response.data);
            })
            .catch(error => {
                // console.error("Error updating order:", error);
            });
    };


    const handleOnDragEndScat = (result) => {
        if (!result.destination) return;
        
        // Filter subcategories for the current category
        const filteredItems = sCatData.filter(row => row.category === catId);

        // Reorder the subcategories for the current category
        const reorderedItems = Array.from(filteredItems);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);        

        setSCatData(reorderedItems); // Update state

        // Update the database with new order
        updateDatabaseScat(reorderedItems);
    };




    // Fonction pour simuler la mise à jour dans la base de données
    const updateDatabaseScat = (newOrder) => {
        // Group subcategories by category_id and reset their order
        const groupedOrder = newOrder.map((item, index) => ({
            id: item.id,
            order: index, // Update order based on new position
            category: item.category, // Retain category information for the update
        }));

        // Send the updated order to the backend
        axiosClient.post('/subCategories/update_order/', groupedOrder)
            .then(response => {
                console.log("Order updated successfully:", response.data);
            })
            .catch(error => {
                console.error("Error updating order:", error);
            });
    };


    const colorbg = useColorModeValue('white', '#2d3748')
    const multiLabel = useColorModeValue('#e6e6e6', '#4e536c')

    const multitext = useColorModeValue('black', 'white')


    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    return (
        <Box mt="5px" >
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span>{t('Catégories')}</span></Text>
                    <Button onClick={() => {
                        setChangeModal(true)
                        onOpen()
                    }}
                        leftIcon={<PiPlusBold color='#49b1cc' />} colorScheme='gray' variant='solid'>
                        {t("Ajouter catégorie")}
                    </Button>
                </Box>

            </Flex>


            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="end"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    >

                        <InputGroup size={"sm"} w={{ base: '100%', lg: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius={"5"}
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
                                    <Table size={"sm"} variant='striped' colorScheme="gray">
                                        <Thead>
                                            <Tr>
                                                <Th fontFamily='Plus Jakarta Display'>Image</Th>
                                                <Th fontFamily='Plus Jakarta Display'>{t('Nom')}</Th>
                                                <Th fontFamily='Plus Jakarta Display'>Description</Th>
                                                {/* <Th fontFamily='Plus Jakarta Display'>Ajoutée par</Th> */}
                                                <Th fontFamily='Plus Jakarta Display'>{t("Date Création")}</Th>
                                                <Th fontFamily='Plus Jakarta Display'>{t("Sous Catégorie")}</Th>
                                                {/* <Th fontFamily='Plus Jakarta Display'>Historique</Th> */}

                                                <Th fontFamily='Plus Jakarta Display'>Options</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {loadingData &&
                                                <Tr>
                                                    <Td><SkeletonCircle size='10' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>
                                                    <Td> <Skeleton height='20px' /></Td>

                                                </Tr>}
                                            {(paginationData?.length !== 0) ?
                                                data.map((row, index) => (
                                                    <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                                        {(provided) => (

                                                            <Tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} p={0} key={row.id}>
                                                                <Td><Avatar name={row.name} borderRadius='15px' src={row.image} /></Td>
                                                                <Td>{row.name}</Td>
                                                                <Td >
                                                                    <Flex flexDirection='row' gap={1}>
                                                                        {row.description.length > 0 ?
                                                                            <>
                                                                                <Text>{row.description.substring(0, 20)} {row.description.length > 20 && <>...</>}</Text>
                                                                                <Popover>
                                                                                    <PopoverTrigger >
                                                                                        <Text cursor='pointer' _hover={{ color: 'lightgray' }}> {row.description.length > 20 && <>{t("(voir plus)")}</>} </Text>
                                                                                    </PopoverTrigger>
                                                                                    <PopoverContent >
                                                                                        <PopoverArrow />
                                                                                        {/* <PopoverCloseButton /> */}
                                                                                        <PopoverBody>
                                                                                            <Textarea readOnly border={"none"} resize='none' value={row.description}>
                                                                                                {row.description}
                                                                                            </Textarea>
                                                                                        </PopoverBody>
                                                                                    </PopoverContent>
                                                                                </Popover>
                                                                            </>

                                                                            :
                                                                            <Text color={'gray.400'}>{t("Non disponible")}</Text>



                                                                        }

                                                                    </Flex>

                                                                </Td>
                                                                {/* <Td>{row.added_by}</Td> */}
                                                                <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>
                                                                <Td><Button
                                                                    size="sm"
                                                                    onClick={() => {
                                                                        onOpenCat()
                                                                        // setChangeModal(false)
                                                                        // setName(row.name)
                                                                        setCatId(row.id);
                                                                        // onOpen();
                                                                    }}
                                                                    colorScheme='linkedin'
                                                                >
                                                                    {t("Sous catégorie")}
                                                                </Button></Td>
                                                                {/* <Td p={4}>
                                            <Popover placement="left" >
                                                <PopoverTrigger>
                                                    <Button
                                                        size="sm"
                                                        mr="5px"
                                                        bg="gray.300"
                                                        color={"black"}
                                                        leftIcon={<MdHistory />}>
                                                        Historique
                                                    </Button>

                                                </PopoverTrigger>
                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, gray)' >
                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                        Historique
                                                    </PopoverHeader>
                                                    <PopoverArrow bg="gray" />
                                                    <PopoverCloseButton />
                                                    <PopoverBody>

                                                        <div style={{ marginBottom: '8px' }}>
                                                            Modifié par : {row.edited_by}
                                                        </div>

                                                        <div>
                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                        </div>
                                                    </PopoverBody>
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    </PopoverHeader>
                                                </PopoverContent>
                                            </Popover>
                                        </Td> */}



                                                                <Td>
                                                                    <Button
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setChangeModal(false)
                                                                            setCatData(row)
                                                                            setCatId(row.id);
                                                                            if (!loading) onOpen();

                                                                        }}

                                                                        variant={'outline'}
                                                                        colorScheme='whatsapp'

                                                                        leftIcon={<EditIcon />}>
                                                                        {t("Modifier")}
                                                                    </Button>

                                                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") && <Button
                                                                        ml={5}
                                                                        size="sm"
                                                                        onClick={() => {
                                                                            setCatId(row.id);
                                                                            onOpenAlert();
                                                                        }}
                                                                        colorScheme='red'
                                                                        leftIcon={<DeleteIcon />}>
                                                                        {t("Supprimer")}
                                                                    </Button>
                                                                    }

                                                                </Td>
                                                            </Tr>
                                                        )}
                                                    </Draggable>
                                                ))
                                                :
                                                <Tr>
                                                    <Td colSpan={8}>{t("il n'y a pas encore de données.")}</Td>
                                                </Tr>
                                            }

                                            {((searchTerm && paginationData?.length === 0) || (checkLengthData)) && (
                                                <Tr>
                                                    <Td colSpan={8}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                                </Tr>

                                            )}
                                        </Tbody>
                                    </Table>

                                </TableContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>

            <AlertDialog
                size={'lg'}
                isOpen={isCatOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    onCloseCat()
                    reset()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>

                            {(isAddFormOpen && !isEditFormOpen) ?
                                <Flex align={'center'} justifyContent={"space-between"} >
                                    <Text>{t("Ajouter une sous catégorie")}</Text>
                                    <IconButton _active={'none'} _hover={'none'} background={'none'} icon={<BiLeftArrowAlt size={25} />} onClick={() => {
                                        setIsAddFormOpen(false)
                                        setMessage('')

                                    }}></IconButton>
                                </Flex>
                                : (isEditFormOpen && !isAddFormOpen) ?
                                    <Flex align={'center'} justifyContent={"space-between"} >
                                        <Text>{t("Modifier la sous catégorie")}</Text>
                                        <IconButton _active={'none'} _hover={'none'} background={'none'} icon={<BiLeftArrowAlt size={25} />} onClick={() => {
                                            setIsEditFormOpen(false)
                                            setMessage('')
                                            setScatNameEdit('')
                                        }}></IconButton>
                                    </Flex>
                                    : t('Liste des sous catégories')}

                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }

                            {isEditFormOpen &&
                                //Edit form
                                <>

                                    <Flex flexDirection={'column'} align={'center'}>
                                        <Avatar src={imageScatEdit ? imageScatEdit : sousCat.image ? sousCat.image : sousCat.image} mb={3} me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>
                                            <label htmlFor="imageInput">
                                                <input
                                                    id="imageInput"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handleChangeScatImageEdit}
                                                />
                                                <AvatarBadge
                                                    cursor='pointer'
                                                    borderRadius='8px'
                                                    border='transparent'
                                                    bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                    boxSize='26px'
                                                    backdropFilter='blur(120px)'>
                                                    <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                                </AvatarBadge>
                                            </label>
                                        </Avatar>
                                        <Flex w="100%" flexDirection={'column'}>
                                            <FormControl mb={2} isRequired>
                                                <FormLabel>{t("Nom du sous catégorie")}</FormLabel>
                                                <Input defaultValue={sousCat.nom_subCategory} maxLength={50} name="name" onChange={e => setScatNameEdit(e.target.value)} placeholder={t("Nom du sous catégorie")} />
                                            </FormControl>
                                            <FormControl isRequired>
                                                <FormLabel>Description</FormLabel>
                                                <Textarea defaultValue={sousCat.description} height={"100px"} resize={"none"} type='text' maxLength={250} name="description" onChange={e => setSCatDescriptionEdit(e.target.value)} placeholder={t("Description du sous catégorie...")} />
                                            </FormControl>
                                        </Flex>
                                    </Flex>



                                </>}
                            {isAddFormOpen &&
                                //Add form
                                <>
                                    <Flex flexDirection={'column'} align={'center'}>
                                        <Avatar mb={3} me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={imageScat} w={{ base: "70px", md: "80px" }} h={{ base: "70px", md: "80px" }} borderRadius='15px'>
                                            <label htmlFor="imageInput">
                                                <input
                                                    id="imageInput"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handleChangeScatImage}
                                                />
                                                <AvatarBadge
                                                    cursor='pointer'
                                                    borderRadius='8px'
                                                    border='transparent'
                                                    bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                    boxSize='26px'
                                                    backdropFilter='blur(120px)'>
                                                    <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                                </AvatarBadge>
                                            </label>
                                        </Avatar>
                                        <Flex w="100%" flexDirection={'column'}>
                                            <FormControl mb={2} isRequired>
                                                <FormLabel>{t("Nom du sous catégorie")}</FormLabel>
                                                <Input maxLength={50} name="name" onChange={e => setScatName(e.target.value)} placeholder={t("Nom du sous catégorie")} />
                                            </FormControl>
                                            <FormControl isRequired>
                                                <FormLabel>Description</FormLabel>
                                                <Textarea height={"100px"} resize={"none"} type='text' maxLength={250} name="description" onChange={e => setSCatDescription(e.target.value)} placeholder={t("Description du sous catégorie...")} />
                                            </FormControl>
                                        </Flex>
                                    </Flex>

                                </>
                            }

                            {(!isAddFormOpen && !isEditFormOpen) ?
                                //list des sous catégories
                                <Flex flexDirection={'column'}>
                                    <Flex justify={"end"}>
                                        <Button leftIcon={<PiPlus />} w={'fit-content'} colorScheme='telegram' onClick={() => {
                                            setIsAddFormOpen(true)
                                            setImageScat(null)
                                        }} >{t("Sous catégorie")}</Button>
                                    </Flex>
                                    <DragDropContext onDragEnd={handleOnDragEndScat}>
                                        <Droppable droppableId="droppable-subs">
                                            {(provided) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    height={100}
                                                    mt={5}
                                                    style={{
                                                        border: '1px solid lightgray',
                                                        padding: "15px 10px",
                                                        borderRadius: '10px',
                                                        overflow: 'auto',
                                                    }}
                                                >
                                                    {sCatData.length !== 0 &&
                                                        sCatData
                                                            .filter((row) => row.category === catId)
                                                            .map((val, key) => (
                                                                <Draggable key={val.id}  draggableId={val.id.toString()} index={key}>
                                                                    {(provided) => (
                                                                        <Tag
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            // style={{
                                                                            //     cursor: "pointer",
                                                                            // }}
                                                                            borderRadius='full'
                                                                            colorScheme='gray'
                                                                            m={1}
                                                                        >
                                                                            <Avatar
                                                                                name={val.nom_subCategory}
                                                                                onClick={() => {
                                                                                    selectSousCat(val.id);
                                                                                    setSousCat('');
                                                                                    setIsEditFormOpen(true);
                                                                                }}
                                                                                src={val.image}
                                                                                size='xs'
                                                                                ml={-1}
                                                                                mr={2}
                                                                            />
                                                                            <TagLabel
                                                                                onClick={() => {
                                                                                    selectSousCat(val.id);
                                                                                    setSousCat('');
                                                                                    setIsEditFormOpen(true);
                                                                                }}
                                                                            >
                                                                                {val.nom_subCategory} 
                                                                            </TagLabel>

                                                                            <Popover size={'sm'} placement="bottom" initialFocusRef={initRef}>
                                                                                {({ isOpen, onClose }) => (
                                                                                    <>
                                                                                        <PopoverTrigger>
                                                                                            <TagCloseButton
                                                                                                style={{
                                                                                                    marginLeft: 5,
                                                                                                    fontSize: 20,
                                                                                                    cursor: "pointer",
                                                                                                    color: 'red',
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    setSousCat('');
                                                                                                    setScatName('');
                                                                                                    setName('');
                                                                                                }}
                                                                                            />
                                                                                        </PopoverTrigger>
                                                                                        <PopoverContent>
                                                                                            <PopoverArrow />
                                                                                            <PopoverHeader>Confirmation</PopoverHeader>
                                                                                            <PopoverCloseButton />
                                                                                            <PopoverBody>
                                                                                                {t(
                                                                                                    "êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement."
                                                                                                )}
                                                                                            </PopoverBody>
                                                                                            <PopoverFooter display='flex' justifyContent='flex-end'>
                                                                                                <Button
                                                                                                    onClick={() => {
                                                                                                        handleSCatDelete(val.id);
                                                                                                        onClose();
                                                                                                    }}
                                                                                                    size={'sm'}
                                                                                                    colorScheme='red'
                                                                                                >
                                                                                                    {t("Supprimer")}
                                                                                                </Button>
                                                                                            </PopoverFooter>
                                                                                        </PopoverContent>
                                                                                    </>
                                                                                )}
                                                                            </Popover>
                                                                        </Tag>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                    {provided.placeholder}
                                                </Box>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Flex> : null}

                        </AlertDialogBody>

                        <AlertDialogFooter>


                            <Button mr={2} ref={cancelRef} onClick={() => {
                                setScatName()
                                setName()
                                onCloseCat()
                                setSousCat('')
                                reset()
                            }}

                            >
                                {t("Annuler")}
                            </Button>
                            {isEditFormOpen ?
                                <Button colorScheme='teal' onClick={() => {
                                    updateSousCategory(sousCat.id)
                                }}
                                >
                                    {t("Modifier")}
                                </Button>
                                : null}
                            {isAddFormOpen ?
                                (!scatName?.trim() || !selectedScatImage || !sCatDescription?.trim() || selectedOptions)
                                    ?
                                    <Button colorScheme='green' title="Entrer le nom de sous catégorie" isDisabled
                                    >
                                        {t("Ajouter")}
                                    </Button>
                                    :
                                    <Button colorScheme='green'
                                        onClick={AjoutSubCategory}
                                    >
                                        {t("Ajouter")}
                                    </Button>
                                : null
                            }

                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>


            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer catégorie")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(catId)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>


            {changeModal ?
                //Modal d'ajout cat
                <Modal size={"lg"} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setImageCat(null)
                    setSelectedCatImage(null)
                    setSelectedOptions()
                    setSelectedVilles()
                    setVillesOptions([])
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'une catégorie")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>
                                <Flex flexDirection={{ base: "column", sm: 'row' }} align={"center"} w={"100%"} justifyContent={"center"} gap={7}>
                                    <Avatar src={imageCat} w={{ base: "120px", md: "150px" }} h={{ base: "120px", md: "150px" }} borderRadius='15px'>
                                        <label htmlFor="imageInput">
                                            <input
                                                id="imageInput"
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleChangeCatImage}
                                            />
                                            <AvatarBadge
                                                cursor='pointer'
                                                borderRadius='8px'
                                                border='transparent'
                                                bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                boxSize='26px'
                                                backdropFilter='blur(120px)'>
                                                <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                            </AvatarBadge>
                                        </label>
                                    </Avatar>
                                    <Flex w={"100%"} flexDirection={"column"}>

                                        <FormControl isRequired>
                                            <FormLabel>{t("Nom de catégorie")}</FormLabel>
                                            <Input maxLength={50} name="name" onChange={e => setName(e.target.value)} placeholder={t("Nom de catégorie")} />
                                        </FormControl>
                                        <FormControl mt={2} isRequired>
                                            <FormLabel>Description</FormLabel>
                                            <Textarea height={"60px"} resize={"none"} type='text' maxLength={250} name="description" onChange={e => setDescription(e.target.value)} placeholder={t('Description de catégorie...')} />
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex w="100%" flexDirection={'column'}>

                                    <FormControl mt={2} isRequired>
                                        <FormLabel>{t("Pays")}</FormLabel>
                                        <MySelect
                                            defaultValue={selectedOptions}
                                            onChange={handleSelectPaysAddChange}
                                            options={options}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'transparent',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                multiValueLabel: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    overflow: "visible",
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer"
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer"
                                                }),

                                                multiValueRemove: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl mt={2} isRequired>
                                        <FormLabel>{t("Villes")}</FormLabel>
                                        <MySelect
                                            defaultValue={selectedVilles}
                                            onChange={handleVillesChange}
                                            options={villesOptions}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'transparent',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                multiValueLabel: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    overflow: "visible",
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer"
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer"
                                                }),

                                                multiValueRemove: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor
                                                }),
                                            }}
                                        />
                                    </FormControl>
                                </Flex>
                            </Flex>
                            {/* <Flex justify="end">
                  {name.length > 40
                    ? <Text fontSize='xs' color="red">{name.length}/50 caractères !</Text>
                    : <Text fontSize='xs'>{name.length}/50 {name.length > 1 ? "caractères" : "caractère"}</Text>
                  }
                </Flex> */}
                        </ModalBody>

                        <ModalFooter>
                            {(!name?.trim() || !description?.trim() || !selectedCatImage)
                                ?
                                <Button colorScheme='blue' title="Ajouter" isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutCategory}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                //Modal de modifaction
                <Modal size={"lg"} closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setSelectedCatImage(null)
                    setSelectedOptions()
                    setSelectedVilles()
                    setVillesOptions([])
                }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{t("Modification de catégorie")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>
                                <Flex flexDirection={{ base: "column", sm: 'row' }} align={"center"} w={"100%"} justifyContent={"center"} gap={7}>
                                    <Avatar src={imageCat ? imageCat : catData.image ? catData.image : catData.image} w={{ base: "120px", md: "150px" }} h={{ base: "120px", md: "150px" }} borderRadius='15px'>
                                        <label htmlFor="imageInput">
                                            <input
                                                id="imageInput"
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleChangeCatImage}
                                            />
                                            <AvatarBadge
                                                cursor='pointer'
                                                borderRadius='8px'
                                                border='transparent'
                                                bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                boxSize='26px'
                                                backdropFilter='blur(120px)'>
                                                <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                            </AvatarBadge>
                                        </label>
                                    </Avatar>
                                    <Flex w={"100%"} flexDirection={"column"}>

                                        <FormControl isRequired>
                                            <FormLabel>{t("Nom de catégorie")}</FormLabel>
                                            <Input maxLength={50} name="name" defaultValue={catData.name} onChange={e => setName(e.target.value)} placeholder={t("Nom de catégorie")} />
                                        </FormControl>
                                        <FormControl mt={2} isRequired>
                                            <FormLabel>Description</FormLabel>
                                            <Textarea height={"100px"} resize={"none"} type='text' defaultValue={catData.description} maxLength={250} name="description" onChange={e => setDescription(e.target.value)} placeholder={t('Description de catégorie...')} />
                                        </FormControl>
                                    </Flex>
                                </Flex>

                                <Flex w="100%" flexDirection={'column'}>

                                    <FormControl w={"100%"} mt={2} isRequired>
                                        <FormLabel>{t("Pays")}</FormLabel>
                                        <MySelect

                                            defaultValue={options.filter(option => catData.pays.includes(option.value))}
                                            onChange={handleSelectChange}
                                            options={options}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            styles={{

                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'transparent',

                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer"
                                                }),
                                                multiValueLabel: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    overflow: 'visible',
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer"
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer"
                                                }),

                                                multiValueRemove: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                }),
                                                option: (provided, state) => ({
                                                    backgroundColor: colorbg,

                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },

                                                    cursor: "pointer"
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                }),

                                            }}
                                        />
                                    </FormControl>
                                    <FormControl w={"100%"} mt={2} isRequired>
                                        <FormLabel>{t("Villes")}</FormLabel>
                                        <MySelect
                                            value={selectedVilles}
                                            onChange={handleVillesChange}
                                            options={villesOptions}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: 'transparent',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: colorbg,
                                                    cursor: "pointer",
                                                }),
                                                multiValueLabel: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    overflow: 'visible',
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer",
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    textAlign: 'left',
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                    cursor: "pointer",
                                                }),
                                                multiValueRemove: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: multiLabel,
                                                    color: multitext,
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                    backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                    '&:hover': {
                                                        backgroundColor: colorbghover,
                                                    },
                                                    cursor: "pointer",
                                                }),
                                                singleValue: (provided) => ({
                                                    ...provided,
                                                    color: txtcolor,
                                                }),
                                            }}
                                        />
                                    </FormControl>

                                </Flex>

                            </Flex>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='gray' onClick={() => {
                                updateCategory(catId)
                            }}>
                                {t("Modifier")}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>

    )
}

export default GestCategory