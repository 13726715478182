import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { AddIcon, SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
import coupon from "../../assets/images/coupon.png";
import discount from "../../assets/images/discount.png";


import {
    IconButton,
    Flex,
    Box,
    Heading,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Switch,
    Badge,
    Td,
    Input,
    useToast,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Tooltip,
    Text,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Modal,
    Alert,
    ModalFooter,
    ModalOverlay,
    AlertIcon,
    useDisclosure,
    FormControl,
    FormLabel,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
    Card,
    CardHeader,
    Menu,
    MenuList,
    MenuButton,
    MenuItem,
    SimpleGrid,
    Image,
    SkeletonCircle,
    Skeleton,
    FocusLock,
    ButtonGroup,

} from '@chakra-ui/react';
import { MdGroupRemove } from 'react-icons/md';
import { PiPlus, PiPlusBold } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { BiLeftArrowAlt, BiSolidLeftArrowAlt } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';


export const GestClients = () => {
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)

    const toast = useToast()
    const [selectedEtat, setSelectedEtat] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [listTypeAbonnements, setListTypeAbonnements] = useState([]);
    const [abonnements, setAbonnements] = useState([]);
    const { t } = useTranslation();
    const [email, setEmail] = useState()

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    //search variable
    const [searchTerm, setSearchTerm] = useState('');
    const [changeModal, setChangeModal] = useState(true)

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isEmailOpen, setIsEmailOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [typeAb, setTypeAb] = useState();
    const [typeAbFilter, setTypeAbFilter] = useState("");

    const [typeFee, setTypeFee] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [clientData, setClientData] = useState();
    const [clientAb, setClientAb] = useState();

    const initRef = React.useRef()
    const [currentPage, setCurrentPage] = useState(0);
    const [paginationData, setPaginationData] = useState()
    const [indexDisplayEmail, setIndexDispalyEmail] = useState(-1)

    // const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = React.useRef(null)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onOpenEmail = () => {
        setIsEmailOpen(true);
    };

    const onCloseEmail = () => {
        setIsEmailOpen(false);
        setIndexDispalyEmail(-1)
        setMessageE('')
        setEmail()
    };

    const MAX_WORDS = 2; // Maximum number of words to display

    function truncateAddress(address) {
        const words = address.split(' ');
        if (words.length > MAX_WORDS) {
            return words.slice(0, MAX_WORDS).join(' ') + '...';
        }
        return address;
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user, setUser } = useStateContext();

    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Client data
    const [data, setData] = useState([]);

    // current user data
    useEffect(() => {
        axiosClient.get('auth/user/')
            .then(({ data }) => {
                setUser(data)
            })
    }, [])

    //get the clients data
    useEffect(() => {
        // setLoadingData(true)

        axiosClient.get(`/clients/getAllClients/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&abonnement=${typeAbFilter}&paymentType=${selectedPaymentType}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                // if (selectedEtat !== null) {
                //     filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                // }
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
        axiosClient.get('/typeAbonnements/')
            .then((response) => {
                setListTypeAbonnements(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });

        axiosClient.get('/abonnements/')
            .then((response) => {
                setAbonnements(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });
    }, [typeAb, selectedEtat, pageSize, currentPage, searchTerm, typeAbFilter,selectedPaymentType]);

    function update() {
        // setLoadingData(true)

        axiosClient.get(`/clients/getAllClients/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&etat=${selectedEtat}&abonnement=${typeAbFilter}&paymentType=${selectedPaymentType}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);
                // if (selectedEtat !== null) {
                //     filteredData = filteredData.filter((item) => item.etat === selectedEtat);
                // }
                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
        axiosClient.get('/typeAbonnements/')
            .then((response) => {
                setListTypeAbonnements(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });

        axiosClient.get('/abonnements/')
            .then((response) => {
                setAbonnements(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });
    }

    const [message, setMessage] = useState(false)

    // delete client
    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/clients/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("l'utilisateur est supprimé avec succès"),
                    status: 'success',
                    variant:'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }

    //search method
    // const filteredData = useCallback(() => {
    //     return data.filter((row) => {
    //         // Exclude data of current user
    //         if (row.id === user.id || row.role === "SUPERADMIN") {
    //             return false;
    //         }
    //         if (row.role === "ADMIN" && (user.role !== "SUPERADMIN")) {
    //             return false;
    //         }
    //         return (
    //             row.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.pays.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.ville.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.rue.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.code_postal.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.email.toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //     });
    // }, [data, searchTerm, user.id]);


    //edit l'etat de client ('activé','désactivé')
    const handleEtatClick = (id, etat) => {
        const formData = new FormData();
        formData.append("etat", !etat);
        formData.append("edited_by", user.first_name + ' ' + user.last_name);

        axiosClient.put(`/update_client_etat/${id}/`, formData)
            .then((response) => {
                setData(rows => rows.map(row => {
                    if (row.id === id) {
                        return {
                            ...row,
                            etat: !etat,
                        };
                    }
                    return row;
                }));
            })
            .catch((error) => {
                // console.error(error)
            });
        if (!etat) {
            toast({
                description: t("le compte est activé"),
                status: 'success',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
        else {
            toast({
                description: t("le compte est désactivé"),
                status: 'warning',
                variant: 'left-accent',
                duration: 2000,
                isClosable: true,
                position: 'bottom-right'
            })
        }
    }

    const AjoutAbonnement = () => {
        // let frais
        // if (typeFee === "Mentuel") {
        //     frais = listTypeAbonnements.find(e => e.id === parseInt(typeAb))?.monthly_fee
        // }
        // else if (typeFee === "Annuel") {
        //     frais = listTypeAbonnements.find(e => e.id === parseInt(typeAb))?.yearly_fee
        // }
        const frais = listTypeAbonnements.find(e => e.id === parseInt(typeAb))?.yearly_fee
        if (abonnements.find(e => e.client === parseInt(clientData.id) && e.type_abonnement === parseInt(typeAb))) {
            setMessage("cet utilisateur a déja cet abonnement")
            return
        }
        const formData = new FormData()
        formData.append("client", clientData.id)
        formData.append("type_abonnement", typeAb)
        formData.append("fee_type", "Annuel")
        formData.append("payment_mode", paymentMode)
        formData.append("start_date", startDate)
        // if (typeFee === "Mentuel") {
        //     const newDate = new Date(startDate);
        //     newDate.setMonth(newDate.getMonth() + 1);
        //     formData.append("end_date", newDate.toISOString().split('T')[0]);
        //   } else if (typeFee === "Annuel") {
        const newDate = new Date(startDate);
        newDate.setFullYear(newDate.getFullYear() + 1);
        formData.append("end_date", newDate.toISOString().split('T')[0]);
        //   }

        formData.append("total_frais", frais)

        axiosClient.post('/abonnements/', formData)
            .then((response) => {
                setListTypeAbonnements([response.data, ...listTypeAbonnements]);

                setMessage("")
                setTypeAb()
                // setTypeFee('')
                setPaymentMode('')
                setStartDate(null)
                update()
                reset()

                toast({
                    description: t(`L'abonnement est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                onClose()

            })
            .catch((error) => {
                // console.error(error);
            });
    }

    const UpdateAbonnement = (id) => {
        const newList = abonnements.filter((e) => e.id !== id)

        if (newList.find(e => e.client === parseInt(clientData.id) && e.type_abonnement === parseInt(typeAb))) {
            setMessage("cet utilisateur a déja cet abonnement")
            return
        }
        const formData = new FormData()
        if (typeAb) { formData.append("type_abonnement", typeAb) }
        if (paymentMode) { formData.append("payment_mode", paymentMode) }
        if (startDate) {
            formData.append("start_date", startDate)
            const newDate = new Date(startDate);
            newDate.setFullYear(newDate.getFullYear() + 1);
            formData.append("end_date", newDate.toISOString().split('T')[0]);
        }
        axiosClient.patch(`/abonnements/${id}/`, formData)
            .then((response) => {
                setListTypeAbonnements([response.data, ...listTypeAbonnements]);

                setMessage("")
                setTypeAb()
                // setTypeFee('')
                setPaymentMode('')
                setStartDate(null)
                update()
                reset()
                toast({
                    description: t(`L'abonnement est modifié avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        onClose()
    }
    const deleteAb = (id) => {
        if (!id) return;
        axiosClient.delete(`/abonnements/${id}/`)
            .then((response) => {
                setListTypeAbonnements((prevData) => prevData.filter((row) => row.id !== id));

                toast({
                    description: t("L'abonnement est supprimé avec succès"),
                    status: 'success',
                    variant:'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                update()
                reset()
            })

            .catch((error) => {
                // console.error(error);
            });
        // onClose()
    }
    const [messageE, setMessageE] = useState('');
    const emailRegex = /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z]+(?:-[a-zA-Z]+)?\.[a-zA-Z]{2,}$/;

    const EditEmail = async (id) => {


        const formData = new FormData();
        if (email) { formData.append("email", email); }

        // const newList = listAdmins.filter((e) => e.id !== user.id);
        // if (newList.length > 0) {
        //     const check = newList.find((obj) => {
        //         return obj.email === email.trim();
        //     });
        //     if (check) {
        //         window.scrollTo(0, 0);
        //         return setMessageE(t("Ce mail existe déjà !"));
        //     }
        // }
        if (email && !emailRegex.test(email)) {
            return setMessageE(t("Veuillez entrer un email valide."));
        }
        if( !email) {
            onCloseEmail()
            return
        }

        try {
            const response = await axiosClient.put(`/update_user_email/${id}/`, formData)

            if (response.data.message) {
                setData(data.map((val, key) => {
                    if (val.id === id) {
                        return {
                            ...val,
                            email: email
                        }
                    }
                    return val
                }))
                toast({
                    description: t("L'email d'utilisateur est modifiée avec succèss"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'

                });
                onCloseEmail()
                // setTimeout(() => {
                //     window.location.reload();
                // }, 2000);
            }
            else {
                setMessageE(t("Ce mail existe déjà"))
            }
        } catch (err) {
            toast({
                description: "erreur",
                status: 'warning',
                duration: 2000,
                isClosable: true,
                variant: 'left-accent',
                position: 'bottom-right'
            })
        }
    }
    function reset() {
        setIsAddFormOpen(false)
        setIsEditFormOpen(false)
        setMessage('')
        setTypeAb()
        setTypeFee('')
        setPaymentMode('')
        setStartDate(null)
    }
    const cardColor = useColorModeValue('linear-gradient(90deg, rgba(221,230,239,1) 0%, rgba(255,255,255,1) 100%)', 'linear-gradient(90deg, rgba(75,87,104,1) 0%, rgba(45,55,72,1) 100%)')
    return (
        <Box mt="5px" >
            <Flex>
                <Box w="90%" mt={2} mb={5}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t('Accueil')}</span>  / <span >Clients</span></Text>
                </Box>
            </Flex>

            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        mb={6} 
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ base: 'column', lg: 'row' }}
                    // direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex  mb={{base:4 , lg:0}} gap={2} direction={{ base: 'column', sm: 'row' }} w={"100%"}>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', md: '40%' ,lg:"30%" }}  onChange={(e) => setTypeAbFilter(e.target.value)} placeholder={t("Choisir le type d'abonnement")}>
                                {listTypeAbonnements?.map((row,key) => (
                                    <option key={key} value={row.id}>{row.type}</option>
                                )
                                )}
                                <option value={"noAb"}>{t("Utilisateurs sans abonnement")}</option>
                            </Select>
                            <Select borderRadius="5" placeholder={t("Choisir la méthode de paiement")} size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', md: "40%" , lg:"30%" }}    value={selectedPaymentType} onChange={(e) => setSelectedPaymentType(e.target.value)}>
                                <option value='cash'>{t("Paiement en espèces")}</option>
                                <option value='card'>{t("Paiement par carte bancaire")}</option>
                            </Select>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', md: "20%" }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                <option value="">{t("Tout")}</option>
                                <option value="true">{t("Activé")}</option>
                                <option value="false">{t("Désactivé")}</option>
                            </Select>
                           
                        </Flex>



                        <InputGroup size="sm" w={{ base: '100%', lg: "40%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table size="sm" variant='striped' colorScheme="gray">
                            <Thead size="sm">
                                <Tr fontSize="12">
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Code</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Nom")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Prénom")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12"> Email</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Adresse")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Pays")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("État")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t('Abonnement')}</Th>

                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Date Création")}</Th>
                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                        <Th fontFamily='Plus Jakarta Display' fontSize="12">Options</Th>
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                    </Tr>}

                                {(paginationData?.length !== 0) &&
                                    data.map((row, key) => (<Tr key={row.id}>
                                        <Td > <Avatar size='md' src={row.image} /></Td>
                                        <Td >{row.code}</Td>
                                        <Td>{row.last_name}</Td>
                                        <Td>{row.first_name}</Td>
                                        <Td >{row.email}

                                            <Popover
                                                isOpen={isEmailOpen}
                                                initialFocusRef={firstFieldRef}
                                                onOpen={onOpenEmail}
                                                onClose={onCloseEmail}
                                                placement='bottom'
                                                closeOnBlur={false}
                                            >
                                                <PopoverTrigger >
                                                    <IconButton variant={'ghost'} onClick={() => setIndexDispalyEmail(key)} size='sm' icon={<EditIcon />} />
                                                </PopoverTrigger>
                                                {indexDisplayEmail === key &&
                                                    <PopoverContent p={5}>
                                                        <FocusLock returnFocus persistentFocus={false}>
                                                            <PopoverArrow />
                                                            <PopoverCloseButton />
                                                            <Stack pt={3} spacing={4}>
                                                                {messageE &&
                                                                    <Alert fontSize={"sm"} padding={2} status='error' rounded="md" variant='left-accent'>
                                                                        <AlertIcon />
                                                                        {messageE}
                                                                    </Alert>}
                                                                <Input maxLength={50} border={"1px solid lightgray"} defaultValue={email ? email : row.email} onChange={(e) => setEmail(e.target.value)} />

                                                                <ButtonGroup display='flex' justifyContent='flex-end'>
                                                                    <Button size={"sm"} colorScheme='red' variant='outline' onClick={onCloseEmail}>
                                                                        {t("Annuler")}
                                                                    </Button>
                                                                    <Button onClick={() => EditEmail(row.id)} size={"sm"} colorScheme='teal'>
                                                                        {t("Enregistrer")}
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Stack>
                                                        </FocusLock>
                                                    </PopoverContent>}
                                            </Popover>

                                        </Td>
                                        <Td>
                                            <Tooltip label={row.rue + " " + row.ville + " " + row.code_postal} closeOnClick={true}>
                                                {truncateAddress(row.rue + " " + row.ville + " " + row.code_postal)}
                                            </Tooltip>
                                        </Td>
                                        <Td>{row.pays}</Td>
                                        <Td>
                                            <Flex>
                                                <Stack direction='row' mr="3px">
                                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                        <Switch
                                                            colorScheme='green'
                                                            isChecked={row.etat}
                                                            onChange={() => handleEtatClick(row.id, row.etat)}
                                                        />
                                                    }
                                                </Stack>
                                                <Badge
                                                    size="sm"
                                                    rounded={'lg'}
                                                    colorScheme={!row.etat ? 'red' : 'green'}
                                                >
                                                    {row.etat ? t("Activé") : t("Désactivé")}
                                                </Badge>
                                            </Flex>
                                        </Td>
                                        <Td>
                                            {abonnements.find(e => e.client === row.id) ?
                                                <Button onClick={() => {
                                                    onOpen()
                                                    setClientData(row)
                                                    // setClientAb(abonnements.find(e => e.client === row.id))
                                                    setChangeModal(false)
                                                }}
                                                    leftIcon={<EditIcon />} colorScheme='yellow' variant='outline'>
                                                    {t('Abonnement')}
                                                </Button>
                                                :
                                                <Button onClick={() => {
                                                    onOpen()
                                                    setClientData(row)
                                                    setChangeModal(true)
                                                }}
                                                    leftIcon={<PiPlusBold />} colorScheme='whatsapp' variant='outline'>
                                                    {t('Abonnement')}
                                                </Button>}

                                        </Td>


                                        <Td>{new Date(row.date_creation).toLocaleDateString(t("fr-FR"))}</Td>
                                        <Td>

                                            {/* <IconButton
                                                size="sm"
                                                onClick={() => navigate(`/editResponsable`, {
                                                    state: {
                                                        idResponsable: row.id
                                                    }
                                                })}
                                                mr="5px"
                                                colorScheme="green"
                                                icon={<EditIcon />}>
                                            </IconButton> */}
                                            {/* <Popover placement="left" >
                                                <PopoverTrigger>
                                                    <IconButton
                                                        size="sm"
                                                        mr="5px"
                                                        bg="gray.300"
                                                        color={"black"}
                                                        icon={<MdHistory />}>
                                                    </IconButton>
                                                </PopoverTrigger>
                                                <PopoverContent w={"full"} color="black" bgGradient='linear(to-r, white, #497dff)' >
                                                    <PopoverHeader letterSpacing={1.5} pt={4} fontWeight="bold" border="0">
                                                        Historique
                                                    </PopoverHeader>
                                                    <PopoverArrow bg="#497dff" />
                                                    <PopoverCloseButton />
                                                    <PopoverBody>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Ajouté par : {row.added_by}
                                                        </div>
                                                        <div style={{ marginBottom: '8px' }}>
                                                            Modifié par : {row.edited_by}
                                                        </div>

                                                        <div>
                                                            Date de dernière modification: {new Date(row.date_modification).toLocaleString('fr-FR')}
                                                        </div>
                                                    </PopoverBody>
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    </PopoverHeader>
                                                </PopoverContent>
                                            </Popover> */}
                                            {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteId(row.id);
                                                        onOpenAlert();

                                                    }}
                                                    mr="5px"
                                                    colorScheme="red"
                                                    icon={<MdGroupRemove />}>
                                                </IconButton>}
                                        </Td>

                                    </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat !== null || selectedPaymentType !=="" || typeAbFilter !=="") && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Supprimer')}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>
                                {t('êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.')}
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button size={"sm"} ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                {t('Annuler')}
                            </Button>
                            <Button size={"sm"} onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {changeModal ?
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setTypeAb()
                    setTypeFee('')
                    setPaymentMode('')
                    setStartDate(null)
                    setMessage('')
                    // setSelectedFeeType('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajouter un abonnement")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Type d'abonnement")}</FormLabel>
                                        <Select onChange={(e) => setTypeAb(e.target.value)} placeholder={t("Choisir le type d'abonnement")}>
                                            {listTypeAbonnements?.map((row,key) => (
                                                <option key={key} value={row.id}>{row.type}</option>
                                            )
                                            )}

                                        </Select>
                                    </FormControl>
                                    {/* <FormControl isRequired mb={3}>
                                        <FormLabel>Type de frais </FormLabel>
                                        <Select onChange={(e) => setTypeFee(e.target.value)} placeholder='Choisir le type de frais'>
                                            {listTypeAbonnements?.filter(e=>e.id===parseInt(typeAb)).map((row) => {
                                                const isMonthly = row.monthly_fee !== 0 && row.yearly_fee === 0;
                                                const isYearly = row.monthly_fee === 0 && row.yearly_fee !== 0;
                                                const isBoth = row.monthly_fee !== 0 && row.yearly_fee !== 0;
                                                return (
                                                    
                                                    (isMonthly && <option key="monthly" value='Mentuel'>Mentuel</option>) ||
                                                    (isYearly && <option key="yearly" value='Annuel'>Annuel</option>) ||
                                                    (isBoth && (
                                                        <>
                                                            <option key="monthly" value='Mentuel'>Mentuel</option>
                                                            <option key="yearly" value='Annuel'>Annuel</option>
                                                        </>
                                                    ))
                                                );
                                            })}
                                        </Select>
                                    </FormControl> */}
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Mode de paiement")}</FormLabel>
                                        <Select onChange={(e) => setPaymentMode(e.target.value)} placeholder='Choisir le mode de paiement'>
                                            <option value='cash'>{t("Paiement en espèces")}</option>
                                            <option value='card'>{t("Paiement par carte bancaire")}</option>
                                        </Select>
                                    </FormControl>
                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Date début")}</FormLabel>
                                        <Input
                                            type="date"
                                            borderColor={'gray.300'}
                                            borderRadius={"5"}
                                            placeholder="Start Date"
                                            // value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </FormControl>

                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>

                            {(!typeAb || !paymentMode || !startDate)
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutAbonnement}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setTypeAb()
                    setTypeFee('')
                    setPaymentMode('')
                    setStartDate(null)
                    setIsAddFormOpen(false)
                    setIsEditFormOpen(false)
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>
                            {(isAddFormOpen && !isEditFormOpen) ?
                                <Flex align={'center'} justifyContent={"space-between"} >
                                    <Text>{t("Ajouter un abonnement")}</Text>
                                    <IconButton _active={'none'} _hover={'none'} background={'none'} icon={<BiLeftArrowAlt size={25} />} onClick={() => {
                                        setIsAddFormOpen(false)
                                        setMessage('')

                                    }}></IconButton>
                                </Flex>
                                : (isEditFormOpen && !isAddFormOpen) ?
                                    <Flex align={'center'} justifyContent={"space-between"} >
                                        <Text>{t("Modification d'abonnement")}</Text>
                                        <IconButton _active={'none'} _hover={'none'} background={'none'} icon={<BiLeftArrowAlt size={25} />} onClick={() => {
                                            setIsEditFormOpen(false)
                                            setMessage('')
                                            // setScatNameEdit('')
                                        }}></IconButton>
                                    </Flex>
                                    : t('Liste des abonnements')}
                        </ModalHeader>

                        <ModalBody >
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            {isEditFormOpen &&
                                <>
                                    <Flex flexDirection={'column'} align={'center'}>

                                        <Flex w="100%" flexDirection={'column'}>
                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Type d'abonnement")} </FormLabel>
                                                <Select defaultValue={listTypeAbonnements.find(e => e.id === parseInt(clientAb.type_abonnement))?.id} onChange={(e) => setTypeAb(e.target.value)} >
                                                    {listTypeAbonnements?.map((row) => (
                                                        <option value={row.id}>{row.type}</option>
                                                    )
                                                    )}

                                                </Select>
                                            </FormControl>

                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Mode de paiement")}</FormLabel>
                                                <Select defaultValue={clientAb.payment_mode} onChange={(e) => setPaymentMode(e.target.value)} >
                                                    <option value='cash'>{t("Paiement en espèces")}</option>
                                                    <option value='card'>{t("Paiement par carte bancaire")}</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Date début")}</FormLabel>
                                                <Input
                                                    type="date"
                                                    borderColor={'gray.300'}
                                                    borderRadius={"5"}
                                                    placeholder="Start Date"
                                                    defaultValue={clientAb.start_date}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </FormControl>

                                        </Flex>
                                    </Flex>

                                </>
                            }
                            {isAddFormOpen &&
                                <>
                                    <Flex flexDirection={'column'} align={'center'}>

                                        <Flex w="100%" flexDirection={'column'}>
                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Type d'abonnement")}</FormLabel>
                                                <Select onChange={(e) => setTypeAb(e.target.value)} placeholder={t("Choisir le type d'abonnement")}>
                                                    {listTypeAbonnements?.map((row) => (
                                                        <option value={row.id}>{row.type}</option>
                                                    )
                                                    )}

                                                </Select>
                                            </FormControl>

                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Mode de paiement")}</FormLabel>
                                                <Select onChange={(e) => setPaymentMode(e.target.value)} placeholder='Choisir le mode de paiement'>
                                                    <option value='cash'>{t("Paiement en espèces")}</option>
                                                    <option value='card'>{t("Paiement par carte bancaire")}</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Date début")}</FormLabel>
                                                <Input
                                                    type="date"
                                                    borderColor={'gray.300'}
                                                    borderRadius={"5"}
                                                    placeholder="Start Date"
                                                    // value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </FormControl>

                                        </Flex>
                                    </Flex>
                                </>
                            }
                            {(!isAddFormOpen && !isEditFormOpen) ?
                                <>

                                    <Flex flexDirection={'column'}>
                                        <Flex justify={"end"}>
                                            <Button leftIcon={<PiPlus />} w={'fit-content'} colorScheme='telegram' onClick={() => {
                                                setIsAddFormOpen(true)
                                            }} >{t("Ajouter un abonnement")}</Button>
                                        </Flex>
                                        <Flex mt={2} justify={{ base: 'flex-start', md: 'space-between' }} flexDirection={{ base: "column", md: 'row' }} gap={3} py={5}>
                                            <SimpleGrid spacing={5} columns={{ base: 1, md: 2 }} width="100%">

                                                {abonnements.length !== 0 &&
                                                    abonnements.filter((row) => row.client === clientData.id).map((val, key) => {
                                                        return (
                                                            <Card borderRadius="1px 25px 1px 25px" key={key} backgroundSize={'cover'} backgroundRepeat={"no-repeat"} bgGradient={cardColor} maxW='md'>
                                                                <CardHeader>
                                                                    <Flex spacing='4'>
                                                                        <Flex flexDirection={'row'} flex='1' gap='4' alignItems='center' flexWrap='wrap'>

                                                                            <Box>
                                                                                <Heading size='sm'>{listTypeAbonnements?.find(e => e.id === parseInt(val?.type_abonnement)).type}</Heading>
                                                                            </Box>
                                                                        </Flex>
                                                                        <Menu>
                                                                            <MenuButton
                                                                                as={IconButton}
                                                                                aria-label='Options'
                                                                                icon={<BsThreeDotsVertical />}
                                                                                variant='ghost'
                                                                            />
                                                                            <MenuList>
                                                                                <MenuItem onClick={() => {
                                                                                    setClientAb(val)
                                                                                    setIsEditFormOpen(true)
                                                                                }}>
                                                                                    {t("Modifier")}
                                                                                </MenuItem>
                                                                                <Popover size={'sm'} placement="bottom" initialFocusRef={initRef}>
                                                                                    {({ isOpen, onClose }) => (
                                                                                        <>
                                                                                            <PopoverTrigger>
                                                                                                <Text onClick={() => {
                                                                                                    setClientAb(val)
                                                                                                }} pl={3} textDecoration={'underline'} cursor={"pointer"} color={'red.500'}>{t("Supprimer l'abonnement")}</Text>
                                                                                            </PopoverTrigger>
                                                                                            <PopoverContent>
                                                                                                <PopoverArrow />
                                                                                                <PopoverHeader >Confirmation</PopoverHeader>
                                                                                                <PopoverCloseButton />
                                                                                                <PopoverBody>
                                                                                                    {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                                                                                                </PopoverBody>
                                                                                                <PopoverFooter display='flex' justifyContent='flex-end'>
                                                                                                    <Button onClick={() => {
                                                                                                        onClose()
                                                                                                        deleteAb(clientAb?.id)
                                                                                                    }} size={'sm'} colorScheme='red'>{t("Supprimer")}</Button>
                                                                                                </PopoverFooter>
                                                                                            </PopoverContent>
                                                                                        </>
                                                                                    )}
                                                                                </Popover>
                                                                            </MenuList>
                                                                        </Menu>
                                                                    </Flex>
                                                                </CardHeader>
                                                            </Card>
                                                        )
                                                    })}
                                            </SimpleGrid>
                                        </Flex>

                                    </Flex>
                                </> : null
                            }

                        </ModalBody>

                        <ModalFooter>
                            <Button ref={cancelRef} onClick={() => {
                                onClose()
                                setMessage('')
                                setTypeAb()
                                setTypeFee('')
                                setPaymentMode('')
                                setStartDate(null)
                                setIsAddFormOpen(false)
                                setIsEditFormOpen(false)
                            }}

                            >
                                {t("Annuler")}
                            </Button>
                            {isEditFormOpen ?
                                <Button ml={2} colorScheme='teal'
                                    onClick={() => UpdateAbonnement(clientAb.id)}

                                >
                                    {t("Modifier")}
                                </Button>
                                : null}
                            {isAddFormOpen ?
                                (!typeAb || !paymentMode || !startDate)
                                    ?
                                    <Button ml={2} colorScheme='blue' isDisabled
                                    >
                                        {t("Ajouter")}
                                    </Button>
                                    :
                                    <Button ml={2} colorScheme='blue'
                                        onClick={AjoutAbonnement}
                                    >
                                        {t("Ajouter")}
                                    </Button>
                                : null
                            }
                            {/* <Popover size={'sm'} placement="bottom" initialFocusRef={initRef} >
                                {({ isOpen, onClose }) => (
                                    <>
                                        <PopoverTrigger>
                                            <Text decoration={'underline'} cursor={"pointer"} color={'red.500'}>   {t("Supprimer l'abonnement")}</Text>

                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverHeader >Confirmation</PopoverHeader>
                                            <PopoverCloseButton />
                                            <PopoverBody>
                                                {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                                            </PopoverBody>
                                            <PopoverFooter display='flex' justifyContent='flex-end'>
                                                <Button onClick={() => {
                                                    onClose()
                                                    deleteAb(clientAb.id)
                                                }} size={'sm'} colorScheme='red'>{t("Supprimer")}</Button>
                                            </PopoverFooter>
                                        </PopoverContent>
                                    </>)}
                            </Popover>
                            <Button colorScheme='blue'
                                onClick={() => UpdateAbonnement(clientAb.id)}
                            >
                                {t("Modifier")}
                            </Button> */}

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }
        </Box>

    )
}

export default GestClients
