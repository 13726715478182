import React, { useState, useEffect, useCallback } from 'react';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import MyPagination from "../../components/MyPagination";
import { useStateContext } from "../../context/ContextProvider";
// import { LiaUserEditSolid } from 'react-icons/lia';

import {
    IconButton,
    Flex,
    Box,
    Button,
    Stack,
    Table,
    Thead,
    InputGroup,
    InputLeftElement,
    Tbody,
    Tr,
    Th,
    Select,
    Avatar,
    Td,
    Input,
    useToast,
    TableContainer,
    useColorModeValue,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Text,
    AvatarGroup,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    Skeleton,
    SkeletonCircle,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    Textarea,
} from '@chakra-ui/react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { PiPlusBold } from 'react-icons/pi';

export const GestServices = () => {
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState(true)
    const [checkLengthData, setCheckLengthData] = useState(false)
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [paginationData, setPaginationData] = useState()
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedCat, setSelectedCat] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedEtat, setSelectedEtat] = useState(null);

    //search variable
    const [searchTerm, setSearchTerm] = useState('');
    const [service, setService] = useState();

    const navigate = useNavigate()

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };


    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    // current user
    const { user } = useStateContext();


    const [deleteId, setDeleteId] = useState(null);
    const cancelRef = React.useRef()

    //Partenaires data
    const [data, setData] = useState([]);
    const [dataImage, setDataImage] = useState([]);

    //get the partenaire data
    useEffect(() => {
        // setLoadingData(true)

        axiosClient.get(`/services/getAllServices/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}&category=${selectedCat}&type=${selectedType}&etat=${selectedEtat}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
        axiosClient.get('/service-images/')
            .then((response) => {

                setDataImage(response.data);
            })
            .catch((error) => {
                // console.error(error);
            });
    }, [pageSize, currentPage, searchTerm, selectedCat,selectedType,selectedEtat]);

    const [message, setMessage] = useState(false)

    // delete partenaire
    const handleDelete = (id = deleteId) => {
        if (!id) return;
        axiosClient.delete(`/services/${id}/`)
            .then((response) => {
                setData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("le service est supprimé avec succès"),
                    status: 'success',
                    variant:'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }

    //search method
    // const filteredData = useCallback(() => {
    //     return data.filter((row) => {

    //         return (
    //             row.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             row.price.toString().toLowerCase().includes(searchTerm.toLowerCase())

    //         );
    //     });
    // }, [data, searchTerm, user.id]);






    return (
        <Box mt="5px" >
            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span >Services</span></Text>
                    <Button onClick={() => navigate('/ajouter-service')} leftIcon={<PiPlusBold color='#49b1cc' />} colorScheme='gray' variant='solid'>
                        {t("Ajouter un service")}
                    </Button>
                </Box>
            </Flex>

            <Flex
                align={'center'}
                justify={'center'}
            >
                <Stack
                    w={'full'}
                    maxW='full'
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'lg'}
                    p={6}
                    my={5}>
                    {/* Search input */}
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={4}
                        direction={{ base: 'column', lg: 'row' }}
                    >
                        <Flex direction={{ base: 'column', sm: 'row' }} mb={{base:0,lg:4}} w={{base:'100%',lg:"70%"}} gap={3}>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', md: '40%', xl: "30%" }}  value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                <option value="">{t("tous les types")}</option>
                                <option value="Vente">{t("Vente")}</option>
                                <option value="Location">{t("Location")}</option>
                            </Select>
                            <Select borderRadius="5" size="sm" fontSize={{ base: 13, lg: 16 }} w={{ base: '100%', md: '40%', xl: "30%" }}  value={selectedCat} onChange={(e) => setSelectedCat(e.target.value)}>
                                <option value="">{t("Toutes les catégories")}</option>
                                <option value="Appartement">{t("Appartement")}</option>
                                <option value="Villa">{t("Villa")}</option>
                                <option value="Terrain">{t("Terrain")} </option>
                                <option value="Commerce">{t("Commerce")}</option>
                            </Select>
                            <Select w={{base:"100%",md: '20%',xl:"20%"}} size="sm"   borderRadius="5"  fontSize={{ base: 13, lg: 16 }} value={selectedEtat} onChange={(e) => setSelectedEtat(e.target.value === '' ? null : e.target.value === 'true')}>
                                    <option value="">{t("Tout")}</option>
                                    <option value="true">{t("Activé")}</option>
                                    <option value="false">{t("Désactivé")}</option>
                                </Select>
                        </Flex>


                        <InputGroup size="sm" w={{ base: '100%', lg: "40%", xl: "30%" }}>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<SearchIcon color='gray.300' />}
                            />
                            <Input
                                borderRadius="5"
                                placeholder={t("Recherche...")}
                                value={searchTerm}
                                onChange={(e) => {
                                    setCurrentPage(0)
                                    setSearchTerm(e.target.value)
                                }}
                                mb={4}
                                sx={{
                                    marginLeft: 'auto',
                                }}
                            />  </InputGroup>

                    </Flex>
                    <TableContainer>
                        <Table size="sm" variant='striped' colorScheme="gray">
                            <Thead size="sm">
                                <Tr fontSize="12">
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Image</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Titre")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Description</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">Type</Th>

                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t('Catégorie')}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Prix")}</Th>
                                    <Th fontFamily='Plus Jakarta Display' fontSize="12">{t("Date Création")}</Th>
                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                        <Th fontSize="12">Options</Th>
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {loadingData &&
                                    <Tr>
                                        <Td><SkeletonCircle size='10' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                        <Td> <Skeleton height='20px' /></Td>
                                    </Tr>}

                                {(paginationData?.length !== 0) &&
                                    data.map((row,key) => (<Tr key={key}>
                                        {/* <Td > <Avatar borderRadius='15px' size='md' src={row.image} /></Td> */}
                                        <Td> <AvatarGroup onClick={() => {
                                            onOpen()
                                            setService(row)
                                        }}
                                            borderRadius={'xl'} size='md' max={2}>
                                            {row.images.map((img,key) => (
                                                <Avatar key={key} borderRadius={'xl'} name='Ryan Florence' src={img.image} />
                                            ))}

                                        </AvatarGroup></Td>
                                        <Td>{row.title}</Td>
                                        <Td >
                                            <Flex flexDirection='row' gap={1}>
                                                {row.description.length > 0 ?
                                                    <>
                                                        <Text>{row.description.substring(0, 30)} {row.description.length > 30 && <>...</>}</Text>
                                                        <Popover>
                                                            <PopoverTrigger >
                                                                <Text cursor='pointer' _hover={{ color: 'lightgray' }}> {row.description.length > 30 && <>{t("(voir plus)")}</>} </Text>
                                                            </PopoverTrigger>
                                                            <PopoverContent >
                                                                <PopoverArrow />
                                                                {/* <PopoverCloseButton /> */}
                                                                <PopoverBody>
                                                                    <Textarea readOnly border={"none"} resize='none' >
                                                                        {row.description}
                                                                    </Textarea>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </>

                                                    :
                                                    <Text color={'gray.400'}>{t("Non disponible")}</Text>



                                                }

                                            </Flex>

                                        </Td>
                                        <Td>{t(`${row.type}`)} </Td>

                                        <Td>{row.category}</Td>
                                        <Td>{row.price} {row.currency==="usd" ? "$" : row.currency==="eur" ? "€" : row.currency==="mad" ? "MAD" :""}</Td>


                                        <Td>{new Date(row.created_at).toLocaleDateString('fr-FR')}</Td>

                                        <Td>

                                            <IconButton
                                                size="sm"
                                                onClick={() => navigate(`/modifier-service`, {
                                                    state: {
                                                        idService: row.id
                                                    }
                                                })}
                                                mr="5px"
                                                variant={'outline'}
                                                colorScheme="whatsapp"
                                                icon={<EditIcon />}>
                                            </IconButton>

                                            {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&

                                                <IconButton
                                                    size="sm"
                                                    onClick={() => {
                                                        setDeleteId(row.id);
                                                        onOpenAlert();

                                                    }}
                                                    mr="5px"
                                                    colorScheme="red"
                                                    icon={<DeleteIcon />}>
                                                </IconButton>}
                                        </Td>

                                    </Tr>
                                    ))}
                                {(((searchTerm || selectedEtat || selectedCat || selectedType ) && paginationData?.length === 0) || (checkLengthData)) && (
                                    <Tr>
                                        <Td colSpan={11}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </Table>

                    </TableContainer>
                    {/* Pagination */}
                    <Flex flexDirection={{ base: 'column', lg: "row" }} justify="space-between" align="center" w="100%">
                        <Box flex="1">
                            <MyPagination
                                paginationData={paginationData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                PAGE_SIZE={pageSize}
                            />
                        </Box>

                        <Select w="70px" value={pageSize} onChange={handlePageSizeChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            {/* Add more options as needed */}
                        </Select>
                    </Flex>
                </Stack>
            </Flex>
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>


                            <Text>
                                {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                            </Text>

                        </AlertDialogBody>



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage(false)
                                onCloseAlert()
                            }}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(deleteId)} colorScheme='red' ml={3} isDisabled={message}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen} >
                <DrawerOverlay />
                <DrawerContent backgroundColor={useColorModeValue('#ffffffe8', '#292b34')} >
                    <DrawerBody >
                        <Flex gap={5} justify={{ base: 'none', md: "center" }} >
                            {dataImage.filter((e) => e.service === service?.id).map((img) => (
                                <Avatar h={"150px"} w={"150px"} borderRadius={'xl'} name='Ryan Florence' src={img.image} />
                            ))}
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

        </Box>

    )
}

export default GestServices