
import {
	Box,
	Button,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Grid,
	Icon,
	Progress,
	SimpleGrid,
	Spacer,
	Stack,
	Stat,
	StatHelpText,
	StatLabel,
	StatNumber,
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr, Card, CardBody, CardHeader, CardFooter, Select, HStack, Td, Skeleton,
	Image
} from '@chakra-ui/react';
import medusa from '../../assets/images/About.jpg';
// Custom components
// import Card from '../../components/Card/Card.js';
// import CardBody from '../../components/Card/CardBody.js';
// import CardHeader from '../../components/Card/CardHeader.js';
import BarChart from '../../components/Charts/BarChart';
import LineChart from '../../components/Charts/LineChart';
import IconBox from '../../components/Icons/IconBox';
// Icons
import { CartIcon, DocumentIcon, GlobeIcon, RocketIcon, StatsIcon, WalletIcon } from '../../components/Icons/Icons.js';
import DashboardTableRow from '../../components/Tables/DashboardTableRow';
import TimelineRow from '../../components/Tables/TimelineRow';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiHappy, BiSolidDiscount } from 'react-icons/bi';
import { BsArrowRight, BsPersonVcardFill } from 'react-icons/bs';
import { IoCheckmarkDoneCircleSharp, IoDocument, IoEllipsisHorizontal } from 'react-icons/io5';
import { FaUserGroup } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import dashImg from '../../assets/images/dashImg.jpg'
import emptystats from '../../assets/images/stats.png'
// Data
import {
	barChartDataDashboard,
	barChartOptionsDashboard,
	lineChartDataDashboard,
	lineChartOptionsDashboard
} from '../../variables/charts';
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom';

import { FaStar, FaUserTie } from 'react-icons/fa';
import DonutChart from '../../components/Charts/DonutChart';
import ReactApexChart from 'react-apexcharts';
import MyPaginantion from '../../components/MyPagination';
import MyPagination from '../../components/MyPagination';
import { MdDiscount, MdOutlineCurrencyExchange } from 'react-icons/md';

// import { dashboardTableData, timelineData } from 'variables/general';

export default function Dashboard() {
	const { t } = useTranslation();

	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth() + 1
	const [loadingData, setLoadingData] = useState(true)
	const [checkLengthData, setCheckLengthData] = useState(false)
	const [paginationData, setPaginationData] = useState()

	const { user } = useStateContext();
	const [clients, setClients] = useState([]);
	const [clientsNewCount, setClientsNewCount] = useState();

	const [transactionsCountOld, setTransactionsCountOld] = useState();
	const [transactionsCountNew, setTransactionsCountNew] = useState();
	const [subscriptionCountOld, setSubscriptionCountOld] = useState();
	const [subscriptionCountNew, setSubscriptionCountNew] = useState();

	const [partenaires, setPartenaires] = useState([]);
	const [partenairesNewCount, setPartenairesNewCount] = useState();

	const [requestsCount, setRequestsCount] = useState();

	const [listClients, setListClients] = useState([]);
	const [listPartenaires, setListPartenaires] = useState([]);
	const [partenairesNames, setPartenairesNames] = useState([]);
	const [listOffres, setListOffres] = useState([]);
	const [listPays, setListPays] = useState([]);

	const [listRatings, setListRatings] = useState([]);
	const [selectedP, setSelectedP] = useState([]);
	const navigate = useNavigate()
	const [plainte, setPlainte] = useState('')
	const [selectedMonth, setSelectedMonth] = useState('');
	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [defaultDonut, setDefaultDonut] = useState('years')

	const [demandes, setDemandes] = useState([])

	const generateYearOptions = () => {
		const yearOptions = [];
		for (let year = 2022; year <= currentYear; year++) {
			yearOptions.push(
				<option key={year} value={year}>
					{year}
				</option>
			);
		}
		return yearOptions;
	};


	// function getClientName(id) {
	// 	let client = listClients.find(e=>e.id===id)
	// 	return `${client?.first_name} ${client?.last_name}`;
	// }

	// function getPartenaireName(id) {
	// 	let partenaire = listPartenaires.find(e=>e.id===id)
	// 	return `${partenaire.first_name} ${partenaire.last_name}`;
	// }

	// function getOffreName(id) {
	// 	let offre=listOffres.find(e=>e.id===id)
	// 	return offre?.labelle;
	// }

	// function getPaysName(id) {
	// 	let offre=listOffres.find(e=>e.id===id)
	// 	let pays=listPays.find(e=>e.id===offre?.pays)
	// 	return pays?.nom_pays;
	// }

	const fetchClients = () => {
		axiosClient.get('clients/')
			.then((response) => {
				// setClients(response.data.length);
				setListClients(response.data)
			})
			.catch((error) => {
				// console.log(error);
			});
		axiosClient.get('count_clients/')
			.then((response) => {
				setClients(response.data.old_count);
				setClientsNewCount(response.data.new_count);
			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const fetchPartenaires = () => {
		axiosClient.get('partenaires/')
			.then((response) => {
				// setPartenaires(response.data.length);
				setListPartenaires(response.data)
			})
			.catch((error) => {
				// console.log(error);
			});

		axiosClient.get('partenaires/get_partners_simple_data/')
			.then((response) => {
				// setPartenaires(response.data.length);
				setPartenairesNames(response.data)
			})
			.catch((error) => {
				// console.log(error);
			});
		axiosClient.get('count_partners/')
			.then((response) => {
				setPartenaires(response.data.old_count);
				setPartenairesNewCount(response.data.new_count);

			})
			.catch((error) => {
				// console.log(error);
			});


	}

	const fetchOffres = () => {
		axiosClient.get('offres/')
			.then((response) => {
				setListOffres(response.data)
			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const fetchPays = () => {
		axiosClient.get('pays/')
			.then((response) => {
				setListPays(response.data)
			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const fetchRatings = () => {
		axiosClient.get('ratings/')
			.then((response) => {
				setListRatings(response.data)
			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const fetchPlaintes = () => {
		axiosClient.get('tickets/')
			.then((response) => {
				setPlainte(response.data);
			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const fetchDemandes = () => {
		// axiosClient.get('demandes/fetch_demandes')
		// 	.then((response) => {
		// 		setDemandes(response.data.demandes);
		// 		console.log("hola",response.data.demandes);
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);

		// 	});
		axiosClient.get(`/demandes/fetch_demandes/?page_size=${pageSize}&page=${currentPage + 1}`)
			.then((response) => {
				let filteredPartners = response.data.results
				setPaginationData(response.data);

				if (filteredPartners && filteredPartners.length === 0) {
					setCheckLengthData(true)
				}
				else {
					setDemandes(filteredPartners);
				}
				setLoadingData(false)
			})
			.catch((error) => {
				// console.error(error);
				setLoadingData(false)
			});

		axiosClient.get('count_transactions/')
			.then((response) => {
				setTransactionsCountOld(response.data.old_count);
				setTransactionsCountNew(response.data.new_count);
			})
			.catch((error) => {
				// console.log(error);
			});
		axiosClient.get('count_subscriptions/')
			.then((response) => {
				setSubscriptionCountOld(response.data.old_count);
				setSubscriptionCountNew(response.data.new_count);

			})
			.catch((error) => {
				// console.log(error);
			});
	}

	const fetchDemandesInscription = () => {

		axiosClient.get('count_pending_requests/')
			.then((response) => {
				setRequestsCount(response.data.count);
			})
			.catch((error) => {
				// console.log(error);
			});
	}



	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
		setDefaultDonut('year')
		if (event.target.value === "0") {
			setDefaultDonut('years')
			setSelectedYear(currentYear)
		}
	};
	// // plaintes par jours chart data
	// const [plainteByDayChartData, setPlainteByDayChartData] = useState({
	// 	series: [],
	// 	options: {
	// 		chart: {
	// 			type: 'donut',
	// 		},
	// 		labels: [],
	// 	},
	// });

	// plaintes par mois chart data
	const [plainteByMonthChartData, setPlainteByMonthChartData] = useState({
		series: [],
		options: {
			chart: {
				type: 'donut',
			},
			labels: [],
		},
	});

	const [topFiveData, setTopFiveData] = useState({
		series: [],
		options: {
			chart: {
				height: 100,
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					dataLabels: {
						name: {
							fontSize: '22px',
						},
						value: {
							fontSize: '16px',
						},
						total: {
							show: true,
							label: 'Total',
							formatter: function (w) {
								return 0
							}
						}
					}
				}
			},
			labels: [],
		},
	});

	// plaintes par année chart data
	const [plainteByYearChartData, setPlainteByYearChartData] = useState({
		series: [],
		options: {
			chart: {
				type: 'donut',
			},
			labels: [],
		},
	});

	// afficher le nombre de plainte par jours
	// const fetchPlainteParJours = () => {
	// 	// Helper function to get the day name based on day index (Sunday is 0, Monday is 1, etc.)
	// 	const getDayName = (dayIndex) => {
	// 		const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Venderedi', 'Samedi'];
	// 		return daysOfWeek[dayIndex - 1];
	// 	};
	// 	axiosClient.get('tickets/plaintes_per_day/')
	// 		.then((response) => {
	// 			const data = response.data;

	// 			const count = data.map(item => item.count);
	// 			const days = data.map(item => {
	// 				const dayIndex = item.day_of_week;
	// 				const dayName = getDayName(dayIndex);
	// 				return dayName;
	// 			});
	// 			setPlainteByDayChartData({
	// 				series: count,
	// 				options: {
	// 					chart: {
	// 						type: 'donut',
	// 					},
	// 					labels: days,
	// 					legend: { show: false }
	// 				},
	// 			});

	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// }

	// afficher le nombre de plainte par mois
	const fetchPlainteParMois = () => {

		// Helper function to get the month name based on month index (January is 0, February is 1, etc.)
		const getMonthName = (monthIndex) => {
			const monthNames = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
			return monthNames[monthIndex];
		};

		axiosClient.get(`tickets/plaintes_per_month/?year=${selectedYear}`)
			.then((response) => {
				const data = response.data;
				const count = data.map(item => item.count);
				const month = data.map(item => {
					const monthIndex = item.month ? new Date(item.month).getMonth() : -1;
					const monthName = getMonthName(monthIndex);
					return monthName;
				});
				setPlainteByMonthChartData({
					series: count,
					options: {
						chart: {
							type: 'donut',
						},
						labels: month,
						legend: { show: false }
					},
				});

			})
			.catch((error) => {
				// console.log(error);
			});
	}

	// afficher le nombre de plainte par jours
	const fetchPlainteParAnnee = () => {
		axiosClient.get('tickets/plaintes_per_year/')
			.then((response) => {
				const data = response.data;

				const years = data.map(item => item.year);
				const counts = data.map(item => item.count);
				setPlainteByYearChartData({
					series: counts,
					options: {
						chart: {
							type: 'donut',
						},
						labels: years,
						legend: { show: false }
					},
				});

			})
			.catch((error) => {
				// console.log(error);
			});
	}
	const [topPartenaires, setTopPartenaires] = useState([]);
	// useEffect(() => {
	// 	axiosClient.get('top_partenaires/')
	// 		.then((data) => {
	// 			setTopPartenaires(data);
	// 			console.log('mydaaaataaaa',data)
	// 		});
	// }, []);



	const fetchTopPartenaire = () => {
		axiosClient.get('top_partenaires/')
			.then((response) => {

				setTopPartenaires(response.data);
				let data = response.data
				let element = [];
				let partenaire = [];
				let total = 0;

				for (let index = 0; index < data.length; index++) {
					element.push(data[index][2]);
					partenaire.push(data[index][0]);
					total += data[index][1];
				}
				const roundedElements = element.map((num) => num.toFixed(2));


				setTopFiveData({
					series: roundedElements,
					options: {
						chart: {
							height: 100,
							type: 'radialBar',
						},
						plotOptions: {
							radialBar: {
								dataLabels: {
									name: {
										fontSize: '22px',
									},
									value: {
										fontSize: '16px',
									},
									total: {
										show: true,
										label: 'Total',
										formatter: function () {
											return (total)
										}

									}
								}
							}
						},
						labels: partenaire
					},

				});

			})
			.catch((error) => {
				// console.log(error);
			});
	}
	// useEffect(() => {
	// 	if (listRatings.length === 0) {
	// 	  return;
	// 	}
	// 	const partenaireRatings = listRatings.filter((rating) => rating.partenaire === selectedP);
	// 	const totalRating = listRatings.filter((rating) => rating.partenaire === selectedP).reduce((sum, rating) => sum + rating.rating, 0);
	// 	const average = totalRating / listRatings.filter((rating) => rating.partenaire === selectedP).length;
	// 	setAverageRating(average);
	//   }, [listRatings, selectedP]);


	const handlePSelection = (e) => {
		setSelectedP(e.target.value)
	};
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(0);

	////////////////////////////////the size of the table//////////////////////////////
	const PAGE_SIZE = 3;
	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const handlePageSizeChange = (event) => {
		const newSize = parseInt(event.target.value, 10);
		setPageSize(newSize);
		setCurrentPage(0);
	};
	//////////////////////////////////////////////////////////////////////////////////

	//search method
	// const filteredData = useCallback(() => {
	//     return demandes.filter((row) => {
	//         return row.label.toLowerCase().includes(searchTerm.toLowerCase()) 
	// 		|| row.client.toLowerCase().includes(searchTerm.toLowerCase());
	//     });
	// }, [ searchTerm]);

	const avgRating = (id) => {

		const partenaireRatings = listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire"));
		if (partenaireRatings.length === 0) {
			return;
		}

		const totalRating = listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire")).reduce((sum, rating) => sum + rating.rating, 0);
		const averageRating = totalRating / listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire")).length; return (averageRating * 100) / 5;
	}
	const avgStars = (id) => {

		const partenaireRatings = listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire"))
		if (partenaireRatings.length === 0) {
			return;
		}

		const totalRating = listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire")).reduce((sum, rating) => sum + rating.rating, 0);
		const averageRating = totalRating / listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire")).length;

		return averageRating;
	}

	const countPerson = (id) => {
		return listRatings.filter((rating) => (rating.dest_id === id) && (rating.label === "Partenaire"))
	}
	useEffect(() => {
		fetchClients()
		fetchPartenaires()
		fetchRatings()
		fetchPlainteParAnnee()
		fetchPlainteParMois()
		// fetchPlainteParJours()
		fetchTopPartenaire()
		fetchPlaintes()
		fetchDemandes()
		fetchOffres()
		fetchPays()
		fetchDemandesInscription()

	}, [selectedP, selectedYear, searchTerm, pageSize, currentPage]);

	return (
		<Flex flexDirection='column' >
			<Flex>
				<Box w="90%" pt='12px' mb={8}>
					<Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} > {t("Accueil")}</span>  / <span >{t("Tableau de bord")}</span></Text>
				</Box>
			</Flex>
			<SimpleGrid columns={{ sm: 1, md: 2, lg: 3, xl: 5 }} spacing='15px'>
				{/* MiniStatistics Card */}
				<Card bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'>
					<CardBody p={4}>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nombre de clients")}
								</StatLabel>
								<Flex>
									<StatNumber fontSize='lg' color='#fff'>
										{clients > 0 && clients}
									</StatNumber>
									{clientsNewCount > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {clientsNewCount}
										</StatHelpText>}

								</Flex>
							</Stat>
							<IconBox  h={'40px'} w={'40px'} bg='brand.200'>
								<FaUserGroup size={"25px"} color='#fff' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				{/* MiniStatistics Card */}
				<Card bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)' minH='83px'>
					<CardBody p={4}>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nombre de partenaires")}
								</StatLabel>
								<Flex>
									<StatNumber fontSize='lg' color='#fff'>
										{partenaires > 0 && partenaires}
									</StatNumber>
									{partenairesNewCount > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {partenairesNewCount}
										</StatHelpText>}

								</Flex>
							</Stat>
							<IconBox h={'40px'} w={'40px'} bg='brand.200'>
								<FaUserTie size={"25px"} color='#fff' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				{/* MiniStatistics Card */}
				<Card bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'>
					<CardBody p={4}>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nouvelles transactions")}
								</StatLabel>
								<Flex>
									<StatNumber fontSize='lg' color='#fff'>
										{(transactionsCountOld > 0 || transactionsCountNew===0) && transactionsCountOld}
									</StatNumber>
									{transactionsCountNew > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {transactionsCountNew}
										</StatHelpText>}

								</Flex>
							</Stat>
							<IconBox h={'40px'} w={'40px'} bg='brand.200'>
								<IoDocument size={"25px"} color='#fff' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				{/* MiniStatistics Card */}
				<Card bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'>
					<CardBody p={4}>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Abonnements")}
								</StatLabel>
								<Flex>
									<StatNumber fontSize='lg' color='#fff'>
										{(subscriptionCountOld > 0 || subscriptionCountNew===0) && subscriptionCountOld}
										
									</StatNumber>
									{subscriptionCountNew > 0 &&
										<StatHelpText
											alignSelf='flex-end'
											justifySelf='flex-end'
											m='0px'
											color='green.400'
											fontWeight='bold'
											ps='3px'
											fontSize='md'>
											+ {subscriptionCountNew}
										</StatHelpText>}

								</Flex>
							</Stat>
							<IconBox  h={'40px'} w={'40px'} bg='brand.200'>
								<MdOutlineCurrencyExchange  size={"28px"} color='#fff' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
				<Card bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'>
					<CardBody p={4}>
						<Flex flexDirection='row' align='center' justify='center' w='100%'>
							<Stat me='auto'>
								<StatLabel fontSize='sm' color='gray.400' fontWeight='bold' pb='2px'>
									{t("Nouvelles demandes")}
								</StatLabel>
								<Flex>
									<StatNumber fontSize='lg' color='#fff' fontWeight='bold'>
										{requestsCount}
									</StatNumber>
									{/* <StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										m='0px'
										color='green.400'
										fontWeight='bold'
										ps='3px'
										fontSize='md'>
										+8%
									</StatHelpText> */}
								</Flex>
							</Stat>
							<IconBox  h={'40px'} w={'40px'} bg='brand.200'>
								<BsPersonVcardFill size={"25px"} color='#fff' />
							</IconBox>
						</Flex>
					</CardBody>
				</Card>
			</SimpleGrid>
			<Flex flexDirection={"column"} my="26px" gap="18px">
				{/* Welcome Card */}
				<Card
				// h={200}
					p='0px'
					// gridArea={{ md: '1 / 1 / 2 / 3', '2xl': 'auto' }}
					bgImage={dashImg}
					bgSize='cover'
					
					bgPosition='50%'>
					<CardBody h='100%'>
						<Flex flexDirection={{ sm: 'column', lg: 'row' }} w='100%' h='100%'>
							<Flex flexDirection='column' h='100%' p='22px' minW='60%' lineHeight='1.6'>
								<Text fontSize='md' color='blue.800' fontWeight='bold'>
									{t("Bienvenu")},
								</Text>
								<Text fontSize='28px' color='blue.900' fontWeight='bold' mb='18px'>
									{user?.first_name} {user?.last_name}
								</Text>

								<Spacer />
								{/* <Flex align='center'>
									<Button
										p='0px'
										variant='no-hover'
										bg='transparent'
										my={{ sm: '1.5rem', lg: '0px' }}>
										<Text
											fontSize='sm'
											color='#fff'
											fontWeight='bold'
											cursor='pointer'
											transition='all .3s ease'
											my={{ sm: '1.5rem', lg: '0px' }}
											_hover={{ me: '4px' }}>
											Tab to record
										</Text>
										<Icon
											as={BsArrowRight}
											w='20px'
											h='20px'
											color='#fff'
											fontSize='2xl'
											transition='all .3s ease'
											mx='.3rem'
											cursor='pointer'
											pt='4px'
											_hover={{ transform: 'translateX(20%)' }}
										/>
									</Button>
								</Flex> */}
							</Flex>
						</Flex>
					</CardBody>
				</Card>
				<Flex
					gap={5}
					w={"100%"}
					flexDirection={{ base: "column", xl: "row" }}
				>
					<Card w={{ base: "100%", xl: "50%" }} p="16px" >
						<CardHeader mb='10px'>
							<Flex justifyContent={'space-between'} direction={{ base: 'column', md: "row" }}>
								<Text fontSize='lg' fontWeight='bold' mb='4px'>
									{t("Taux de satisfaction")}
								</Text>
								<Select
									w={{ base: '100%', md: "50%" }}
									size={'sm'}
									rounded={"lg"}
									placeholder={t("Selectionez un partenaire")}
									onChange={handlePSelection}
								>
									{partenairesNames?.map((prt) => (
										<option key={prt.id} value={prt.id}>
											{prt.company_name}
										</option>
									))}
								</Select>
							</Flex>
						</CardHeader>
						<Flex w={"100%"} direction={{ base: 'column', xl: 'row' }} p={5}>
							<Flex direction='column' me={{ md: '6px', lg: '30px' }} mb={{ sm: '16px', md: '0px' }}>
								<Flex
									direction='column'
									p='22px'
									pe={{ sm: '22e', md: '8px', lg: '22px' }}
									minW={{ sm: '220px', md: '140px', lg: '220px' }}
									bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
									borderRadius='20px'
									mb='20px'>
									<Text color='gray.400' fontSize='sm' mb='4px'>
										Clients
									</Text>
									<Text color='#fff' fontSize='lg' fontWeight='bold'>
										{/* {listRatings.filter((rating) => rating.partenaire === selectedP)}  */}
										{countPerson(parseInt(selectedP)).length} 	{t("personnes")}
									</Text>
								</Flex>
								<Flex
									direction='column'
									p='22px'
									pe={{ sm: '22px', md: '8px', lg: '22px' }}
									minW={{ sm: '170px', md: '140px', lg: '170px' }}
									bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
									borderRadius='20px'>
									<Text color='gray.400' fontSize='sm' mb='4px'>
										{t("Moyenne des étoiles")}
									</Text>
									<HStack spacing={"2px"}>
										{[...Array(5)].map((star, index) => {

											return (
												<Box
													as="label"
													key={index}
													color={index < avgStars(parseInt(selectedP)) ? "#ffc107" : "#e4e5e9"}
												>
													<FaStar
														cursor={"pointer"}
														size={20}
														transition="color 200ms"
													/>
												</Box>
											);
										})}
									</HStack>

								</Flex>
							</Flex>


							<Flex justify={'center'} w={"100%"} mx={{ sm: 'auto', md: '0px' }}>
								<CircularProgress
									size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
									value={avgRating(parseInt(selectedP))}
									thickness={6}
									color={(avgRating(parseInt(selectedP)) > '70') ? "#05CD99" : avgRating(parseInt(selectedP)) > 40 ? "#ffd83a" : "#ff6767"}
									variant='vision'>
									<CircularProgressLabel>
										<Flex direction='column' justify='center' align='center'>
											<Text color='gray.400' fontSize='sm'>
												Ratings
											</Text>
											<Text

												fontSize={{ md: '36px', lg: '35px' }}
												fontWeight='bold'
												mb='4px'>
												{avgRating(parseInt(selectedP))?.toFixed(1)}%
											</Text>

										</Flex>
									</CircularProgressLabel>
								</CircularProgress>
							</Flex>
						</Flex>
						{/* <Flex direction='column' justify='center' align='center'>

						<Box>
							<CircularProgress
								size={200}
								value={avgRating(parseInt(selectedP))}
								thickness={6}
								color='#582CFF'
								variant='vision'
								rounded>
								<CircularProgressLabel >

									<IconBox mb='30px' mx='auto' bg='brand.200' borderRadius='50%' w='48px' h='48px'>
										<Icon as={BiHappy} w='30px' h='30px' />
									</IconBox>
								</CircularProgressLabel>
							</CircularProgress>
						</Box>
						<Stack
							direction='row'
							spacing={{ sm: '42px', md: '68px' }}
							justify='center'
							maxW={{ sm: '270px', md: '300px', lg: '100%' }}
							mx={{ sm: 'auto', md: '0px' }}
							p='18px 22px'
							bg='linear-gradient(126.97deg, rgb(6, 11, 40) 28.26%, rgba(10, 14, 35) 91.2%)'
							borderRadius='20px'
							position='absolute'
							bottom='5%'
							zIndex='1'>
							<Text fontSize='xs' color='gray.400'>
								0%
							</Text>
							<Flex direction='column' align='center' minW='80px'>
								<Text color='#fff' fontSize='28px' fontWeight='bold'>
								{avgRating(parseInt(selectedP))}%
								</Text>
								<Text fontSize='xs' color='gray.400'>
									Based on tickets
								</Text>
							</Flex>
							<Text fontSize='xs' color='gray.400'>
								100%
							</Text>
						</Stack>
					</Flex> */}
					</Card>
					{/* Referral Tracking */}
					<Card w={{ base: "100%", xl: "50%" }} p="16px" >
						<Flex direction='column' p={5}>
							<Flex justify='space-between' align='center' mb='40px'>
								<Text fontSize='lg' fontWeight='bold'>
									{t("Statistiques des tickets")}
								</Text>
								{/* <Select size={'sm'} width={150} value={selectedMonth} onChange={(event) => setSelectedMonth(event.target.value)}>
									<option value="0">Tous les mois</option>
									{months.map((month) => (
										<option key={month.value} value={month.value}>
											{month.name}
										</option>
									))}
								</Select> */}
								<Select rounded={'lg'} size={'sm'} width={150} defaultValue={selectedYear} onChange={handleYearChange}>
									<option value="0">	{t("toutes les années")}</option>
									{generateYearOptions()}

								</Select>
							</Flex>
							<Flex  h='100%' w={"100%"} direction={{ sm: 'row', md: "column", xl: 'row' }} justify={'center'} alignItems={'center'}>

								{/* Tickets par jours chart */}
								{/* <Box width={{ base: "100%", md: "33%" }}>
									<DonutChart title={"Tickets par jours"} data={plainteByDayChartData} />
								</Box> */}
								{/* Tickets par mois chart */}
								{/* <Box width={{ base: "100%", md: "33%" }}>
									<DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
								</Box> */}
								{/* Tickets par anneé chart */}
								{/* <Box>
									<DonutChart title={"Tickets par année"} data={plainteByMonthChartData} />
								</Box> */}

								{plainteByYearChartData.series.length === 0 ?
									<Flex flexDir={"column"} align={"center"} justify={"center"} h={'100%'}>
										<Text>{t("Rien de disponible pour l’instant.")}</Text>
										<Image opacity={0.5} src={emptystats} w={"150px"} />
									</Flex> :

									<>
										{defaultDonut === "year" &&
											<>
												<Box   >
													<DonutChart title={"Tickets par année"} data={plainteByYearChartData} />
												</Box>
												<Box >
													<DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
												</Box>
											</>
										}
										{defaultDonut === "years" &&
											<>	
												<Box >
													<DonutChart title={"Tickets par mois"} data={plainteByYearChartData} />
												</Box>
												<Box >
													<DonutChart title={"Tickets par mois"} data={plainteByMonthChartData} />
												</Box>
											</>

										}</>
								}

								{/* <div style={{
									background:"yellow",
									width:'100%'
								}}>
									test
								</div> */}








							</Flex>
						</Flex>
					</Card>
				</Flex>
				{/* Satisfaction Rate */}

			</Flex>

			{/* <Grid
				templateColumns={{ sm: '1fr', lg: '1.7fr 1.3fr' }}
				maxW={{ sm: '100%', md: '100%' }}
				gap='24px'
				mb='24px'>
				<Card p='28px 0px 0px 0px'>
					<CardHeader mb='20px' ps='22px'>
						<Flex direction='column' alignSelf='flex-start'>
							<Text fontSize='lg' fontWeight='bold' mb='6px'>
								Sales Overview
							</Text>
							<Text fontSize='md' fontWeight='medium' color='gray.400'>
								<Text as='span' color='green.400' fontWeight='bold'>
									(+5%) more
								</Text>{' '}
								in 2021
							</Text>
						</Flex>
					</CardHeader>
					<Box w='100%' minH={{ sm: '300px' }}>
						<LineChart
							lineChartData={lineChartDataDashboard}
							lineChartOptions={lineChartOptionsDashboard}
						/>
					</Box>
				</Card>
				<Card p='16px'>
					<CardBody>
						<Flex direction='column' w='100%'>
							<Box
								bg='linear-gradient(126.97deg, #060C29 28.26%, rgba(4, 12, 48, 0.5) 91.2%)'
								borderRadius='20px'
								display={{ sm: 'flex', md: 'block' }}
								justify={{ sm: 'center', md: 'flex-start' }}
								align={{ sm: 'center', md: 'flex-start' }}
								minH={{ sm: '180px', md: '220px' }}
								p={{ sm: '0px', md: '22px' }}>
								<BarChart
									barChartOptions={barChartOptionsDashboard}
									barChartData={barChartDataDashboard}
								/>
							</Box>
							<Flex direction='column' mt='24px' mb='36px' alignSelf='flex-start'>
								<Text fontSize='lg' fontWeight='bold' mb='6px'>
									Active Users
								</Text>
								<Text fontSize='md' fontWeight='medium' color='gray.400'>
									<Text as='span' color='green.400' fontWeight='bold'>
										(+23%)
									</Text>{' '}
									than last week
								</Text>
							</Flex>
							<SimpleGrid gap={{ sm: '12px' }} columns={4}>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox  h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<WalletIcon h={'15px'} w={'15px'} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Users
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										32,984
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={20} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<RocketIcon h={'15px'} w={'15px'} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Clicks
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										2.42m
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={90} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<CartIcon h={'15px'} w={'15px'} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Sales
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										2,400$
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={30} />
								</Flex>
								<Flex direction='column'>
									<Flex alignItems='center'>
										<IconBox as='box' h={'30px'} w={'30px'} bg='brand.200' me='6px'>
											<StatsIcon h={'15px'} w={'15px'} />
										</IconBox>
										<Text fontSize='sm' color='gray.400'>
											Items
										</Text>
									</Flex>
									<Text
										fontSize={{ sm: 'md', lg: 'lg' }}

										fontWeight='bold'
										mb='6px'
										my='6px'>
										320
									</Text>
									<Progress colorScheme='brand' bg='#2D2E5F' borderRadius='30px' h='5px' value={50} />
								</Flex>
							</SimpleGrid>
						</Flex>
					</CardBody>
				</Card>
			</Grid> */}
			<Grid templateColumns={{ sm: '1fr', md: '1fr 1fr', lg: '2fr 1fr' }} gap='24px'>
				{/* Projects */}
				<Card p='16px' overflowX={{ base: 'scroll', xl: 'hidden' }}>
					<CardHeader p='12px 0px 28px 0px'>
						<Flex direction='column'>
							<Text fontSize='lg' fontWeight='bold' pb='8px'>
								Transactions
							</Text>
							<Flex align='center'>
								<Icon as={IoCheckmarkDoneCircleSharp} color='teal.300' w={4} h={4} pe='3px' />
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									<Text fontWeight='bold' as='span'>
										{transactionsCountNew}
									</Text>{' '}
									{t("Fait ce mois")}

								</Text>
							</Flex>
						</Flex>
					</CardHeader>
					<Table variant='simple'>
						<Thead>
							<Tr my='.8rem' ps='0px'>
								<Th
									ps='0px'
									color='gray.400'
									fontFamily='Plus Jakarta Display'
									borderBottomColor='gray.400'>
									Client
								</Th>

								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='gray.400'>
									{t("Offre")}
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='gray.400'>
									{t("Pays")}
								</Th>
								<Th color='gray.400' fontFamily='Plus Jakarta Display' borderBottomColor='gray.400'>
									{t("Date de transaction")}
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{loadingData &&
								<Tr>

									<Td> <Skeleton height='20px' /></Td>
									<Td> <Skeleton height='20px' /></Td>
									<Td> <Skeleton height='20px' /></Td>
									<Td> <Skeleton height='20px' /></Td>

								</Tr>}
							{(paginationData?.length !== 0) && demandes.map((row) =>
								<Tr>
									<Td
										ps='0px'
										borderBottomColor='gray.400'
									>
										{/* {getClientName(row.client)} */}
										{row.client_name}

									</Td>


									<Td borderBottomColor='gray.400' >
										<Text fontSize='sm' fontWeight='semibold' pb='.5rem'>
											{/* {getOffreName(row.offre)} */}
											{row.offre_labelle}
										</Text>
									</Td>
									<Td borderBottomColor='gray.400' >
										<Text fontSize='sm' fontWeight='semibold' pb='.5rem'>
											{/* {getPaysName(row.offre)} */}
											{row.pays_nom}
										</Text>
									</Td>

									<Td borderBottomColor='gray.400'>
										<Flex direction='column'>
											<Text
												fontSize='sm'

												fontWeight='semibold'
												pb='.2rem'>
												{new Date(row.date_demande).toLocaleDateString(t('fr-FR'))}
											</Text>

										</Flex>
									</Td>
								</Tr>
							)}
							{((paginationData?.length === 0) || (checkLengthData)) && (
								<Tr>
									<Td colSpan={11}>{t("il n'y a pas encore de données.")}</Td>
								</Tr>
							)}

						</Tbody>
					</Table>
					{/* Pagination */}
					<Flex justify="space-between" align="center" w="100%">
						<Box flex="1">
							<MyPagination
								paginationData={paginationData}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								PAGE_SIZE={pageSize}
							/>
						</Box>

						{/* <Select borderRadius={'lg'} size={"sm"} w="70px" value={pageSize} onChange={handlePageSizeChange}>
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="10">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="40">50</option>
                                        </Select> */}
					</Flex>
				</Card>
				{/* Orders Overview */}
				<Card >
					<CardHeader >
						<Flex direction='column'>
							<Text fontSize='lg' fontWeight='bold' pb='8px' >
								Top 5 {t("partenaires")}
							</Text>
							<Flex align='center'>
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									{t("Basé sur les service utilisés par les clients")}
								</Text>
							</Flex>
						</Flex>

					</CardHeader>
					<CardBody p={0} pb={2}>
						<Flex direction='column' >
							<ReactApexChart options={topFiveData.options} series={topFiveData.series} type="radialBar" height={300} />
						</Flex>
					</CardBody>
				</Card>
			</Grid>
		</Flex>
	);
}
