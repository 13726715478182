import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box, Flex, Text, Image, Tabs, TabList, TabPanels, Tab, TabPanel, Spinner,
    Table, Thead, Tbody, Tr, Th, Td, useToast, SimpleGrid, Skeleton,
    TableContainer,
    useColorModeValue
} from '@chakra-ui/react';
import axiosClient from '../../axios-client';
import img from '../../assets/images/birthday.png';
import { IoMdCalendar } from 'react-icons/io';
import { LuPartyPopper } from "react-icons/lu";
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/birthday.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
function Birthday() {
    const [loading, setLoading] = useState(true);
    const [todayBirthdays, setTodayBirthdays] = useState([]);
    const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
    const [paginationData, setPaginationData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [tabIndex, setTabIndex] = useState(0);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const toast = useToast();
    const [checkLengthData, setCheckLengthData] = useState(false);

    const fetchBirthdays = async (type) => {
        setLoading(true);
        axiosClient.get(`/clients/get_birthdays/?type=${type}&page_size=${pageSize}&page=${currentPage + 1}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (type === 'today') {
                    setTodayBirthdays(filteredData);
                } else if (type === 'upcoming') {
                    setUpcomingBirthdays(filteredData);
                }

                if (filteredData.length === 0) {
                    setCheckLengthData(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                // console.error(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        const type = tabIndex === 0 ? 'today' : 'upcoming';
        fetchBirthdays(type);
    }, [tabIndex, pageSize, currentPage]);

    const handleTabChange = (index) => {
        setTabIndex(index);
        setCurrentPage(0);
        setPaginationData()
    };
    const borderColor = useColorModeValue("gray.400","gray.600")
    const borderColorRows = useColorModeValue("gray.200","gray.700")
    return (
        <Box padding="0" w="100%" minH="100vh" position="relative" overflow="hidden">
            <Flex justify="space-between" align="center" mt={7} px={4}>
                <Text fontFamily="Plus Jakarta Display" fontSize={{ base: "18px", lg: "25px" }} color="gray">
                    <span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >
                        {t("Accueil")}
                    </span>  / {t("Espace anniversaires")}
                </Text>
            </Flex>
            <Flex
                px={5}
                as={SimpleGrid}
                maxW={'7xl'}
                zIndex="1"
                columns={{ base: 1, lg: 2 }}
                spacing={{ base: 5, lg: 15 }}
                py={{ base: 5, sm: 5, lg: 5 }}
                position="relative"
                _before={{
                    content: '""',
                    position: 'absolute',
                    top: -40,
                    right: -20,
                    width: '30%',
                    height: '100vh',
                    opacity: 0.7,
                    filter: 'blur(140px)',
                    zIndex: -1,
                    background:
                        'radial-gradient(circle at 71px 61px, #FF69B4  111px, transparent 0), ' +
                        'radial-gradient(circle at 244px 106px, #FFD700  140px, transparent 0), ' +
                        'radial-gradient(circle at 80.5px 189.5px, #FF7F50  101.5px, transparent 0), ' +
                        'radial-gradient(circle at 196.5px 317.5px, #FF2929 101.5px, transparent 0), ' +
                        'radial-gradient(circle at 70.5px 458.5px, #DA70D6  101.5px, transparent 0), ' +
                        'radial-gradient(circle at 426.5px -0.5px, #FFFFFF 101.5px, transparent 0)',
                }}
            >
                <Tabs  w={{base:"100%",xl:"60%"}} variant="soft-rounded" colorScheme="purple" index={tabIndex} onChange={handleTabChange} mt={5}>
                    <TabList mb={2}>
                        <Tab gap={2}>
                            {t("Anniversaires d'aujourd'hui")}
                            <LuPartyPopper size={"20px"} />
                        </Tab>
                        <Tab gap={2}>
                            {t("Anniversaires à venir")}
                            <IoMdCalendar size={"20px"} />
                        </Tab>
                    </TabList>

                    <TabPanels rounded={"2xl"} background={useColorModeValue("gray.50","gray.800")}>
                        <TabPanel>
                            <TableContainer >
                                <Table style={{ borderColor: 'red' }} size={"lg"} variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th borderBottomColor={borderColor}>{t("Nom complet")}</Th>
                                            <Th borderBottomColor={borderColor}>{t("Date naissance")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {loading &&
                                            <Tr >

                                                <Td> <Skeleton height='30px' /></Td>
                                                <Td> <Skeleton height='30px' /></Td>

                                            </Tr>}
                                        {(paginationData?.length !== 0) && todayBirthdays?.map((client) => (
                                            <Tr key={client.id}>
                                                <Td borderBottomColor={borderColorRows}>{client.first_name} {client.last_name}</Td>
                                                <Td fontWeight={"bold"} borderBottomColor={borderColorRows}>{client.birthday}</Td>
                                            </Tr>
                                        ))}
                                        {paginationData?.length === 0 && (
                                            <Tr>
                                                <Td colSpan={11}>{t("Aucun anniversaire trouvé")}</Td>
                                            </Tr>
                                        )}

                                    </Tbody>
                                </Table>
                            </TableContainer>


                        </TabPanel>
                        <TabPanel>

                            <TableContainer>
                                <Table size={"lg"} variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th borderBottomColor={borderColor}>{t("Nom complet")}</Th>
                                            <Th borderBottomColor={borderColor}>{t("Date naissance")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {loading &&
                                            <Tr>

                                                <Td> <Skeleton height='30px' /></Td>
                                                <Td> <Skeleton height='30px' /></Td>

                                            </Tr>}
                                        {(paginationData?.length !== 0) && upcomingBirthdays?.map((client) => (
                                            <Tr key={client.id}>
                                                <Td borderBottomColor={borderColorRows}>{client.first_name} {client.last_name}</Td>
                                                <Td fontWeight={"bold"} borderBottomColor={borderColorRows}>{client.birthday}</Td>
                                            </Tr>
                                        ))}
                                        {paginationData?.length === 0 && (
                                            <Tr>
                                                <Td colSpan={11}>{t("Aucun anniversaire trouvé")}</Td>
                                            </Tr>
                                        )}

                                    </Tbody>
                                </Table>
                            </TableContainer>

                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box  w={"40%"}  flex="1" textAlign="center" display={{base:"none",xl:"flex"}}>
                    <Lottie options={defaultOptions} />
                </Box>
                {/* Uncomment to enable pagination */}
                {/* <Box mt={4}>
                    <MyPagination
                        paginationData={paginationData}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                        pageSize={pageSize}
                        onPageSizeChange={(size) => setPageSize(size)}
                    />
                </Box> */}
            </Flex>
        </Box>
    );
}

export default Birthday;
