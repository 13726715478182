import axios from "axios";
import CryptoJS from "crypto-js";

export const domainName = "https://api.apapremium.com"


// export const socketUrl = 'ws://127.0.0.1:8000'
// export const socketUrl = 'ws://192.168.100.183:8000'
export const socketUrl = 'wss://api.apapremium.com'


const axiosClient = axios.create({
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://192.168.100.183:8000/",
  baseURL: "https://api.apapremium.com/",


});

const secretKeyEnv = process.env.REACT_APP_SECRET_KEY;
const getEncryptionKey = () => {
  const deviceInfo = navigator.userAgent; // Get device-specific info (like browser)
  const secretKey = secretKeyEnv;

  return secretKey + deviceInfo;
};

const decryptToken = (encryptedToken) => {
  try {
    const key = getEncryptionKey();
    const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    // console.error("Error decrypting token:", error);
    return null;
  }
};


axiosClient.interceptors.request.use((config) => {
  const encryptedToken = localStorage.getItem("U_T");
  const token = encryptedToken ? decryptToken(encryptedToken) : null;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export default axiosClient;
