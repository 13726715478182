import {
    Editable,
    EditableInput,
    EditableTextarea,
    EditablePreview, Alert, AlertIcon, Avatar, Box, Button, Card, CardBody, CardHeader, Select, Flex, Grid, Input, InputGroup, Stack, Text, useColorModeValue, useToast, InputLeftElement, TableContainer, Table, Tr, Tbody, Td, IconButton, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,
    useEditableControls,
    ButtonGroup
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'

import { useStateContext } from '../../context/ContextProvider'
import axiosClient from '../../axios-client';
import { useNavigate } from 'react-router-dom'
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import { PiPlusBold } from 'react-icons/pi';
import { BsBuildings } from 'react-icons/bs';
import countryData from '../../assets/dataJson/countries.json';
import MyPaginantion from '../../components/MyPagination';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Pagination from '../../components/Pagination';


function GestLocations() {
    const { user } = useStateContext();
    const navigate = useNavigate()
    const [listPays, setListPays] = useState([])
    const [listVilles, setListVilles] = useState([]);
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const { t } = useTranslation();


    const [villeName, setVilleName] = useState('');
    const [villeNameEdit, setVilleNameEdit] = useState('');
    const [paysName, setPaysName] = useState('');
    const [deletePaysId, setDeletePaysId] = useState(null);
    const [deleteVilleId, setDeleteVilleId] = useState(null);

    const [selectedPays, setSelectedPays] = useState(null);

    const [addVille, setAddVille] = useState(false)
    const [addPays, setAddPays] = useState(false)

    const [nbPartners, setNbPartners] = useState()
    const [nbEvents, setNbEvents] = useState()
    const [nbOffers, setNbOffers] = useState()

    const [loadingAlert, setLoadingAlert] = useState(true)

    const inputColor = useColorModeValue("gray.300", "gray.600")
    //search variable
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTerm2, setSearchTerm2] = useState('');

    //toast variable
    const toast = useToast()

    const cancelRef = React.useRef()
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isAlert2Open, setIsAlert2Open] = useState(false);

    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert2 = () => {
        setIsAlert2Open(false);
    };

    const onOpenAlert2 = () => {
        setIsAlert2Open(true);
    };

    useEffect(() => {
        axiosClient.get('/pays/')
            .then((response) => {
                setListPays(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });

        axiosClient.get('/villes/')
            .then((response) => {
                setListVilles(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });
        setLoadingAlert(true)

        if (deletePaysId) {
            axiosClient.get(`/partenaires/get_count_partenaires_by_country/?pays=${deletePaysId}`)
                .then((response) => {

                    setNbPartners(response.data);
                    setLoadingAlert(false)
                })
                .catch((error) => {
                    // console.error(error);
                    setLoadingAlert(false)
                });

            axiosClient.get(`/events/get_count_events_by_country/?pays=${deletePaysId}`)
                .then((response) => {

                    setNbEvents(response.data);
                    setLoadingAlert(false)
                })
                .catch((error) => {
                    // console.error(error);
                    setLoadingAlert(false)
                });

            axiosClient.get(`/offres/get_count_offers_by_country/?pays=${deletePaysId}`)
                .then((response) => {

                    setNbOffers(response.data);
                    setLoadingAlert(false)
                })
                .catch((error) => {
                    // console.error(error);
                    setLoadingAlert(false)
                });
        }



    }, [deletePaysId]);
    function update() {
        axiosClient.get('/villes/')
            .then((response) => {
                setListVilles(response.data.sort().reverse());
            })
            .catch((error) => {
                // console.error(error);
            });
    }
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedCountryValue, setSelectedCountryValue] = useState('');


    useEffect(() => {
        const Mycountries = [];
        const data = countryData;
        for (let index = 0; index < data.countries.length; index++) {
            Mycountries.push({
                value: data.countries[index].value,
                label: data.countries[index].label.substring(5, 50)
            });
        }
        setCountries(Mycountries);
    }, []);



    const handleCountryChange = (e) => {
        setSelectedCountry(countries.find(
            (country) => country.value === e.target.value
        ))

        setSelectedCountryValue(countries.find(
            (country) => country.value === e.target.value).value.toLowerCase())

    };


    const colorbg = useColorModeValue('gray.200', '#2d3748')
    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    const filteredData = useCallback(() => {
        return listPays.filter((row) => {
            return row.nom_pays.toLowerCase().includes(searchTerm.toLowerCase())
        });
    }, [listPays, searchTerm]);

    const filteredVilleData = useCallback(() => {
        return listVilles.filter((row) => {
            return row.nom_ville.toLowerCase().includes(searchTerm2.toLowerCase()) ||
                (listPays.find((pays) => pays.id === row.pays).nom_pays).toLowerCase().includes(searchTerm2.toLowerCase())
        });
    }, [listVilles, searchTerm2]);


    const AjoutPays = async (e) => {

        if (!selectedCountry.label) {
            setMessage(t("Veuillez choisir un pays"))
            return;
        }


        const formData = new FormData()
        if (selectedCountry) { formData.append("nom_pays", selectedCountry.label); }

        // const flagImagePath =  require(`../../assets/images/countries/${selectedCountryValue}.png`);
        // const flagImageResponse = await fetch(flagImagePath);
        // if (!flagImageResponse.ok) {
        //     throw new Error(`Échec de la récupération de l'image: ${flagImageResponse.status}`);
        //   }
        // const flagImageBlob = await flagImageResponse.blob();

        const flagImage = await fetch(getFlagImagePath());
        const flagImageBlob = await flagImage.blob() // Convert the image to a Blob
        const flagImageFile = new File([flagImageBlob], `${selectedCountryValue}.png`, { type: 'image/png' });

        // console.log(flagImageBlob)

        formData.append('flag', flagImageFile);

        if (listPays.length > 0) {
            const check = listPays.find(obj => {

                return obj.nom_pays === selectedCountry.label;
            })

            if (check) {
                return setMessage(t('Ce pays existe déja!'))
            }
        }
        await axiosClient.post('pays/', formData)
            .then((response) => {
                setListPays([response.data, ...listPays]);
                setSelectedCountry('')
                setMessage("")
                setAddPays(false)
                toast({
                    description: ` ${selectedCountry.label} ${t("est ajouté avec succès")} `,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right',
                })
            })

    }

    const AjoutVille = () => {

        if (!villeName) {
            setMessage2(t("Veuillez entrer un nom"))
            return;
        }
        const formData = new FormData()
        if (villeName) { formData.append("nom_ville", villeName); }
        formData.append("pays", selectedPays.id);


        if (listVilles.length > 0) {
            const check = listVilles.find(obj => {

                return obj.nom_ville === villeName;
            })

            if (check) {
                return setMessage2(t('Cette ville existe déja!'))
            }
        }

        axiosClient.post('/villes/', formData)
            .then((response) => {
                setListVilles([response.data, ...listVilles]);
                setMessage2("")
                setAddVille(false)
                setSelectedPays(null)
                toast({
                    description: `${villeName} ${t("est ajouté avec succès")}`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right',
                })
                setVilleName('')
            })

    }
    const handleDeletePays = (id) => {
        if (!id) return;
        axiosClient.delete(`/pays/${id}/`)
            .then((response) => {
                setListPays((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("Le pays est supprimé avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                onCloseAlert()
            })
            .catch((error) => {
                // console.error(error);
            });
    }
    const editNameVille = (id) => {


        const formData = new FormData()
        if (villeNameEdit) formData.append("nom_ville", villeNameEdit)
        axiosClient.patch(`/villes/${id}/`, formData)
            .then((response) => {

                toast({
                    description: ` ${t("Le nom de ville est modifié avec succès")}`,
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right',
                })
                setVilleNameEdit('')
                setIndexDispalyVille(-1)
                update()


            })
            .catch((error) => {
                // console.error('Error:', error);
            })
    }
    const handleDeleteVille = (id) => {
        if (!id) return;
        axiosClient.delete(`/villes/${id}/`)
            .then((response) => {
                setListVilles((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("La ville est supprimée avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
                onCloseAlert2()
            })
            .catch((error) => {
                // console.error(error);
            });
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const [currentPage, setCurrentPage] = useState(0);

    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 5);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////the size of the table//////////////////////////////
    const [currentPage2, setCurrentPage2] = useState(0);
    const PAGE_SIZE2 = 5;
    const [pageSize2, setPageSize2] = useState(PAGE_SIZE2);
    const handlePageSizeChange2 = (event) => {
        const newSize = parseInt(event.target.value, 5);
        setPageSize2(newSize);
        setCurrentPage2(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const getFlagImagePath = () => {
        if (selectedCountryValue) {
            return require(`../../assets/images/countries/${selectedCountryValue}.png`);
        }
        return null;
    };

    const alertColor = useColorModeValue("red.600", "red.300")
    const [displayVille, setDisplayVille] = useState(false)
    const [indexDisplayVille, setIndexDispalyVille] = useState(-1)

    // function EditableControls() {
    //     const {
    //         isEditing,
    //         getSubmitButtonProps,
    //         getCancelButtonProps,
    //         getEditButtonProps,
    //     } = useEditableControls()

    //     return isEditing ? (
    //         <ButtonGroup size='sm'>
    //             <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
    //             <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
    //         </ButtonGroup>
    //     ) : (
    //         <IconButton size='sm' icon={<EditIcon />} {...getEditButtonProps()} />
    //     )
    // }

    return (
        <Box bgSize="auto"
            bgRepeat="no-repeat"
            bgPosition="right"
            backgroundImage={"https://www.pngall.com/wp-content/uploads/1/World-Map-PNG-Photo.png"}>

            <Flex>
                <Box w="90%" mt={3} mb={5} >
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span  >{t("Réseau de Partenaires")}</span></Text>
                </Box>
            </Flex>
            <Box direction='column' mt={{ sm: '25px', md: '0px' }}>

                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr", xl: "2.5fr 2.5fr 1fr " }} px={{ base: 1, md: 4, lg: 4, xl: 5 }} gap='40px' >

                    <Card
                        bg={useColorModeValue("gray.200", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='1px 5px' display={'flex'} justifyContent={"space-between"}>
                            <Text fontSize='lg' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
                                {t("Pays")}
                            </Text>
                            <Button onClick={() => setAddPays(true)} _hover={"none"} color={"white"} w={"20%"} backgroundColor={"#49b1cc"} rounded={'xl'} size={'sm'}>
                                <PiPlusBold />
                            </Button>
                        </CardHeader>
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {addPays &&
                                    <>
                                        <Flex flexDirection={"column"}>
                                            {/* <Input name='paysName' value={paysName} onChange={e => setPaysName(e.target.value)} borderColor={inputColor} mb={2} size={"sm"} rounded={'lg'} maxLength={50}  placeholder='Nom de pays...' /> */}
                                            {/* <Select
                                                id="pays"
                                                name="pays"
                                                options={countries}
                                                onChange={(selectedOption) => setSelectedCountry(selectedOption)}
                                                isSearchable
                                                styles={{
                                                    
                                                    control: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        color: txtcolor
                                                        
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        cursor: "pointer"
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: txtcolor,
                                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: colorbghover,
                                                        },
                                                        cursor: "pointer"
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: txtcolor
                                                    }),
                                                }}
                                            /> */}
                                            {message &&
                                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                                    <AlertIcon />
                                                    {message}
                                                </Alert>
                                            }
                                            <Select
                                                borderColor={inputColor}
                                                mb={2} size={"sm"} rounded={'lg'}
                                                id="pays"
                                                name="pays"
                                                defaultValue={selectedCountry}
                                                onChange={handleCountryChange}
                                                isSearchable
                                                bg={colorbg}
                                                color={txtcolor}
                                                _focus={{ bg: colorbg, borderColor: "transparent" }}
                                            >
                                                <option>-- {t("Choisir un pays")} --</option>
                                                {countries.sort((a, b) => a.label.localeCompare(b.label)).map((country) => (
                                                    <option
                                                        key={country.value}
                                                        value={country.value}
                                                        bg={colorbg}
                                                        color={txtcolor}
                                                        _hover={{ bg: colorbghover, cursor: "pointer" }}
                                                    >
                                                        {country.label}
                                                    </option>
                                                ))}
                                            </Select>

                                            <Flex flexDirection={'row'} justify={"end"}>
                                                <Button onClick={() => { setAddPays(false); setMessage('') }} _hover={"none"} color={"white"} w={"fit-content"} fontSize={"xs"} colorScheme='red' rounded={'xl'} size={'sm'}>
                                                    {t("Annuler")}
                                                </Button>
                                                <Button onClick={AjoutPays} ml={2} _hover={"none"} color={"white"} w={"fit-content"} fontSize={"xs"} backgroundColor={"#49b1cc"} rounded={'xl'} size={'sm'}>
                                                    {t("Enregistrer")}
                                                </Button>
                                            </Flex>

                                        </Flex>

                                    </>

                                }
                                <Flex
                                    align={'center'}
                                    justify={'center'}
                                >
                                    <Stack
                                        w={'full'}
                                        maxW='full'
                                        bg={useColorModeValue('gray.200', 'gray.700')}
                                        rounded={'lg'}
                                        p={1}
                                        my={1}>
                                        {/* Search input */}
                                        <Flex
                                            justifyContent="end"
                                            alignItems="center"
                                            direction={{ base: 'column', lg: 'row' }}
                                        >

                                            <InputGroup size={"sm"} w={"100%"}>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={<SearchIcon color='gray.300' />}
                                                />
                                                <Input
                                                    borderColor={'gray.300'}
                                                    borderRadius={"5"}
                                                    placeholder={t("Recherche...")}
                                                    value={searchTerm}
                                                    mb={4}
                                                    sx={{
                                                        marginLeft: 'auto',
                                                    }}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />  </InputGroup>

                                        </Flex>
                                        <TableContainer rounded="lg">
                                            <Table size={"sm"} variant='striped' colorScheme="gray">
                                                {/* <Thead>
                                                    <Tr>
                                                        <Th fontSize={'sm'}>Nom</Th>
                                                        <Th fontSize={'sm'}>Date Création</Th>
                                                        <Th fontSize={'sm'}>Options</Th>
                                                    </Tr>
                                                </Thead> */}
                                                <Tbody>
                                                    {filteredData().slice(currentPage * pageSize, currentPage * pageSize + pageSize)
                                                        .map((row) => (
                                                            <Tr cursor='pointer' onClick={() => { setSearchTerm2(row.nom_pays) }} p={0} key={row.id}>
                                                                <Td> <Avatar borderRadius='5px' size={'sm'}
                                                                    src={row.flag}
                                                                    alt="Flag"
                                                                /></Td>

                                                                <Td fontSize={'sm'} >{row.nom_pays}</Td>

                                                                <Td >
                                                                    {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                                        <IconButton
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                setDeletePaysId(row.id);
                                                                                if (!loadingAlert) {
                                                                                    onOpenAlert();
                                                                                }
                                                                            }}

                                                                            colorScheme='red'

                                                                            icon={<DeleteIcon />}>

                                                                        </IconButton>}
                                                                    <Button ml={2}
                                                                        onClick={() => {
                                                                            setAddVille(true);
                                                                            setSelectedPays(row)
                                                                        }}
                                                                        _hover={"none"} color={"white"} background="gray.500" size={'sm'}>
                                                                        <BsBuildings />
                                                                    </Button>

                                                                    {/* {(user.role === "SUPERADMIN" || user.role === "MASTER") && <Button
                                                ml={5}
                                                size="sm"
                                                onClick={() => {
                                                    setCatId(row.id);
                                                    onOpenAlert();
                                                }}
                                                colorScheme='red'
                                                leftIcon={<DeleteIcon />}>
                                                Supprimer
                                            </Button>
                                            } */}

                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                    {filteredData().length === 0 && (
                                                        <Tr>
                                                            <Td colSpan={5} fontSize={'sm'}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                                        </Tr>
                                                    )}
                                                </Tbody>
                                            </Table>

                                        </TableContainer>
                                        {/* Pagination */}
                                        <Flex justify="space-between" align="center" >
                                            <Box flex="1">
                                                <Pagination
                                                    data={filteredData()}
                                                    searchInput={searchTerm}
                                                    PAGE_SIZE={pageSize}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            </Box>


                                        </Flex>
                                    </Stack>
                                </Flex>

                            </Flex>
                        </CardBody>
                    </Card>
                    <Card

                        bg={useColorModeValue("gray.700", "gray.700")}
                        display={'flex'}
                        p='16px'
                    >
                        <CardHeader p='1px 5px' display={'flex'} justifyContent={"space-between"}>
                            <Text mb={2} fontSize='lg' color={"gray.200"} fontWeight='bold'>
                                {t("Villes")}
                            </Text>
                            <Text mb={2} fontSize='lg' color={"gray.200"} fontWeight='bold'> {selectedPays ? `Pays : ${selectedPays.nom_pays}` : null}</Text>

                        </CardHeader>
                        <CardBody px='5px'>
                            <Flex direction='column'>

                                {addVille &&
                                    <>
                                        <Flex flexDirection={"column"}>
                                            {message2 &&
                                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                                    <AlertIcon />
                                                    {message2}
                                                </Alert>
                                            }
                                            <Input name='villeName' value={villeName} onChange={e => setVilleName(e.target.value)} mb={2} size={"sm"} color="gray.200" rounded={'lg'} maxLength={50} placeholder='Nom de ville...' />
                                            <Flex flexDirection={'row'} justify={"end"}>
                                                <Button onClick={() => { setAddVille(false); setSelectedPays(); setMessage2(''); setVilleName(''); setSearchTerm2('') }} _hover={"none"} color={"white"} w={"fit-content"} fontSize={"xs"} colorScheme='red' rounded={'xl'} size={'sm'}>
                                                    {t("Annuler")}
                                                </Button>
                                                <Button ml={2} onClick={AjoutVille} _hover={"none"} color={"white"} w={"fit-content"} fontSize={"xs"} backgroundColor={"#49b1cc"} rounded={'xl'} size={'sm'}>
                                                    {t("Enregistrer")}
                                                </Button>
                                            </Flex>
                                        </Flex>

                                    </>

                                }
                                <Flex
                                    align={'center'}
                                    justify={'center'}
                                >
                                    <Stack
                                        w={'full'}
                                        maxW='full'
                                        bg={useColorModeValue('gray.700', 'gray.700')}
                                        rounded={'lg'}
                                        p={1}
                                        my={1}>
                                        {/* Search input */}
                                        <Flex
                                            justifyContent="end"
                                            alignItems="center"
                                            direction={{ base: 'column', lg: 'row' }}
                                        >

                                            <InputGroup size={"sm"} w={"100%"}>
                                                <InputLeftElement
                                                    pointerEvents='none'
                                                    children={<SearchIcon color='gray.300' />}
                                                />
                                                <Input
                                                    color='gray.200'
                                                    borderColor={'gray.300'}
                                                    borderRadius={"5"}
                                                    placeholder={t("Recherche...")}
                                                    value={searchTerm2}
                                                    mb={4}
                                                    sx={{
                                                        marginLeft: 'auto',
                                                    }}
                                                    onChange={(e) => setSearchTerm2(e.target.value)}
                                                />  </InputGroup>

                                        </Flex>
                                        <TableContainer rounded="lg">
                                            <Table size={"sm"} variant='striped' background={useColorModeValue('gray.200', 'gray.700')}>
                                                {/* <Thead>
                                                    <Tr>
                                                        <Th fontSize={'sm'}>Nom</Th>
                                                        <Th fontSize={'sm'}>Date Création</Th>
                                                        <Th fontSize={'sm'}>Options</Th>
                                                    </Tr>
                                                </Thead> */}
                                                <Tbody>
                                                    {filteredVilleData().slice(currentPage2 * pageSize2, currentPage2 * pageSize2 + pageSize2).map((row, key) => (
                                                        <Tr p={0} key={row.id}>
                                                            {(indexDisplayVille !== key)
                                                                ?
                                                                <>
                                                                    <Td fontSize={'sm'}>{row.nom_ville}</Td>

                                                                    <Td fontSize={'sm'}>

                                                                        <>
                                                                            {listPays.find((pays) => pays.id === row.pays)?.nom_pays ?? "Loading..."}

                                                                        </>
                                                                    </Td>

                                                                    <Td >
                                                                        <IconButton
                                                                            mr={2}
                                                                            onClick={() => {
                                                                                // setDisplayVille(true)
                                                                                setIndexDispalyVille(key)
                                                                            }}
                                                                            colorScheme='yellow' size='sm' icon={<EditIcon />} />
                                                                        <>

                                                                            {(user.role === "SUPERADMIN" || user.role === "ADMIN") &&
                                                                                <IconButton
                                                                                    size="sm"
                                                                                    onClick={() => {
                                                                                        setDeleteVilleId(row.id);
                                                                                        onOpenAlert2();
                                                                                    }}

                                                                                    colorScheme='red'

                                                                                    icon={<DeleteIcon />}>

                                                                                </IconButton>}
                                                                        </>




                                                                    </Td>
                                                                </>
                                                                :
                                                                <Td colSpan={3}>
                                                                    <Flex gap={3} alignItems={"center"} >
                                                                        <Input maxLength={50} border={"1px solid lightgray"} defaultValue={villeNameEdit ? villeNameEdit : row.nom_ville} onChange={(e) => setVilleNameEdit(e.target.value)} />
                                                                        <ButtonGroup size='sm'>
                                                                            <IconButton size="sm" onClick={() => editNameVille(row.id)} variant={'ghost'} colorScheme='whatsapp' icon={<CheckIcon />} />
                                                                            <IconButton size="sm" variant={'ghost'} colorScheme='red' onClick={() => setIndexDispalyVille(-1)} icon={<CloseIcon />} />
                                                                        </ButtonGroup>
                                                                    </Flex>

                                                                </Td>
                                                            }


                                                        </Tr>
                                                    ))}
                                                    {filteredVilleData().length === 0 && (
                                                        <Tr>
                                                            <Td colSpan={5} fontSize={'sm'}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                                        </Tr>
                                                    )}
                                                </Tbody>
                                            </Table>
                                            {/* Pagination */}
                                            <Flex justify="space-between" align="center" >
                                                <Box flex="1">
                                                    <Pagination
                                                        data={filteredVilleData()}
                                                        searchInput={searchTerm2}
                                                        PAGE_SIZE={pageSize}
                                                        currentPage={currentPage2}
                                                        setCurrentPage={setCurrentPage2}
                                                    />
                                                </Box>


                                            </Flex>
                                        </TableContainer>

                                    </Stack>
                                </Flex>

                            </Flex>


                        </CardBody>
                    </Card>

                </Grid>

            </Box>
            {!loadingAlert &&
                <AlertDialog
                    size={"lg"}
                    closeOnOverlayClick={false}
                    isOpen={isAlertOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseAlert}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t("Supprimer")}
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                {
                                    (nbPartners > 0 || nbEvents > 0 || nbOffers > 0) ?
                                        <>
                                            <Text>
                                                {t("Attention ! Des données sont liées à ce pays:")}
                                            </Text>
                                            <Flex flexDirection={'column'}>
                                                {nbPartners > 0 &&
                                                    <Text fontWeight={"semibold"}>
                                                        - {nbPartners}  {nbPartners > 1 ? t("Partenaires") : t("Partenaire")}
                                                    </Text>
                                                }

                                                {nbEvents > 0 &&
                                                    <Text fontWeight={"semibold"}>
                                                        - {nbEvents} {nbEvents > 1 ? t("Événements") : t("Événement")}
                                                    </Text>}

                                                {nbOffers > 0 &&
                                                    <Text fontWeight={"semibold"}>
                                                        - {nbOffers} {nbOffers > 1 ? t("Offres") : t("Offre")}
                                                    </Text>}

                                            </Flex>
                                            <Text>
                                                {t("Ces données ne seront plus accessibles dans l'application mobile après la suppression du pays. Êtes-vous sûr de vouloir continuer ?")}

                                            </Text>
                                        </>

                                        :
                                        <Text>
                                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                                        </Text>
                                }




                            </AlertDialogBody>



                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={() => {
                                    setMessage(false)
                                    onCloseAlert()
                                }}>
                                    {t("Annuler")}
                                </Button>
                                <Button onClick={() => handleDeletePays(deletePaysId)} colorScheme='red' ml={3} isDisabled={message}>
                                    {t("Supprimer")}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>}
            <AlertDialog
                closeOnOverlayClick={false}
                isOpen={isAlert2Open}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert2}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t('Supprimer')}
                        </AlertDialogHeader>

                        <AlertDialogBody>


                            <Text>
                                {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                            </Text>

                        </AlertDialogBody>



                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => {
                                setMessage2(false)
                                onCloseAlert2()
                            }}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDeleteVille(deleteVilleId)} colorScheme='red' ml={3} isDisabled={message2}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>

    )
}

export default GestLocations