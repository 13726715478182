import { Alert, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertIcon, Avatar, AvatarBadge, Box, Button, Card, CardBody, Checkbox, Flex, FormControl, FormLabel, Grid, Icon, IconButton, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger, Select, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from '../../context/ContextProvider';
import axiosClient from '../../axios-client';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';
import MyPagination from "../../components/MyPagination";
import { PiPlusBold } from 'react-icons/pi';
import animationData from '../../assets/lotties/subscribe.json'
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import makeAnimated from 'react-select/animated';
import MySelect from 'react-select';
import { FaPencilAlt } from 'react-icons/fa';
import Resizer from "react-image-file-resizer";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


function TypeAbonnements() {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(false)
    const [typeAbData, setTypeAbData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [id, setId] = useState(null);
    const toast = useToast()
    const cancelRef = React.useRef()
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [message, setMessage] = useState('');
    const [typeData, setTypeData] = useState();
    const [typeId, setTypeId] = useState('');
    const [description, setDescription] = useState('');

    const [fraisM, setFraisM] = useState('');
    const [fraisA, setFraisA] = useState('');
    const [type, setType] = useState('');
    const [options, setOptions] = useState([
        { value: '', label: '' },
    ]);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedFeeType, setSelectedFeeType] = useState(null);

    const animatedComponents = makeAnimated();
    const [imageAb, setImageAb] = useState()
    const [selectedAbImage, setSelectedAbImage] = useState()
    const [selectedAbImg, setSelectedAbImg] = useState()

    //search variable
    const [searchTerm, setSearchTerm] = useState('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [changeModal, setChangeModal] = useState(true)

    const onOpenAlert = () => {
        setIsAlertOpen(true);
    };
    const onCloseAlert = () => {
        setIsAlertOpen(false);
    };

    const handleFeeTypeChange = (ev) => {
        setSelectedFeeType(ev.target.value)
        setFraisA('')
        setFraisM('')
    }
    ////////////////////////////////the size of the table//////////////////////////////
    const PAGE_SIZE = 5;
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const handlePageSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setPageSize(newSize);
        setCurrentPage(0);
    };
    //////////////////////////////////////////////////////////////////////////////////
    const [checkLengthData, setCheckLengthData] = useState(false)
    const [loadingData, setLoadingData] = useState(true)
    const [paginationData, setPaginationData] = useState()


    //get the data
    useEffect(() => {
        axiosClient.get(`/typeAbonnements/getAllTypesAbonnement/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setTypeAbData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
        // axiosClient.get('typeAbonnements/')
        //     .then((res) => {
        //         setTypeAbData(res.data.sort().reverse())
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     });
        axiosClient.get('pays/')
            .then(({ data }) => {

                const paysOptions = data.map(pay => ({
                    value: pay.id,
                    label: pay.nom_pays,
                }));

                setOptions([
                    ...paysOptions,
                ]);
            })
            .catch((error) => {
                // console.error('Error fetching pays data:', error);
            });
        if (selectedAbImage) {
            setImageAb(URL.createObjectURL(selectedAbImage))
        }
        else {
            setSelectedAbImage()
        }

    }, [selectedOptions, isChecked, selectedAbImage, searchTerm])

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });
    };
    const handleChangeAbImage = async (e) => {
        try {
            const file = e.target.files[0];
            setSelectedAbImg(file)
            const blobImage = await resizeFile(file);

            const fileName = file.name; // Use the original filename
            const imageFile = new File([blobImage], fileName, { type: blobImage.type });

            setSelectedAbImage(imageFile);
        } catch (err) {
            // console.log(err);
        }
        // const image = e.target.files[0]
        // setSelectedCatImage(image)
    }

    function update() {
        axiosClient.get(`/typeAbonnements/getAllTypesAbonnement/?page_size=${pageSize}&page=${currentPage + 1}&search_term=${searchTerm}`)
            .then((response) => {
                let filteredData = response.data.results;
                setPaginationData(response.data);

                if (filteredData.length === 0) {
                    setCheckLengthData(true)
                }
                else {
                    setTypeAbData(filteredData);
                }
                setLoadingData(false)
            })
            .catch((error) => {
                // console.error(error);
                setLoadingData(false)

            });
    }
    const handleSelectChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        // console.log(selectedOptions);
    };


    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/typeAbonnements/${id}/`)
            .then((response) => {
                setTypeAbData((prevData) => prevData.filter((row) => row.id !== id));
                toast({
                    description: t("Le type d'abonnement est supprimé avec succès"),
                    status: 'success',
                    variant: 'left-accent',
                    duration: 2000,
                    isClosable: true,
                    position: 'bottom-right'
                })
            })
            .catch((error) => {
                // console.error(error);
            });
        onCloseAlert()
    }

    const AjoutType = () => {
        if (!type || type.trim() === "") {
            setMessage(t("Veuillez remplir le type"))
            return;
        }
        const selectedPaysIds = selectedOptions ? selectedOptions.map(option => option.value) : [];

        const formData = new FormData()
        formData.append("type", type)
        if (fraisM) { formData.append("monthly_fee", fraisM) }
        if (fraisA) { formData.append("yearly_fee", fraisA) }
        formData.append("description", description)
        if (selectedAbImage) formData.append("image", selectedAbImage);
        for (let index = 0; index < selectedPaysIds.length; index++) {
            formData.append("pays", selectedPaysIds[index]);
        }

        if (typeAbData.length > 0) {
            const check = typeAbData.find(obj => {
                return obj.type.toLowerCase() === type.toLowerCase().trim();
            })
            if (check) {
                return setMessage(t(`Ce type d'abonnement existe déja !`))
            }
        }
        axiosClient.post('/typeAbonnements/', formData)
            .then((response) => {
                setTypeAbData([response.data, ...typeAbData]);
                setType('')
                setFraisM()
                setFraisA()
                setDescription('')
                setSelectedFeeType('')
                setSelectedAbImage()
                setMessage("")
                setImageAb()
                setSelectedOptions(null)

                toast({
                    description: t(`Le type d'abonnement est ajoutée avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
            })
        onClose()
    }

    const UpdateType = (id) => {
        const formData = new FormData();
        if (type) formData.append("type", type)
        if (description) formData.append("description", description);

        if (selectedFeeType === "Both") {
            if (fraisM) formData.append("monthly_fee", fraisM)
            if (fraisA) formData.append("yearly_fee", fraisA)
        }
        if (selectedFeeType === "Mentuel") {
            if (fraisM) formData.append("monthly_fee", fraisM)
            formData.append("yearly_fee", 0)
        }
        if (selectedFeeType === "Annuel") {
            formData.append("monthly_fee", 0)
            if (fraisA) formData.append("yearly_fee", fraisA)
        }
        const selectedPaysIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        let tab = []
        if (selectedPaysIds.length > 0) {
            for (let index = 0; index < selectedPaysIds.length; index++) {
                tab.push(selectedPaysIds[index]);
            }
        }
        const newList = typeAbData.filter((e) => e.id !== id)
        if (newList.length > 0) {
            const check = newList.find(obj => {
                return obj.type.toLowerCase() === type.toLowerCase().trim();
            })
            if (check) {
                return setMessage(t(`Ce type d'abonnement existe déja !`))
            }
        }
        formData.append('pays', JSON.stringify(tab));
        const fd = new FormData()
        if (selectedAbImage) fd.append("image", selectedAbImage);

        axiosClient.patch(`/typeAbonnements/${id}/`, {
            ...(description && { description: formData.get("description") }),
            ...(type && { type: formData.get("type") }),
            ...(formData.get("monthly_fee") && { monthly_fee: formData.get("monthly_fee") }),
            ...(formData.get("yearly_fee") && { yearly_fee: formData.get("yearly_fee") }),
            pays: tab
        }).then(() => {
            update()
            axiosClient.patch(`/typeAbonnements/${id}/`, fd).then(() => {
                update()

                toast({
                    description: t(`Le type d'abonnement est modifié avec succès`),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    variant: 'left-accent',
                    position: 'bottom-right'
                })
                setType('')
                setFraisM()
                setFraisA()
                setDescription('')
                setSelectedFeeType('')

                setSelectedAbImage()
                setImageAb(null)
                setMessage("")
                setIsChecked(false)
                // setTypeData("")
            })

        })

        onClose()
    }
    const colorbg = useColorModeValue('white', '#2d3748')
    const multiLabel = useColorModeValue('#e6e6e6', '#4e536c')

    const multitext = useColorModeValue('black', 'white')


    const txtcolor = useColorModeValue('black', 'white')
    const colorbghover = useColorModeValue('#e2e8f0', '#171923')

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
    
        // Reorder the items
        const reorderedItems = Array.from(typeAbData);
        const [movedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, movedItem);
    
        setTypeAbData(reorderedItems);
    
        // Update the database with the new order
        updateDatabase(reorderedItems);
    };

    // Fonction pour simuler la mise à jour dans la base de données
    const updateDatabase = (newOrder) => {
        const updatedOrder = newOrder.map((item, index) => ({
            id: item.id,
            order: index,
        }));

        axiosClient.post('/typeAbonnements/update_order/', updatedOrder)
            .then(response => {
                // console.log("Order updated successfully:", response.data);
            })
            .catch(error => {
                // console.error("Error updating order:", error);
            });
    };

    return (
        <Box>

            <Flex>
                <Box w="100%" display={"flex"} justifyContent="space-between" flexDirection={{ base: 'column', md: "row" }} mt={2} mb={{ base: 0, md: 5 }}>
                    <Text fontFamily='Plus Jakarta Display' as="cite" fontSize={{ base: "18px", md: "20px", lg: "25px" }} color="gray"><span onClick={() => navigate('/')} style={{ cursor: 'pointer' }} onMouseEnter={e => e.target.style.textDecoration = "underline"} onMouseLeave={e => e.target.style.textDecoration = "none"} >{t("Accueil")}</span>  / <span>{t("Types des abonnements")}</span> </Text>
                    <Button mb={{ base: 5, md: 0 }} onClick={() => {
                        onOpen()
                        setChangeModal(true)
                    }}
                        leftIcon={<PiPlusBold color='#49b1cc' />} variant='solid'>
                        {t("Ajouter type abonnement")}
                    </Button>
                </Box>

            </Flex>
            <Flex direction='column' mt={{ sm: '25px', md: '0px' }} p={{ base: 0, lg: 7 }}>

                <Grid templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr 3fr " }} justify={"center"} gap='22px'>

                    <Flex display={{ base: "none", lg: "flex" }} align={'center'}>
                        <Lottie style={{ height: "300px", width: "350px", objectFit: 'contain' }} options={defaultOptions} />
                    </Flex>

                    <Card

                        bg={useColorModeValue("white", "gray.700")}
                        display={'flex'}
                    >

                        <CardBody px='2px' >
                            <Flex
                                align={'center'}
                                justify={'center'}
                            >
                                <Stack
                                    w={'full'}
                                    maxW='full'
                                    bg={useColorModeValue('white', 'gray.700')}
                                    rounded={'lg'}
                                    px={6}
                                >
                                    {/* Search input */}
                                    <Flex
                                        justifyContent="end"
                                        alignItems="center"
                                        direction={{ base: 'column', lg: 'row' }}
                                    >

                                        <InputGroup size={"sm"} w={{ base: '100%', lg: "30%" }}>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<SearchIcon color='gray.300' />}
                                            />
                                            <Input
                                                borderRadius={"5"}
                                                placeholder={t("Recherche...")}
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                mb={4}
                                                sx={{
                                                    marginLeft: 'auto',
                                                }}
                                            />  </InputGroup>

                                    </Flex>

                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <TableContainer ref={provided.innerRef} {...provided.droppableProps}>
                                                    <Table size={"sm"} variant='striped' colorScheme="gray">
                                                        <Thead>
                                                            <Tr>
                                                                <Th>{t("Image")}</Th>
                                                                <Th>{t("Type")}</Th>
                                                                <Th>{t("Description")}</Th>
                                                                <Th>{t("Frais mensuels")}</Th>
                                                                <Th>{t("Frais annuels")}</Th>
                                                                <Th>{t("Date Création")}</Th>
                                                                <Th>{t("Options")}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {typeAbData.length ? (
                                                                typeAbData.map((row, index) => (
                                                                    <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                                                        {(provided) => (
                                                                            <Tr  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <Td><Avatar name={row.name} borderRadius='15px' src={row.image} /></Td>
                                                                                <Td>{row.type}</Td>
                                                                                <Td>
                                                                                    <Flex flexDirection='row' gap={1}>
                                                                                        {row.description.length > 0 ? (
                                                                                            <>
                                                                                                <Text>{row.description.substring(0, 20)} {row.description.length > 20 && <>...</>}</Text>
                                                                                                <Popover>
                                                                                                    <PopoverTrigger>
                                                                                                        <Text cursor='pointer' _hover={{ color: 'lightgray' }}>{row.description.length > 20 && <>{t("(voir plus)")}</>}</Text>
                                                                                                    </PopoverTrigger>
                                                                                                    <PopoverContent>
                                                                                                        <PopoverArrow />
                                                                                                        <PopoverBody>
                                                                                                            <Textarea readOnly border={"none"} resize='none' value={row.description} />
                                                                                                        </PopoverBody>
                                                                                                    </PopoverContent>
                                                                                                </Popover>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Text color={'gray.400'}>{t("Non disponible")}</Text>
                                                                                        )}
                                                                                    </Flex>
                                                                                </Td>
                                                                                <Td>{row.monthly_fee || "--------"}</Td>
                                                                                <Td>{row.yearly_fee || "--------"}</Td>
                                                                                <Td>{new Date(row.date_creation).toLocaleDateString('fr-FR')}</Td>
                                                                                <Td>
                                                                                    <IconButton
                                                                                        size="sm"
                                                                                        onClick={() => {
                                                                                            setTypeData(row);
                                                                                            onOpen();
                                                                                        }}
                                                                                        variant={'outline'}
                                                                                        colorScheme='whatsapp'
                                                                                        icon={<EditIcon />}
                                                                                    >
                                                                                        {t("Modifier")}
                                                                                    </IconButton>
                                                                                </Td>
                                                                            </Tr>
                                                                        )}
                                                                    </Draggable>
                                                                ))
                                                            ) : (
                                                                <Tr>
                                                                    <Td colSpan={7}>{t("Aucune ligne correspondante n'a été trouvée.")}</Td>
                                                                </Tr>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                    {/* Pagination */}
                                    <Flex justify="space-between" align="center" w="100%">
                                        <Box flex="1">
                                            <MyPagination
                                                paginationData={paginationData}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                PAGE_SIZE={pageSize}
                                            />
                                        </Box>


                                    </Flex>
                                </Stack>
                            </Flex>
                        </CardBody>
                    </Card>

                </Grid>
            </Flex>
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Supprimer")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert}>
                                {t("Annuler")}
                            </Button>
                            <Button onClick={() => handleDelete(id)} colorScheme='red' ml={3}>
                                {t("Supprimer")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {changeModal ?
                <Modal size={"xl"} isOpen={isOpen} onClose={() => {
                    onClose()
                    setIsChecked(false)
                    setSelectedOptions(null)
                    setMessage('')
                    setSelectedAbImage()
                    setImageAb()
                    setDescription('')
                    setSelectedFeeType('')
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Ajout d'un type d'abonnement")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <Flex align={'center'} justify={'center'}>
                                        <Avatar mb={3} me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={imageAb} w={{ base: "70px", md: "130px" }} h={{ base: "70px", md: "130px" }} borderRadius='15px'>
                                            <label htmlFor="imageInput">
                                                <input
                                                    id="imageInput"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handleChangeAbImage}
                                                />
                                                <AvatarBadge
                                                    cursor='pointer'
                                                    borderRadius='8px'
                                                    border='transparent'
                                                    bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                    boxSize='26px'
                                                    backdropFilter='blur(120px)'>
                                                    <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                                </AvatarBadge>
                                            </label>
                                        </Avatar>
                                        <Flex w={"100%"} direction={'column'}>
                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Type d'abonnement")}</FormLabel>
                                                <Input maxLength={50} name="type" onChange={e => setType(e.target.value)} placeholder={t("Type d'abonnement")} />
                                            </FormControl>
                                            <FormControl isRequired mb={3}>
                                                {/* <FormLabel>Description</FormLabel> */}
                                                <Textarea
                                                    maxLength={255}
                                                    resize={'none'}
                                                    placeholder="Description"

                                                    onChange={e => setDescription(e.target.value)}
                                                />
                                            </FormControl>
                                        </Flex>

                                    </Flex>



                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Type de frais")}</FormLabel>
                                        <Select onChange={handleFeeTypeChange} placeholder={t('Sélectionnez le type de frais')}>
                                            {/* <option value='Mentuel'>Mentuel</option> */}
                                            <option value='Annuel'>{t("Annuel")}</option>
                                            <option value='Both'>{t("Annuel / Mensuel")}</option>
                                        </Select>
                                    </FormControl>

                                    {
                                        // selectedFeeType === "Mentuel" ?
                                        //     <FormControl isRequired >
                                        //         <FormLabel>Frais mensuels</FormLabel>
                                        //         <Input  type='number' value={fraisM} maxLength={50} name="fraisM" onChange={e => setFraisM(e.target.value)} placeholder='Frais mensuels...' />
                                        //     </FormControl>
                                        //     : 
                                        selectedFeeType === "Annuel" ?
                                            <FormControl mt={2} isRequired mb={3}>
                                                {/* <FormLabel>{t("Frais annuels")}</FormLabel> */}
                                                <Input type='number' value={fraisA} maxLength={50} name="fraisA" onChange={e => setFraisA(e.target.value)} placeholder={t('Frais annuels...')} />
                                            </FormControl>
                                            :
                                            selectedFeeType === "Both" ?
                                                <>
                                                    <Flex mt={2} gap={5}>
                                                        <FormControl isRequired mb={3}>
                                                            {/* <FormLabel>{t("Frais mensuels")}</FormLabel> */}
                                                            <Input resize={"none"} type='number' maxLength={50} name="fraisM" onChange={e => setFraisM(e.target.value)} placeholder={t('Frais mensuels...')} />
                                                        </FormControl>
                                                        <FormControl isRequired mb={3}>
                                                            {/* <FormLabel>{t("Frais annuels")}</FormLabel> */}
                                                            <Input resize={"none"} type='number' maxLength={50} name="fraisA" onChange={e => setFraisA(e.target.value)} placeholder={t('Frais annuels...')} />
                                                        </FormControl>
                                                    </Flex>

                                                </>
                                                : null
                                    }
                                    <Text color={'yellow.700'} mb={2} fontSize={14}>{t("Veuillez noter que ne pas sélectionner de pays implique que l'abonnement est disponible dans tous les pays.")}</Text>
                                    <Checkbox onChange={(e) => {
                                        setSelectedOptions(null)
                                        setIsChecked(e.target.checked)
                                    }}>{t("Choisir le/les pays")}</Checkbox>
                                    {isChecked &&
                                        <FormControl mt={2} isRequired>
                                            <FormLabel>{t("Pays")}</FormLabel>
                                            <MySelect
                                                defaultValue={selectedOptions}
                                                onChange={handleSelectChange}
                                                options={options}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        backgroundColor: 'transparent',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        cursor: "pointer"
                                                    }),
                                                    multiValueLabel: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        overflow: "visible",
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                        cursor: "pointer"
                                                    }),
                                                    multiValue: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                        cursor: "pointer"
                                                    }),

                                                    multiValueRemove: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                    }),
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        color: txtcolor,
                                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: colorbghover,
                                                        },
                                                        cursor: "pointer"
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: txtcolor
                                                    }),
                                                }}
                                            />
                                        </FormControl>}

                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>
                            {(!selectedAbImage || !description || !type || !selectedFeeType || (selectedFeeType === "Annuel" && !fraisA) || (selectedFeeType === "Both" && (!fraisA || !fraisM)) || (isChecked && (!selectedOptions || selectedOptions?.length === 0)))
                                ?
                                <Button colorScheme='blue' isDisabled
                                >
                                    {t("Ajouter")}
                                </Button>
                                :
                                <Button colorScheme='blue'
                                    onClick={AjoutType}
                                >
                                    {t("Ajouter")}
                                </Button>
                            }
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                :
                <Modal size={"xl"} isOpen={isOpen} onClose={() => {
                    onClose()
                    setMessage('')
                    setSelectedFeeType('')
                    setFraisA('')
                    setFraisM('')
                    setSelectedAbImage()
                    setImageAb()
                    setDescription('')
                    setSelectedOptions(null)
                    setIsChecked(false)
                }}>
                    <ModalOverlay />

                    <ModalContent>

                        <ModalHeader>{t("Modification de type d'abonnement")}</ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {message &&
                                <Alert mb={2} fontSize={"sm"} padding={2} variant={"left-accent"} status='error' rounded="md">
                                    <AlertIcon />
                                    {message}
                                </Alert>
                            }
                            <Flex flexDirection={'column'} align={'center'}>

                                <Flex w="100%" flexDirection={'column'}>
                                    <Flex align={'center'} justify={'center'}>
                                        <Avatar mb={3} me={{ md: '22px' }} mr={{ base: 5, sm: 0, md: 5 }} src={imageAb ? imageAb : typeData.image ? typeData.image : typeData.image} w={{ base: "70px", md: "130px" }} h={{ base: "70px", md: "130px" }} borderRadius='15px'>
                                            <label htmlFor="imageInput">
                                                <input
                                                    id="imageInput"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={handleChangeAbImage}
                                                />
                                                <AvatarBadge
                                                    cursor='pointer'
                                                    borderRadius='8px'
                                                    border='transparent'
                                                    bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                                                    boxSize='26px'
                                                    backdropFilter='blur(120px)'>
                                                    <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                                                </AvatarBadge>
                                            </label>
                                        </Avatar>
                                        <Flex w={"100%"} direction={'column'}>
                                            <FormControl isRequired mb={3}>
                                                <FormLabel>{t("Type d'abonnement")}</FormLabel>
                                                <Input defaultValue={typeData.type} maxLength={50} name="type" onChange={e => setType(e.target.value)} placeholder={t("Type d'abonnement")} />
                                            </FormControl>
                                            <FormControl isRequired mb={3}>
                                                {/* <FormLabel>Description</FormLabel> */}
                                                <Textarea
                                                    maxLength={255}
                                                    resize={'none'}
                                                    placeholder="Description"
                                                    defaultValue={typeData.description}
                                                    onChange={e => setDescription(e.target.value)}
                                                />
                                            </FormControl>
                                        </Flex>

                                    </Flex>


                                    <FormControl isRequired mb={3}>
                                        <FormLabel>{t("Type de frais")}</FormLabel>
                                        <Select defaultValue={selectedFeeType} onChange={handleFeeTypeChange} placeholder={t('Sélectionnez le type de frais')}>
                                            {/* <option value='Mentuel'>Mentuel</option> */}
                                            <option value='Annuel'>{t("Annuel")}</option>
                                            <option value='Both'>{t("Annuel / Mensuel")}</option>
                                        </Select>
                                    </FormControl>

                                    {selectedFeeType &&

                                        // selectedFeeType === "Mentuel" ?
                                        // <FormControl isRequired >
                                        //     <FormLabel>Frais mensuels</FormLabel>
                                        //     <Input  type='number' maxLength={50} name="fraisM" value={(typeData.monthly_fee && !fraisM) ? typeData.monthly_fee : fraisM} onChange={e => setFraisM(e.target.value)} placeholder='Frais mensuels...' />
                                        // </FormControl>
                                        // : 
                                        selectedFeeType === "Annuel" ?
                                        <FormControl isRequired mb={3}>
                                            <FormLabel>{t("Frais annuels")}</FormLabel>
                                            <Input type='number' maxLength={50} name="fraisA" value={(typeData.yearly_fee && !fraisA) ? typeData.yearly_fee : fraisA} onChange={e => setFraisA(e.target.value)} placeholder={t('Frais annuels...')} />
                                        </FormControl>
                                        :
                                        selectedFeeType === "Both" ?
                                            <>
                                                <FormControl isRequired mb={3}>
                                                    <FormLabel>{t("Frais mensuels")}</FormLabel>
                                                    <Input resize={"none"} type='number' maxLength={50} name="fraisM" defaultValue={typeData.monthly_fee} onChange={e => setFraisM(e.target.value)} placeholder={t('Frais mensuels...')} />
                                                </FormControl>
                                                <FormControl isRequired mb={3}>
                                                    <FormLabel>{t("Frais annuels")}</FormLabel>
                                                    <Input resize={"none"} type='number' maxLength={50} name="fraisA" defaultValue={typeData.yearly_fee} onChange={e => setFraisA(e.target.value)} placeholder={t('Frais annuels...')} />
                                                </FormControl>
                                            </>
                                            : null}
                                    <Text color={'yellow.700'} mb={2} fontSize={14}>{t("Veuillez noter que ne pas sélectionner de pays implique que l'abonnement est disponible dans tous les pays.")}</Text>

                                    <Checkbox defaultChecked={options.filter(option => typeData?.pays.includes(option.value)).length > 0 ? true : false} onChange={(e) => {
                                        setSelectedOptions(null)
                                        setIsChecked(e.target.checked)
                                    }}>{t("Choisir le/les pays")}</Checkbox>
                                    {(isChecked) &&
                                        <FormControl w={"100%"} mt={2} isRequired>
                                            <FormLabel>{t("Pays")}</FormLabel>
                                            <MySelect

                                                defaultValue={options.filter(option => typeData?.pays.includes(option.value))}
                                                onChange={handleSelectChange}
                                                options={options}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                isClearable
                                                styles={{

                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        backgroundColor: 'transparent',

                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: colorbg,
                                                        cursor: "pointer"
                                                    }),
                                                    multiValueLabel: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        overflow: 'visible',
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                        cursor: "pointer"
                                                    }),
                                                    multiValue: (provided) => ({
                                                        ...provided,
                                                        textAlign: 'left',
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                        cursor: "pointer"
                                                    }),

                                                    multiValueRemove: (provided) => ({
                                                        ...provided,
                                                        backgroundColor: multiLabel,
                                                        color: multitext,
                                                    }),
                                                    option: (provided, state) => ({
                                                        backgroundColor: colorbg,

                                                        ...provided,
                                                        color: txtcolor,
                                                        backgroundColor: state.isSelected ? colorbg : 'inherit',
                                                        '&:hover': {
                                                            backgroundColor: colorbghover,
                                                        },

                                                        cursor: "pointer"
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        color: txtcolor,
                                                    }),

                                                }}
                                            />
                                        </FormControl>}
                                </Flex>
                            </Flex>

                        </ModalBody>

                        <ModalFooter>


                            <Button colorScheme='blue'
                                onClick={() => UpdateType(typeId)}
                            >
                                {t("Modifier")}
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            }

        </Box>
    )
}

export default TypeAbonnements